import { createGlobalStyle } from 'styled-components';

export const Colors = createGlobalStyle`
  :root {
    /** Primary colors */
    --color-aliceBlue: #E3EDFF;
    --color-aliceBlue2: #F4F7FF;
    --color-aliceBlue3: #F9FBFF;
    --color-dodgerBlue: #2D7CFF;
    --color-navyBlue: #0052CC;
    --color-egyptianBlue: #0F4296;
    --color-lavenderBlue: #C7DCFF;
    --color-hawkesBlue: #D2DEF6;

    /** Secondary colors */
    --color-amour: #F6E7F1;
    --color-bakersChocolate: #503012;
    --color-blueChalk: #EEECF6;
    --color-blueGem: #403393;
    --color-christalle:#2B206F;
    --color-persianBlue: #2F1AAD;
    --color-darkCerulean: #0C6A7E;
    --color-irisBlue: #e9edf1;
    --color-darkGreen: #006D00;
    --color-earlyDawn: #FCF3E4;
    --color-fireEngineRed: #E31414;
    --color-forgetMeNot: #FDF3DF;
    --color-goldenBrown: #867414;
    --color-saddleBrown: #80420A;
    --color-green: #008800;
    --color-hacienda: #A57E26;
    --color-honeydew: #EAFFEA;
    --color-jazzberryJam: #8A095E;
    --color-lavender: #F8F9FE;
    --color-lightCyan: #C5FEDF;
    --color-limeGreen: #20DC49;
    --color-magnolia: #ECD9FF;
    --color-malachite: #14E342;
    --color-offYellow: #FAF7DD;
    --color-papayaWhip: #FFEED9;
    --color-seaGreen: #3AB56E;
    --color-snow: #FFEEEE;
    --color-tangerine: #E09D00;
    --color-venetianRed: #D9052C;
    --color-pigmentGreen: #caf0db;
    --color-statusGreen: #EAFAF2;
    --color-statusRed: #FEEFF2;
    --color-statusTextRed: #BC102F;
    --color-tableBorder: #e9e9e7;
    --color-tableTRBorder : #ededed;
    --color-lightGray2 : #f6f6f9;
    --color-lightGray3 : #fafafa;
    --color-lightGray4 : #eeeeee;
    --color-lightGray5 : #e4e4e4;
    --color-lightGray6 : #e6e6e6;
    --color-lightGray7 : #fefefe;
    --color-lightGray8 : #c2c2c2;
    --color-darkBlue: #4984ff;
    --color-cyprus:#E3F3E9;
    --color-british-racing-green: #004528;
    --color-progressRed: #E0094A;
    --color-lightPink: #F7E5F0;
    --color-lightlilac: #F3EBFF;
    --color-lightGreen: #CCFAE9;
    --color-smokeGreen: #F8FFFC;
    --color-smokeBlue: #FBF8FF;
    --color-smokePink: #FFF5FB;
    --color-hoverGrey: #f4f5f6;
    --color-stormDust: #636568;
    --color-linkBlue: #0f46e0;
    --color-lightBrown: #FAF3E6;
    --color-textBrown: #BA841D;
    --color-darkBrown: #5C4033;
    --color-lightBlue: #D1E3FA;
    --color-hoverBlue: #F2F8FD;
    --color-silver: #c1c1c1;
    --color-lightSlateBlue: #a663fd;
    --color-artyClickOrange: #FC7B03;
    --color-supernova: #ffb524;
    --color-gamboge : #db950e;
    --color-cornSilk: #fff4dd;
    --color-paleCornflowerBlue: #B2CAFA;
    --color-paleDodgerBlue: #2a657c;
    --color-pastelMagenta: #EAA0C9;
    --color-azul: #1256FE;
    --color-purpleMimosa: #A18AFF;
    --color-deepSaffron: #FF9841;
    --color-parisGreen: #54C992;
    --color-lightBlueGrey: #f4f4fc;
    --color-waterCourse: #00774b;
    --color-yellow: #fff500;

    /** Neutral colors */
    --color-black: #000000;
    --color-charcoal: #444444;
    --color-darkGray: #777777;
    --color-gainsboro: #E2E2E2;
    --color-lightGray: #D4D4D4;
    --color-quartz: #E9E7F6;
    --color-solitude: #EBEDF1;
    --color-solitudeSecondary: #F7F8F9;
    --color-suvaGray: #888888;
    --color-white: #FFFFFF;
    --color-whiteIce: #E7F6F5;
    --color-whiteSmoke: #EFEFEF;
    --color-whiteSmoke2: #f0f0f0;
    --color-amour: #FCF5FA;
    --color-pink: #B42877;
    --color-lilac: #563AAD;
    --color-azalea: #FABDBD;

  }
`;
