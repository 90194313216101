import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import { Control } from 'react-hook-form';
import { TextField, Typography } from 'ui';

import { isWorkflowReadOnlyAtom } from '../../../atoms/atoms';

type WorkflowNodeSettingsProps = {
  control?: Control<any>;
  name: string;
};

export function WorkflowNodeSettings({
  control,
  name,
}: WorkflowNodeSettingsProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  return (
    <PadBox padding="2rem">
      <Stack gutter="1rem">
        <Inline align="center">
          <Typography>Timeout after (s)</Typography>
          <TextField
            name={`${name}.timeout`}
            type="number"
            size="small"
            control={control}
            disabled={isWorkflowReadOnly}
          />
        </Inline>

        {/* <Inline align="center" gutter={8}>
          <Typography>Continue on error?</Typography>
          <CheckboxField
            name={`${name}.errorContinue`}
            control={control}
            disabled={isWorkflowReadOnly}
            appearance="switch"
            useId={`${name}.errorContinue`}
          />
        </Inline> */}
      </Stack>
    </PadBox>
  );
}
