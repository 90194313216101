import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { usedConnectorMappingAtom } from '../../../atom';
import { permissionObj } from '../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../components/PermissionComponent/hooks/useCheckPermissions';
import {
  DependencyUsingMapType,
  UsedConnectorMappingInEntityType,
} from '../../../types';
import { handleSetCheckSumByEntityName } from '../../../utils/common';
import { ENTITY_ID } from '../../../utils/constant';
import {
  approvalInfoWorkflowAtom,
  changedNodeIdsAtom,
  isWorkflowLiveAtom,
  isWorkflowReadOnlyAtom,
  isWorkflowTestOnlyAtom,
  versionInfoWorkflowAtom,
  versionMappingWfInfoAtom,
  workflowIdAtom,
  workflowStaticUrlAtom,
  workflowStatusAtom,
  workflowStatusByNode,
} from '../atoms/atoms';
import { useGetWorkflowById } from './graphql/useGetWorkflowById';
import { useGetWorkflowByIdOnPublish } from './graphql/useGetWorkflowByIdOnPublish';
import { WorkflowNodeType } from './useOpenWorkflow';

type UseEditWorkflowArgs = {
  id?: string;
  setValue: UseFormSetValue<any>;
  discardWorkflowData?: Record<string, any>;
  isLive?: boolean;
  commitId?: string;
};

export function useEditWorkflow({
  id,
  setValue,
  discardWorkflowData,
  isLive,
  commitId,
}: UseEditWorkflowArgs) {
  const [getWorkflowById, { data }] = useGetWorkflowById();
  const [searchParams] = useSearchParams();
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);

  const version = searchParams.get('version') ?? '';

  const [getWorkflowByIdOnPublish, { data: workflowDataOnPublish }] =
    useGetWorkflowByIdOnPublish();

  const type = searchParams.get('type');

  const [, setWorkflowId] = useAtom(workflowIdAtom);
  const [workflowStatus, setWorkflowStatus] = useAtom(workflowStatusAtom);
  const [, setWorkflowStaticUrl] = useAtom(workflowStaticUrlAtom);
  const [, setWorkflowStatusByNode] = useAtom(workflowStatusByNode);

  const [, setIsWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [, setIsWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);
  const [, setIsWorkflowLive] = useAtom(isWorkflowLiveAtom);
  const [, setApprovalInfoWorkflow] = useAtom(approvalInfoWorkflowAtom);
  const [, setversionInfoWorkflow] = useAtom(versionInfoWorkflowAtom);
  const [, setVersionMappingInfo] = useAtom(versionMappingWfInfoAtom);

  const [, setUsedConnectorMapping] = useAtom(usedConnectorMappingAtom);

  // eslint-disable-next-line
  const [staging, setStaging] = useState<any>({});
  // eslint-disable-next-line
  const [production, setProduction] = useState<any>({});

  const [oldNodes, setOldNodes] = useState<any[]>([]);
  const [oldEdges, setOldEdges] = useState<any[]>([]);

  const { isHide: isEditDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.workflow],
    entityStatus: workflowStatus,
  });

  const { isHide: isTestDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.test],
    entityList: [ENTITY_ID.workflow],
    entityStatus: workflowStatus,
  });

  const handleGetWorkflowId = async (wfId: string) => {
    try {
      const filters: Record<string, any> = {};

      if (!_isNil(commitId) && !_isEmpty(commitId)) {
        filters.eq = { commitId };
      }

      if (!_isNil(version) && !_isEmpty(version)) {
        filters.eq = { version };
      }

      await getWorkflowById({
        variables: { id: wfId, live: isLive, filters },
        fetchPolicy: 'no-cache',
      });
    } catch (error) {}
  };

  const handleGetWorkflowAfterStateTransition = async () => {
    try {
      await getWorkflowById({
        variables: { id },
        fetchPolicy: 'no-cache',
      });
    } catch (error) {}
  };

  useEffect(() => {
    setChangedNodeIds([]);

    if (!_isNil(id)) {
      void handleGetWorkflowId(id);
    }
  }, [id]);

  useEffect(() => {
    setIsWorkflowReadOnly(isEditDisable);
  }, [isEditDisable]);

  useEffect(() => {
    setIsWorkflowTestOnly(!isTestDisable);
  }, [isTestDisable]);

  useEffect(() => {
    if (!_isNil(data)) {
      initializeWorkflow(data.getWorkflow.data[0]);
    }
  }, [data, type]);

  useEffect(() => {
    if (!_isNil(discardWorkflowData)) {
      initializeWorkflow(discardWorkflowData.discardWorkflow.data[0]);
    }
  }, [discardWorkflowData]);

  useEffect(() => {
    if (!_isNil(workflowDataOnPublish)) {
      const data = workflowDataOnPublish.getWorkflow.data[0];

      if (!_isNil(data)) {
        setversionInfoWorkflow(data.versionInfo);
        setIsWorkflowLive(data.isLive ?? false);
        setWorkflowStatus(data.status);

        handleSetCheckSumByEntityName('workflow', data.checksum);
      }

      if (typeof setValue === 'function') {
        setValue('publishedAt', data.publishedAt);
      }
    }
  }, [workflowDataOnPublish]);

  const initializeWorkflow = (workflowData: Record<string, any>) => {
    if (!_isNil(workflowData)) {
      if (!_isNil(workflowData.nodes)) {
        setOldNodes(workflowData.nodes);

        workflowData.nodes.forEach((node: WorkflowNodeType) => {
          setWorkflowStatusByNode((prev) => ({
            ...prev,
            [node.id]: node.data.status ?? 'draft',
          }));
        });

        if (!_isNil(workflowData.nodes)) {
          const currUsedConnectors: UsedConnectorMappingInEntityType = {};

          workflowData.nodes.forEach((node: any) => {
            if (
              ['restApiNode', 'dbNode', 'gSheetNode'].includes(
                node.data.nodeType
              )
            ) {
              currUsedConnectors[node.data.entityId] = {
                status: true,
                source: ['node'],
              };
            }
          });

          setUsedConnectorMapping(currUsedConnectors);
        }
      }

      if (!_isNil(workflowData.edges)) {
        setOldEdges(workflowData.edges);
      }

      if (!_isNil(workflowData.staticUrl)) {
        setWorkflowStaticUrl(workflowData.staticUrl);
      }

      if (!_isNil(workflowData.status)) {
        setWorkflowStatus(workflowData.status);
      }

      if (!_isNil(workflowData.staging)) {
        setValue('staging', workflowData.staging);
      }

      if (!_isNil(workflowData.production)) {
        setValue('production', workflowData.production);
      }

      if (!_isNil(workflowData.checksum)) {
        handleSetCheckSumByEntityName('workflow', workflowData.checksum);
      }

      if (!_isNil(workflowData.id) && type !== 'clone') {
        setWorkflowId(workflowData.id);
      } else {
        setWorkflowId(null);
      }

      if (!_isNil(workflowData.name)) {
        setValue(
          'name',
          `${workflowData.name as string}${type === 'clone' ? '_copy' : ''}`
        );
      }

      if (!_isNil(workflowData.isEnabled)) {
        setValue('isEnabled', workflowData.isEnabled);
      }

      if (!_isNil(workflowData.isLive)) {
        setIsWorkflowLive(workflowData?.isLive ?? false);
      }

      if (!_isNil(workflowData.description)) {
        setValue('description', workflowData.description);
      }

      if (!_isNil(workflowData.approvalInfo)) {
        setApprovalInfoWorkflow(workflowData.approvalInfo);
      }

      if (!_isNil(workflowData.versionInfo)) {
        setversionInfoWorkflow(workflowData.versionInfo);
      }

      if (!_isNil(workflowData.dependencyMap)) {
        setVersionMappingInfo(
          workflowData.dependencyMap?.map(
            (currMapping: DependencyUsingMapType) => ({
              entityId: currMapping.id,
              type: currMapping.type,
              version: currMapping.version,
              nodeId: currMapping.nodeId,
            })
          ) ?? []
        );
      }

      if (!_isNil(workflowData.createdAt)) {
        setValue('createdAt', workflowData.createdAt);
      }

      if (!_isNil(workflowData.publishedAt)) {
        setValue('publishedAt', workflowData.publishedAt);
      }
    }
  };

  return {
    oldNodes,
    oldEdges,
    staging,
    production,
    getWorkflowByIdOnPublish,
    handleGetWorkflowAfterStateTransition,
  };
}
