import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Edge, Node, Position, useEdgesState, useNodesState } from 'reactflow';
import { useCurrentLayer, useLayer } from 'ui';

import { getEntityTypeForApi } from '../../../components/Modals/utils/common';
import {
  EntityType,
  useLayerCloseOnPath,
} from '../../../hooks/useLayerCloseOnPath';
import { useSendEventToGTM } from '../../../hooks/useSendEventToGTM';
import { generateUid } from '../../../utils/common';
import { timeToExpireUnits } from '../../DataSets/utils';
import type { ConnectorAndPluginModel } from '../../Integrations/types';
import { isRuleReadOnlyAtom } from '../../Rules';
import type { RuleListDataModel } from '../../Rules/components/RuleSet/models';
import {
  oldWorkflowNodesAtom,
  versionMappingWfInfoAtom,
  workflowEdgesAtom,
  workflowIdAtom,
  workflowNodeSavingAtom,
  workflowNodesAtom,
  workflowStaticUrlAtom,
  workflowStatusAtom,
} from '../atoms/atoms';
import { ApiTriggerSheet } from '../components/Sheets/ApiTriggerSheet/ApiTriggerSheet';
import { CodeNodeSheet } from '../components/Sheets/CodeNodeSheet/CodeNodeSheet';
import { ConnectorActionSheet } from '../components/Sheets/ConnectorActionSheet/ConnectorActionSheet';
import { DelayNodeSheet } from '../components/Sheets/DelayNodeSheet/DelayNodeSheet';
import { GSheetNodeSheet } from '../components/Sheets/GSheetNodeSheet/GSheetNodeSheet';
import { ResponseNodeSheet } from '../components/Sheets/ResponseNodeSheet/ResponseNodeSheet';
import { RestApiActionSheet } from '../components/Sheets/RestApiActionSheet/RestApiActionSheet';
import { RuleSheet } from '../components/Sheets/RuleSheet/RuleSheet';
import { SetVariableNodeSheet } from '../components/Sheets/SetVariableNodeSheet/SetVariableNodeSheet';
import { SwitchNodeSheet } from '../components/Sheets/SwitchNodeSheet/SwitchNodeSheet';
import { WorkflowNodeSheet } from '../components/Sheets/WorkflowNodeSheet/WorkflowNodeSheet';
import {
  appendTypeToEdges,
  findListOfNodesAndEdgesToBeDeleted,
  findNodesAndEdgesBetween,
  generateUniqueName,
  getCaseNodesAndEdges,
  getChildNodesAndEdges,
  getMergeNodeCorrespondingToSrId,
  getNextNode,
  getOrderedSuccessorNodesRecursiveDFS,
  getParentEdges,
  getParentNodes,
  getRootNodeEqualToCurrentNodeRootId,
  removeNakedEdges,
  sortNodesByPositionYDesc,
} from '../utils/common';
import {
  useGetRuleVersionList,
  useGetWorkflowVersionList,
} from './graphql/useGetEntityVersionList';

type AddNodeParams = {
  id: string;
  type?: string;
  ruleItem?: RuleListDataModel;
  metaData?: Record<string, any>;
  connectorItem?: ConnectorAndPluginModel;
  workflowItem?: Record<string, any>;
};

type DeleteNodeParams = {
  id: string;
  type?: string;
};

type UpdateOnNameChangeParams = {
  id: string;
  name: string;
  newName: string;
};

type HandleVersionMappingParams = {
  entityId: string;
  type: string;
  name: string;
  nodeId: string;
  subType?: string;
};

export type WorkflowNodeType = Node<any, string | undefined>;

export type WorkflowEdgeType = Edge<any>;

type UseWorkflowOpenArgs = {
  oldNodes?: WorkflowNodeType[];
  oldEdges?: any[];
};

export function useOpenWorkflow({ oldNodes, oldEdges }: UseWorkflowOpenArgs) {
  const { closeAllLayers, close: closeWorkflowSheet } = useCurrentLayer();
  const [searchParams] = useSearchParams();
  const [, setRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [, setOldWorkflowNodes] = useAtom(oldWorkflowNodesAtom);
  const [, setWorkflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges, setWorkflowEdges] = useAtom(workflowEdgesAtom);
  const [, setWorkflowId] = useAtom(workflowIdAtom);
  const [, setWorkflowStatus] = useAtom(workflowStatusAtom);
  const [, setWorkflowStaticUrl] = useAtom(workflowStaticUrlAtom);
  const [, setWorkflowNodeSaving] = useAtom(workflowNodeSavingAtom);
  const [versionMappingInfo, setVersionMappingInfo] = useAtom(
    versionMappingWfInfoAtom
  );

  const { openWithProps: openRuleSheet } = useLayer(<RuleSheet />);
  const { openWithProps: openConnectorActionSheet } = useLayer(
    <ConnectorActionSheet />
  );
  const { openWithProps: openCodeNodeSheet } = useLayer(<CodeNodeSheet />);
  const { openWithProps: openDelayNodeSheet } = useLayer(<DelayNodeSheet />);
  const { openWithProps: openSetVariableNodeSheet } = useLayer(
    <SetVariableNodeSheet />
  );
  const { openWithProps: openWorkflowNode } = useLayer(<WorkflowNodeSheet />);
  const { openWithProps: openResponseNodeSheet } = useLayer(
    <ResponseNodeSheet />
  );
  const { openWithProps: openRestApiActionSheet } = useLayer(
    <RestApiActionSheet />
  );
  const { openWithProps: openSwitchNodeSheet } = useLayer(<SwitchNodeSheet />);
  const { openWithProps: openGSheetNodeSheet } = useLayer(<GSheetNodeSheet />);

  const type = searchParams.get('type');

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const { sendEventToGTM } = useSendEventToGTM();

  // eslint-disable-next-line
  const [getRuleVersionListQuery] = useGetRuleVersionList();
  // eslint-disable-next-line
  const [getWorkflowVersionListQuery] = useGetWorkflowVersionList();

  const { openWithProps } = useLayer(<ApiTriggerSheet />);

  const onWorkflowNodeChange = useCallback(
    (node: WorkflowNodeType, type?: string, topEdges?: WorkflowEdgeType[]) => {
      setNodes((nodes) => {
        let updatedNodes: WorkflowNodeType[] = [];
        let nodesToBeDeleted: string[] = [];
        let edgesToBeDeleted: string[] = [];

        const currentNode = nodes.find((n) => n.id === node.id);

        if (type === 'switch' && currentNode != null) {
          const existingGroups: string[] = [];
          const newGroups: string[] = [];

          currentNode?.data.switcher?.forEach((s: Record<string, any>) => {
            existingGroups.push(s.pathId);
          });

          node?.data.switcher?.forEach((s: Record<string, any>) => {
            newGroups.push(s.pathId);
          });

          const deletedPaths = existingGroups.filter(
            (e) => !newGroups.includes(e)
          );

          const addedPaths = newGroups.filter(
            (e) => !existingGroups.includes(e)
          );
          const mergeNode = nodes.find(
            (n) => n.data.rootId === node.id && n.data.isMergeNode
          );

          if (deletedPaths.length > 0 && !_isNil(mergeNode)) {
            const items = findListOfNodesAndEdgesToBeDeleted(
              deletedPaths,
              nodes,
              topEdges ?? workflowEdges,
              mergeNode.id
            );
            nodesToBeDeleted = items.nodeIdsToBeDeleted;
            edgesToBeDeleted = items.edgeIdsToBeDeleted;
          }

          const newNodesAndEdges = getCaseNodesAndEdges(
            addedPaths,
            mergeNode?.id ?? '',
            node.id,
            node.data.conditions.nodes
          );

          updatedNodes = [...updatedNodes, ...newNodesAndEdges.nodesToBeAdded];

          setEdges((edges) => {
            const modifiedEdges = [
              ...edges,
              ...newNodesAndEdges.edgesToBeAdded,
            ].filter((e) => !edgesToBeDeleted.includes(e.id));

            setWorkflowEdges(appendTypeToEdges(modifiedEdges));

            return modifiedEdges;
          });
        }

        const finalNodes = nodes.map((n) => {
          if (n.id === node.id) {
            return node;
          }

          return n;
        });

        updatedNodes = [...finalNodes, ...updatedNodes].filter(
          (n) => !nodesToBeDeleted.includes(n.id)
        );

        setWorkflowNodes(updatedNodes);

        return updatedNodes;
      });
    },
    [nodes, edges, JSON.stringify(workflowEdges)]
  );

  const getNextNodeId = (currentNodeId: string, currentEdges: any[]) => {
    const outgoingEdges = currentEdges.filter(
      (edge) => edge.source === currentNodeId
    );

    if (outgoingEdges.length > 0) {
      // If there are outgoing edges, return the target node of the first outgoing edge
      return outgoingEdges[0].target;
    }

    // If there are no outgoing edges or the current node is the last in the graph, return null
    return null;
  };

  const addNode = useCallback(
    (params: AddNodeParams) => {
      let nodeId = '';

      setNodes((currentNodes) => {
        let updatedNodes: Array<Node<any, string | undefined>> = [
          ...currentNodes,
        ];

        const currentNode = currentNodes.find((node) => node.id === params.id);

        if (params.type === 'apiTrigger') {
          setEdges((edges) => {
            const newAddNodeId = generateUid('node_');

            const newTriggerNode: WorkflowNodeType = {
              id: params.id,
              type: 'trigger',
              data: {
                nodeType: 'apiTrigger',
                ...functionsForNode,
                name: generateUniqueName(updatedNodes, 'Trigger'),
                blockName: 'trigger',
                settings: {
                  isEnabled: true,
                  authType: 'none',
                },
              },
              position: {
                x: (currentNode?.position.x ?? 0) - 40,
                y: currentNode?.position.y ?? 0,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode: WorkflowNodeType = {
              id: newAddNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
              },
              position: {
                x: newTriggerNode.position.x + 29,
                y: newTriggerNode.position.y + 110,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newEdgeId = generateUid('edge_');
            const updatedEdges = [...edges];

            const newEdge1 = {
              id: newEdgeId,
              source: params.id,
              target: newAddNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            updatedEdges.push(newEdge1);

            setWorkflowEdges(appendTypeToEdges(updatedEdges));

            updatedNodes = [newTriggerNode, newAddNode];

            openWithProps({
              id: newTriggerNode.id,
              data: newTriggerNode.data,
            });

            return appendTypeToEdges(updatedEdges);
          });
        } else if (params.type === 'dtNode') {
          setEdges((edges) => {
            const newDtNodeId = generateUid('node_');
            const newAddNodeId = generateUid('node_');

            nodeId = newDtNodeId;

            let rootId: string = currentNode?.data.rootId ?? '';

            // eslint-disable-next-line
            if (currentNode?.data.isMergeNode) {
              rootId =
                getRootNodeEqualToCurrentNodeRootId(currentNodes, currentNode)
                  ?.data.rootId ?? '';
            }

            // LIST OF SUCCESSOR NODES
            const successorsNodes = getOrderedSuccessorNodesRecursiveDFS(
              currentNodes,
              edges,
              params.id
            );

            const newDtNode: WorkflowNodeType = {
              id: newDtNodeId,
              type: 'rule',
              data: {
                nodeType: 'dtNode',
                ...functionsForNode,
                name: generateUniqueName(
                  updatedNodes,
                  params.ruleItem?.name ?? 'RULE_1'
                ),
                entityId: params.ruleItem?.id ?? '',
                rootId,
                entity: 'rule',
                blockName: 'decisionTable',
                settings: {
                  timeout: 30,
                  runAction: false,
                  actionInSync: false,
                  errorContinue: false,
                },
              },
              position: {
                x: (currentNode?.position.x ?? 100) - 29,
                y: (currentNode?.position.y ?? 0) + 55,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode: WorkflowNodeType = {
              id: newAddNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                rootId,
              },
              position: {
                x: newDtNode.position.x + 29,
                y: newDtNode.position.y + 110,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const nextNodeId = getNextNodeId(params.id, edges);

            const nextNode = currentNodes.find(
              (node) => node.id === nextNodeId
            );

            const newEdgeId = generateUid('edge_');
            const newEdgeId1 = generateUid('edge_');
            let updatedEdges = [...edges];

            const newEdgeAddNode = {
              id: newEdgeId,
              source: params.id,
              target: newDtNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const newEdge1 = {
              id: newEdgeId1,
              source: newDtNodeId,
              target: newAddNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const oldEdge = edges.find((edge) => edge.source === params.id);

            if (!_isNil(nextNode) && !_isNil(oldEdge)) {
              const newEdge2 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: newAddNodeId,
                target: nextNode.id,
              };

              let distanceToBeAdded = 165;

              const cloneSuccessorNodes = JSON.parse(
                JSON.stringify(successorsNodes)
              );

              const newSuccessorsNodes = cloneSuccessorNodes.map(
                (updatedNode: any, i: number) => {
                  const parentNodes = getParentNodes(
                    currentNodes,
                    edges,
                    updatedNode.id
                  );

                  const sortedParentNodes =
                    sortNodesByPositionYDesc(parentNodes);

                  if (
                    _isNil(currentNode?.data.rootId) ||
                    currentNode?.data.rootId === ''
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode !== true
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId !== updatedNode.data?.rootId
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode === true
                  ) {
                    let y = 0;

                    if (i === 0) {
                      y =
                        (newAddNode.position.y ?? 0) -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    } else {
                      y =
                        // eslint-disable-next-line
                        (cloneSuccessorNodes[i - 1]?.position?.y ?? 0) +
                        distanceToBeAdded -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    }

                    if (
                      !_isNil(cloneSuccessorNodes[i - 1]) &&
                      cloneSuccessorNodes[i - 1].id === sortedParentNodes[0]?.id
                    ) {
                      distanceToBeAdded = 165;
                    } else if (y > 0) {
                      distanceToBeAdded = y;
                    } else {
                      distanceToBeAdded = 0;
                    }

                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  }

                  return updatedNode;
                }
              );

              updatedEdges = updatedEdges.filter((edg) => {
                if (edg.source === params.id) {
                  return false;
                }

                return true;
              });

              updatedEdges.push(newEdge2);

              updatedNodes = updatedNodes.map((updatedNode, i) => {
                const node = newSuccessorsNodes.find(
                  (node: any) => node.id === updatedNode.id
                );

                if (!_isNil(node)) {
                  return node;
                }

                return updatedNode;
              });
            }

            updatedEdges.push(newEdge1, newEdgeAddNode);

            setWorkflowEdges(appendTypeToEdges(updatedEdges));

            updatedNodes = [...updatedNodes, newDtNode, newAddNode];

            openRuleSheet({
              id: newDtNode.id,
              data: newDtNode.data,
            });

            return appendTypeToEdges(updatedEdges);
          });
        } else if (params.type === 'ruleSetNode') {
          setEdges((edges) => {
            const newRuleSetNodeId = generateUid('node_');
            const newAddNodeId = generateUid('node_');

            nodeId = newRuleSetNodeId;

            let rootId: string = currentNode?.data.rootId ?? '';

            // eslint-disable-next-line
            if (currentNode?.data.isMergeNode) {
              rootId =
                getRootNodeEqualToCurrentNodeRootId(currentNodes, currentNode)
                  ?.data.rootId ?? '';
            }

            // LIST OF SUCCESSOR NODES
            const successorsNodes = getOrderedSuccessorNodesRecursiveDFS(
              currentNodes,
              edges,
              params.id
            );

            const newRuleSetNode: WorkflowNodeType = {
              id: newRuleSetNodeId,
              type: 'rule',
              data: {
                nodeType: 'ruleSetNode',
                ...functionsForNode,
                name: generateUniqueName(
                  updatedNodes,
                  params.ruleItem?.name ?? 'RULE_1'
                ),
                entityId: params.ruleItem?.id ?? '',
                rootId,
                entity: 'rule',
                blockName: 'ruleSet',
                settings: {
                  timeout: 30,
                  runAction: false,
                  actionInSync: false,
                  errorContinue: false,
                },
              },
              position: {
                x: (currentNode?.position.x ?? 100) - 29,
                y: (currentNode?.position.y ?? 0) + 55,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode: WorkflowNodeType = {
              id: newAddNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                rootId,
              },
              position: {
                x: newRuleSetNode.position.x + 29,
                y: newRuleSetNode.position.y + 110,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const nextNodeId = getNextNodeId(params.id, edges);

            const nextNode = currentNodes.find(
              (node) => node.id === nextNodeId
            );

            const newEdgeId = generateUid('edge_');
            const newEdgeId1 = generateUid('edge_');
            let updatedEdges = [...edges];

            const newEdgeAddNode = {
              id: newEdgeId,
              source: params.id,
              target: newRuleSetNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const newEdge1 = {
              id: newEdgeId1,
              source: newRuleSetNodeId,
              target: newAddNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const oldEdge = edges.find((edge) => edge.source === params.id);

            if (!_isNil(nextNode) && !_isNil(oldEdge)) {
              const newEdge2 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: newAddNodeId,
                target: nextNode.id,
              };

              let distanceToBeAdded = 165;

              const cloneSuccessorNodes = JSON.parse(
                JSON.stringify(successorsNodes)
              );

              const newSuccessorsNodes = cloneSuccessorNodes.map(
                (updatedNode: any, i: number) => {
                  const parentNodes = getParentNodes(
                    currentNodes,
                    edges,
                    updatedNode.id
                  );

                  const sortedParentNodes =
                    sortNodesByPositionYDesc(parentNodes);

                  if (
                    _isNil(currentNode?.data.rootId) ||
                    currentNode?.data.rootId === ''
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode !== true
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId !== updatedNode.data?.rootId
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode === true
                  ) {
                    let y = 0;

                    if (i === 0) {
                      y =
                        (newAddNode.position.y ?? 0) -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    } else {
                      y =
                        // eslint-disable-next-line
                        (cloneSuccessorNodes[i - 1]?.position?.y ?? 0) +
                        distanceToBeAdded -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    }

                    if (
                      !_isNil(cloneSuccessorNodes[i - 1]) &&
                      cloneSuccessorNodes[i - 1].id === sortedParentNodes[0]?.id
                    ) {
                      distanceToBeAdded = 165;
                    } else if (y > 0) {
                      distanceToBeAdded = y;
                    } else {
                      distanceToBeAdded = 0;
                    }

                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  }

                  return updatedNode;
                }
              );

              updatedEdges = updatedEdges.filter((edg) => {
                if (edg.source === params.id) {
                  return false;
                }

                return true;
              });

              updatedEdges.push(newEdge2);

              updatedNodes = updatedNodes.map((updatedNode, i) => {
                const node = newSuccessorsNodes.find(
                  (node: any) => node.id === updatedNode.id
                );

                if (!_isNil(node)) {
                  return node;
                }

                return updatedNode;
              });
            }

            updatedEdges.push(newEdge1, newEdgeAddNode);

            setWorkflowEdges(appendTypeToEdges(updatedEdges));

            updatedNodes = [...updatedNodes, newRuleSetNode, newAddNode];

            openRuleSheet({
              id: newRuleSetNode.id,
              data: newRuleSetNode.data,
            });

            return appendTypeToEdges(updatedEdges);
          });
        } else if (params.type === 'srNode') {
          setEdges((currentEdges) => {
            let updatedEdges = [...currentEdges];

            const oldEdge = currentEdges.find(
              (edge) => edge.source === params.id
            );

            let rootId: string = currentNode?.data.rootId ?? '';
            // eslint-disable-next-line
            if (currentNode?.data.isMergeNode) {
              rootId =
                // eslint-disable-next-line
                // eslint-disable-next-line
                getRootNodeEqualToCurrentNodeRootId(currentNodes, currentNode)
                  ?.data.rootId ?? '';
            }

            const newSrNodeId = generateUid('node_');
            const newAddNodeId = generateUid('node_');
            const newAddNodeId2 = generateUid('node_');
            const newAddMergeNodeId = generateUid('node_');

            nodeId = newSrNodeId;

            const sourceId = generateUid('source_');
            const sourceId2 = generateUid('source_');

            // LIST OF SUCCESSOR NODES
            const successorsNodes = getOrderedSuccessorNodesRecursiveDFS(
              currentNodes,
              currentEdges,
              params.id
            );

            const nextNodeId = getNextNodeId(params.id, currentEdges);
            const nextNode = currentNodes.find(
              (node) => node.id === nextNodeId
            );

            const newSrNode: WorkflowNodeType = {
              id: newSrNodeId,
              type: 'rule',
              data: {
                nodeType: 'srNode',
                ...functionsForNode,
                name: generateUniqueName(
                  updatedNodes,
                  params.ruleItem?.name ?? 'RULE_1'
                ),
                rootId,
                entityId: params.ruleItem?.id ?? '',
                sourceIds: [sourceId, sourceId2],
                entity: 'rule',
                blockName: 'simpleRule',
                settings: {
                  timeout: 30,
                  runAction: false,
                  actionInSync: false,
                  errorContinue: false,
                },
              },
              position: {
                x: (currentNode?.position.x ?? 100) - 29,
                y: (currentNode?.position.y ?? 0) + 55,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode: WorkflowNodeType = {
              id: newAddNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                rootId: newSrNodeId,
              },
              position: {
                x: newSrNode.position.x - 120,
                y: newSrNode.position.y + 128,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode2: WorkflowNodeType = {
              id: newAddNodeId2,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                rootId: newSrNodeId,
              },
              position: {
                x: newSrNode.position.x + 180,
                y: newSrNode.position.y + 128,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNodeMerge: WorkflowNodeType = {
              id: newAddMergeNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                isMergeNode: true,
                rootId: newSrNodeId,
              },
              position: {
                x: newSrNode.position.x + 29,
                y: newSrNode.position.y + 274,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            if (successorsNodes.length === 0) {
              const newConnectEdgeId = generateUid('edge_');
              const newConnectEdge: WorkflowEdgeType = {
                id: newConnectEdgeId,
                source: params.id,
                target: newSrNodeId,
                animated: false,
                sourceHandle: sourceId,
                style: { stroke: 'gray', strokeWidth: 1 },
                type: 'smoothEdge',
                data: {
                  edgeType: 'then',
                },
              };

              updatedEdges.push(newConnectEdge);
            } else if (!_isNil(nextNode) && !_isNil(oldEdge)) {
              updatedEdges = updatedEdges.filter((edg) => {
                if (edg.source === params.id) {
                  return false;
                }

                return true;
              });

              const newConnectEdgeId = generateUid('edge_');

              const newConnectEdge: WorkflowEdgeType = {
                id: newConnectEdgeId,
                source: newAddMergeNodeId,
                target: nextNode.id,
                animated: false,
                sourceHandle: sourceId,
                style: { stroke: 'gray', strokeWidth: 1 },
                type: 'smoothEdge',
                data: {
                  edgeType: 'then',
                },
              };

              updatedEdges.push(newConnectEdge);

              const newEdge2 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: params.id,
                target: newSrNodeId,
              };

              let distanceToBeAdded = 315;

              const cloneSuccessorNodes = JSON.parse(
                JSON.stringify(successorsNodes)
              );

              const newSuccessorsNodes = cloneSuccessorNodes.map(
                (updatedNode: any, i: number) => {
                  const parentNodes = getParentNodes(
                    currentNodes,
                    currentEdges,
                    updatedNode.id
                  );

                  const sortedParentNodes =
                    sortNodesByPositionYDesc(parentNodes);

                  if (
                    _isNil(currentNode?.data.rootId) ||
                    currentNode?.data.rootId === ''
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode !== true
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId !== updatedNode.data?.rootId
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode === true
                  ) {
                    let y = 0;

                    if (i === 0) {
                      y =
                        (newAddNodeMerge.position.y ?? 0) -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    } else {
                      y =
                        // eslint-disable-next-line
                        (cloneSuccessorNodes[i - 1]?.position?.y ?? 0) +
                        distanceToBeAdded -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    }

                    if (
                      !_isNil(cloneSuccessorNodes[i - 1]) &&
                      cloneSuccessorNodes[i - 1].id === sortedParentNodes[0]?.id
                    ) {
                      distanceToBeAdded = 315;
                    } else if (y > 0) {
                      distanceToBeAdded = y;
                    } else {
                      distanceToBeAdded = 0;
                    }

                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  }

                  return updatedNode;
                }
              );

              updatedEdges.push(newEdge2);

              updatedNodes = updatedNodes.map((updatedNode, i) => {
                const node = newSuccessorsNodes.find(
                  (node: any) => node.id === updatedNode.id
                );

                if (!_isNil(node)) {
                  return node;
                }

                return updatedNode;
              });
            }

            const newEdgeAddId1 = generateUid('edge_');
            const newEdgeAddId2 = generateUid('edge_');
            const newEdgeAddId3 = generateUid('edge_');
            const newEdgeAddId4 = generateUid('edge_');

            const newEdgeAdd1: WorkflowEdgeType = {
              id: newEdgeAddId1,
              source: newSrNodeId,
              target: newAddNodeId,
              animated: false,
              sourceHandle: sourceId,
              style: { stroke: 'gray', strokeWidth: 1 },
              type: 'smoothEdge',
              label: 'True',
              data: {
                edgeType: 'then',
              },
            };

            const newEdgeAdd2 = {
              id: newEdgeAddId2,
              source: newSrNodeId,
              target: newAddNodeId2,
              sourceHandle: sourceId2,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              type: 'smoothEdge',
              label: 'False',
              data: {
                edgeType: 'else',
              },
            };

            const newEdgeAddTarget: WorkflowEdgeType = {
              id: newEdgeAddId3,
              source: newAddNodeId,
              target: newAddMergeNodeId,
              animated: false,
              sourceHandle: sourceId,
              style: { stroke: 'gray', strokeWidth: 1 },
              type: 'smoothEdge',
              data: {
                edgeType: 'then',
              },
            };

            const newEdgeAddTarget2 = {
              id: newEdgeAddId4,
              source: newAddNodeId2,
              target: newAddMergeNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              type: 'smoothEdge',
              data: {
                edgeType: 'then',
              },
            };

            updatedEdges.push(
              newEdgeAdd1,
              newEdgeAdd2,
              newEdgeAddTarget,
              newEdgeAddTarget2
            );

            updatedNodes.push(
              newSrNode,
              newAddNode,
              newAddNode2,
              newAddNodeMerge
            );

            setWorkflowEdges(appendTypeToEdges(updatedEdges));

            openRuleSheet({
              id: newSrNode.id,
              data: newSrNode.data,
            });

            return appendTypeToEdges(updatedEdges);
          });
        } else if (params.type === 'dbNode') {
          setEdges((edges) => {
            const newDbNodeId = generateUid('node_');
            const newAddNodeId = generateUid('node_');

            let rootId: string = currentNode?.data.rootId ?? '';

            // eslint-disable-next-line
            if (currentNode?.data.isMergeNode) {
              rootId =
                getRootNodeEqualToCurrentNodeRootId(currentNodes, currentNode)
                  ?.data.rootId ?? '';
            }

            // LIST OF SUCCESSOR NODES
            const successorsNodes = getOrderedSuccessorNodesRecursiveDFS(
              currentNodes,
              edges,
              params.id
            );

            const newDbNode: WorkflowNodeType = {
              id: newDbNodeId,
              type: 'dbNode',
              data: {
                nodeType: 'dbNode',
                ...functionsForNode,
                name: generateUniqueName(
                  updatedNodes,
                  params.connectorItem?.name ?? 'dbAction'
                ),
                rootId,
                entity: 'connector',
                entityId: params.connectorItem?.id ?? '',
                blockName: params.metaData?.name,
                settings: {
                  cacheEnabled: false,
                  durationUnit: timeToExpireUnits[2].value,
                  durationValue: 5,
                  rowLimit: 1000,
                  timeout: 30,
                  errorContinue: false,
                },
              },
              position: {
                x: (currentNode?.position.x ?? 100) - 29,
                y: (currentNode?.position.y ?? 0) + 55,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode: WorkflowNodeType = {
              id: newAddNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                rootId,
              },
              position: {
                x: newDbNode.position.x + 29,
                y: newDbNode.position.y + 110,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const nextNodeId = getNextNodeId(params.id, edges);

            const nextNode = currentNodes.find(
              (node) => node.id === nextNodeId
            );

            const newEdgeId = generateUid('edge_');
            const newEdgeId1 = generateUid('edge_');
            let updatedEdges = [...edges];

            const newEdgeAddNode = {
              id: newEdgeId,
              source: params.id,
              target: newDbNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const newEdge1 = {
              id: newEdgeId1,
              source: newDbNodeId,
              target: newAddNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const oldEdge = edges.find((edge) => edge.source === params.id);

            if (!_isNil(nextNode) && !_isNil(oldEdge)) {
              const newEdge2 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: newAddNodeId,
                target: nextNode.id,
              };

              let distanceToBeAdded = 165;

              const cloneSuccessorNodes = JSON.parse(
                JSON.stringify(successorsNodes)
              );

              const newSuccessorsNodes = cloneSuccessorNodes.map(
                (updatedNode: any, i: number) => {
                  const parentNodes = getParentNodes(
                    currentNodes,
                    edges,
                    updatedNode.id
                  );

                  const sortedParentNodes =
                    sortNodesByPositionYDesc(parentNodes);

                  if (
                    _isNil(currentNode?.data.rootId) ||
                    currentNode?.data.rootId === ''
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode !== true
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId !== updatedNode.data?.rootId
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode === true
                  ) {
                    let y = 0;

                    if (i === 0) {
                      y =
                        (newAddNode.position.y ?? 0) -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    } else {
                      y =
                        // eslint-disable-next-line
                        (cloneSuccessorNodes[i - 1]?.position?.y ?? 0) +
                        distanceToBeAdded -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    }

                    if (
                      !_isNil(cloneSuccessorNodes[i - 1]) &&
                      cloneSuccessorNodes[i - 1].id === sortedParentNodes[0]?.id
                    ) {
                      distanceToBeAdded = 165;
                    } else if (y > 0) {
                      distanceToBeAdded = y;
                    } else {
                      distanceToBeAdded = 0;
                    }

                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  }

                  return updatedNode;
                }
              );

              updatedEdges = updatedEdges.filter((edg) => {
                if (edg.source === params.id) {
                  return false;
                }

                return true;
              });

              updatedEdges.push(newEdge2);

              updatedNodes = updatedNodes.map((updatedNode, i) => {
                const node = newSuccessorsNodes.find(
                  (node: any) => node.id === updatedNode.id
                );

                if (!_isNil(node)) {
                  return node;
                }

                return updatedNode;
              });
            }

            updatedEdges.push(newEdge1, newEdgeAddNode);

            setWorkflowEdges(appendTypeToEdges(updatedEdges));

            updatedNodes = [...updatedNodes, newDbNode, newAddNode];

            openConnectorActionSheet({
              id: newDbNode.id,
              data: newDbNode.data,
            });

            return appendTypeToEdges(updatedEdges);
          });
        } else if (params.type === 'codeNode') {
          setEdges((edges) => {
            const newCodeNodeId = generateUid('node_');
            const newAddNodeId = generateUid('node_');

            let rootId: string = currentNode?.data.rootId ?? '';

            // eslint-disable-next-line
            if (currentNode?.data.isMergeNode) {
              rootId =
                getRootNodeEqualToCurrentNodeRootId(currentNodes, currentNode)
                  ?.data.rootId ?? '';
            }

            // LIST OF SUCCESSOR NODES
            const successorsNodes = getOrderedSuccessorNodesRecursiveDFS(
              currentNodes,
              edges,
              params.id
            );

            const newCodeNode: WorkflowNodeType = {
              id: newCodeNodeId,
              type: 'codeNode',
              data: {
                nodeType: 'codeNode',
                ...functionsForNode,
                name: generateUniqueName(updatedNodes, 'Code'),
                entityId: newCodeNodeId,
                rootId,
                entity: 'codeNode',
                blockName: 'code',
                input: {
                  language: {
                    value: 'JS',
                  },
                },
                settings: {
                  timeout: 10,
                  errorContinue: false,
                },
              },
              position: {
                x: (currentNode?.position.x ?? 100) - 29,
                y: (currentNode?.position.y ?? 0) + 55,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode: WorkflowNodeType = {
              id: newAddNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                rootId,
              },
              position: {
                x: newCodeNode.position.x + 29,
                y: newCodeNode.position.y + 110,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const nextNodeId = getNextNodeId(params.id, edges);

            const nextNode = currentNodes.find(
              (node) => node.id === nextNodeId
            );

            const newEdgeId = generateUid('edge_');
            const newEdgeId1 = generateUid('edge_');
            let updatedEdges = [...edges];

            const newEdgeAddNode = {
              id: newEdgeId,
              source: params.id,
              target: newCodeNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const newEdge1 = {
              id: newEdgeId1,
              source: newCodeNodeId,
              target: newAddNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const oldEdge = edges.find((edge) => edge.source === params.id);

            if (!_isNil(nextNode) && !_isNil(oldEdge)) {
              const newEdge2 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: newAddNodeId,
                target: nextNode.id,
              };

              let distanceToBeAdded = 165;

              const cloneSuccessorNodes = JSON.parse(
                JSON.stringify(successorsNodes)
              );

              const newSuccessorsNodes = cloneSuccessorNodes.map(
                (updatedNode: any, i: number) => {
                  const parentNodes = getParentNodes(
                    currentNodes,
                    edges,
                    updatedNode.id
                  );

                  const sortedParentNodes =
                    sortNodesByPositionYDesc(parentNodes);

                  if (
                    _isNil(currentNode?.data.rootId) ||
                    currentNode?.data.rootId === ''
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode !== true
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId !== updatedNode.data?.rootId
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode === true
                  ) {
                    let y = 0;

                    if (i === 0) {
                      y =
                        (newAddNode.position.y ?? 0) -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    } else {
                      y =
                        // eslint-disable-next-line
                        (cloneSuccessorNodes[i - 1]?.position?.y ?? 0) +
                        distanceToBeAdded -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    }

                    if (
                      !_isNil(cloneSuccessorNodes[i - 1]) &&
                      cloneSuccessorNodes[i - 1].id === sortedParentNodes[0]?.id
                    ) {
                      distanceToBeAdded = 165;
                    } else if (y > 0) {
                      distanceToBeAdded = y;
                    } else {
                      distanceToBeAdded = 0;
                    }

                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  }

                  return updatedNode;
                }
              );

              updatedEdges = updatedEdges.filter((edg) => {
                if (edg.source === params.id) {
                  return false;
                }

                return true;
              });

              updatedEdges.push(newEdge2);

              updatedNodes = updatedNodes.map((updatedNode, i) => {
                const node = newSuccessorsNodes.find(
                  (node: any) => node.id === updatedNode.id
                );

                if (!_isNil(node)) {
                  return node;
                }

                return updatedNode;
              });
            }

            updatedEdges.push(newEdge1, newEdgeAddNode);

            setWorkflowEdges(appendTypeToEdges(updatedEdges));

            updatedNodes = [...updatedNodes, newCodeNode, newAddNode];

            openCodeNodeSheet({
              id: newCodeNode.id,
              data: newCodeNode.data,
            });

            return appendTypeToEdges(updatedEdges);
          });
        } else if (params.type === 'delayNode') {
          setEdges((edges) => {
            const newDelayNodeId = generateUid('node_');
            const newAddNodeId = generateUid('node_');

            let rootId: string = currentNode?.data.rootId ?? '';

            // eslint-disable-next-line
            if (currentNode?.data.isMergeNode) {
              rootId =
                getRootNodeEqualToCurrentNodeRootId(currentNodes, currentNode)
                  ?.data.rootId ?? '';
            }

            // LIST OF SUCCESSOR NODES
            const successorsNodes = getOrderedSuccessorNodesRecursiveDFS(
              currentNodes,
              edges,
              params.id
            );

            const newDelayNode: WorkflowNodeType = {
              id: newDelayNodeId,
              type: 'delayNode',
              data: {
                nodeType: 'delayNode',
                ...functionsForNode,
                name: generateUniqueName(updatedNodes, 'DELAY'),
                entityId: newDelayNodeId,
                rootId,
                entity: 'delayNode',
                blockName: 'delay',
                input: {
                  action: {
                    value: 'immed',
                  },
                  unit: {
                    value: null,
                  },
                  amount: {
                    value: null,
                  },
                  dateTime: {
                    value: null,
                  },
                },
                settings: {
                  timeout: 60,
                },
              },
              position: {
                x: (currentNode?.position.x ?? 100) - 29,
                y: (currentNode?.position.y ?? 0) + 55,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode: WorkflowNodeType = {
              id: newAddNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                rootId,
              },
              position: {
                x: newDelayNode.position.x + 29,
                y: newDelayNode.position.y + 110,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const nextNodeId = getNextNodeId(params.id, edges);

            const nextNode = currentNodes.find(
              (node) => node.id === nextNodeId
            );

            const newEdgeId = generateUid('edge_');
            const newEdgeId1 = generateUid('edge_');
            let updatedEdges = [...edges];

            const newEdgeAddNode = {
              id: newEdgeId,
              source: params.id,
              target: newDelayNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const newEdge1 = {
              id: newEdgeId1,
              source: newDelayNodeId,
              target: newAddNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const oldEdge = edges.find((edge) => edge.source === params.id);

            if (!_isNil(nextNode) && !_isNil(oldEdge)) {
              const newEdge2 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: newAddNodeId,
                target: nextNode.id,
              };

              let distanceToBeAdded = 165;

              const cloneSuccessorNodes = JSON.parse(
                JSON.stringify(successorsNodes)
              );

              const newSuccessorsNodes = cloneSuccessorNodes.map(
                (updatedNode: any, i: number) => {
                  const parentNodes = getParentNodes(
                    currentNodes,
                    edges,
                    updatedNode.id
                  );

                  const sortedParentNodes =
                    sortNodesByPositionYDesc(parentNodes);

                  if (
                    _isNil(currentNode?.data.rootId) ||
                    currentNode?.data.rootId === ''
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode !== true
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId !== updatedNode.data?.rootId
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode === true
                  ) {
                    let y = 0;

                    if (i === 0) {
                      y =
                        (newAddNode.position.y ?? 0) -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    } else {
                      y =
                        // eslint-disable-next-line
                        (cloneSuccessorNodes[i - 1]?.position?.y ?? 0) +
                        distanceToBeAdded -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    }

                    if (
                      !_isNil(cloneSuccessorNodes[i - 1]) &&
                      cloneSuccessorNodes[i - 1].id === sortedParentNodes[0]?.id
                    ) {
                      distanceToBeAdded = 165;
                    } else if (y > 0) {
                      distanceToBeAdded = y;
                    } else {
                      distanceToBeAdded = 0;
                    }

                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  }

                  return updatedNode;
                }
              );

              updatedEdges = updatedEdges.filter((edg) => {
                if (edg.source === params.id) {
                  return false;
                }

                return true;
              });

              updatedEdges.push(newEdge2);

              updatedNodes = updatedNodes.map((updatedNode, i) => {
                const node = newSuccessorsNodes.find(
                  (node: any) => node.id === updatedNode.id
                );

                if (!_isNil(node)) {
                  return node;
                }

                return updatedNode;
              });
            }

            updatedEdges.push(newEdge1, newEdgeAddNode);

            setWorkflowEdges(appendTypeToEdges(updatedEdges));

            updatedNodes = [...updatedNodes, newDelayNode, newAddNode];

            openDelayNodeSheet({
              id: newDelayNode.id,
              data: newDelayNode.data,
            });

            return appendTypeToEdges(updatedEdges);
          });
        } else if (params.type === 'workflowNode') {
          setEdges((edges) => {
            const newWorkflowNodeId = generateUid('node_');
            const newAddNodeId = generateUid('node_');

            nodeId = newWorkflowNodeId;

            let rootId: string = currentNode?.data.rootId ?? '';

            // eslint-disable-next-line
            if (currentNode?.data.isMergeNode) {
              rootId =
                getRootNodeEqualToCurrentNodeRootId(currentNodes, currentNode)
                  ?.data.rootId ?? '';
            }

            // LIST OF SUCCESSOR NODES
            const successorsNodes = getOrderedSuccessorNodesRecursiveDFS(
              currentNodes,
              edges,
              params.id
            );

            const newWorkflowNode: WorkflowNodeType = {
              id: newWorkflowNodeId,
              type: 'workflowNode',
              data: {
                nodeType: 'workflowNode',
                ...functionsForNode,
                name: generateUniqueName(
                  updatedNodes,
                  params.workflowItem?.name ?? 'WORKFLOW_1'
                ),
                entityId: params.workflowItem?.id,
                rootId,
                entity: 'workflow',
                blockName: 'workflow',
                input: {},
                settings: {
                  timeout: 60,
                  errorContinue: false,
                },
              },
              position: {
                x: (currentNode?.position.x ?? 100) - 29,
                y: (currentNode?.position.y ?? 0) + 55,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode: WorkflowNodeType = {
              id: newAddNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                rootId,
              },
              position: {
                x: newWorkflowNode.position.x + 29,
                y: newWorkflowNode.position.y + 110,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const nextNodeId = getNextNodeId(params.id, edges);

            const nextNode = currentNodes.find(
              (node) => node.id === nextNodeId
            );

            const newEdgeId = generateUid('edge_');
            const newEdgeId1 = generateUid('edge_');
            let updatedEdges = [...edges];

            const newEdgeAddNode = {
              id: newEdgeId,
              source: params.id,
              target: newWorkflowNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const newEdge1 = {
              id: newEdgeId1,
              source: newWorkflowNodeId,
              target: newAddNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const oldEdge = edges.find((edge) => edge.source === params.id);

            if (!_isNil(nextNode) && !_isNil(oldEdge)) {
              const newEdge2 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: newAddNodeId,
                target: nextNode.id,
              };

              let distanceToBeAdded = 165;

              const cloneSuccessorNodes = JSON.parse(
                JSON.stringify(successorsNodes)
              );

              const newSuccessorsNodes = cloneSuccessorNodes.map(
                (updatedNode: any, i: number) => {
                  const parentNodes = getParentNodes(
                    currentNodes,
                    edges,
                    updatedNode.id
                  );

                  const sortedParentNodes =
                    sortNodesByPositionYDesc(parentNodes);

                  if (
                    _isNil(currentNode?.data.rootId) ||
                    currentNode?.data.rootId === ''
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode !== true
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId !== updatedNode.data?.rootId
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode === true
                  ) {
                    let y = 0;

                    if (i === 0) {
                      y =
                        (newAddNode.position.y ?? 0) -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    } else {
                      y =
                        // eslint-disable-next-line
                        (cloneSuccessorNodes[i - 1]?.position?.y ?? 0) +
                        distanceToBeAdded -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    }

                    if (
                      !_isNil(cloneSuccessorNodes[i - 1]) &&
                      cloneSuccessorNodes[i - 1].id === sortedParentNodes[0]?.id
                    ) {
                      distanceToBeAdded = 165;
                    } else if (y > 0) {
                      distanceToBeAdded = y;
                    } else {
                      distanceToBeAdded = 0;
                    }

                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  }

                  return updatedNode;
                }
              );

              updatedEdges = updatedEdges.filter((edg) => {
                if (edg.source === params.id) {
                  return false;
                }

                return true;
              });

              updatedEdges.push(newEdge2);

              updatedNodes = updatedNodes.map((updatedNode, i) => {
                const node = newSuccessorsNodes.find(
                  (node: any) => node.id === updatedNode.id
                );

                if (!_isNil(node)) {
                  return node;
                }

                return updatedNode;
              });
            }

            updatedEdges.push(newEdge1, newEdgeAddNode);

            setWorkflowEdges(appendTypeToEdges(updatedEdges));

            updatedNodes = [...updatedNodes, newWorkflowNode, newAddNode];

            openWorkflowNode({
              id: newWorkflowNodeId,
              data: newWorkflowNode.data,
            });

            return appendTypeToEdges(updatedEdges);
          });
        } else if (params.type === 'responseNode') {
          setEdges((edges) => {
            const newResponseNodeId = generateUid('node_');
            const newAddNodeId = generateUid('node_');

            let rootId: string = currentNode?.data.rootId ?? '';

            const rootElement = currentNodes.find((node) => node.id === rootId);

            // eslint-disable-next-line
            if (currentNode?.data.isMergeNode) {
              rootId =
                getRootNodeEqualToCurrentNodeRootId(currentNodes, currentNode)
                  ?.data.rootId ?? '';
            }

            // LIST OF SUCCESSOR NODES
            const successorsNodes = getOrderedSuccessorNodesRecursiveDFS(
              currentNodes,
              edges,
              params.id
            );

            const newResponseNode: WorkflowNodeType = {
              id: newResponseNodeId,
              type: 'responseNode',
              data: {
                nodeType: 'responseNode',
                ...functionsForNode,
                name: generateUniqueName(updatedNodes, 'Response'),
                entityId: newResponseNodeId,
                rootId,
                entity: 'responseNode',
                blockName: 'response',
                input: {},
                settings: {
                  timeout: 60,
                },
              },
              position: {
                x: (currentNode?.position.x ?? 100) - 29,
                y: (currentNode?.position.y ?? 0) + 55,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode: WorkflowNodeType = {
              id: newAddNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                rootId,
              },
              position: {
                x: newResponseNode.position.x + 29,
                y: newResponseNode.position.y + 110,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const nextNodeId = getNextNodeId(params.id, edges);

            const nextNode = currentNodes.find(
              (node) => node.id === nextNodeId
            );

            const newEdgeId = generateUid('edge_');
            const newEdgeId1 = generateUid('edge_');
            let updatedEdges = [...edges];

            const newEdgeAddNode = {
              id: newEdgeId,
              source: params.id,
              target: newResponseNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const newEdge1 = {
              id: newEdgeId1,
              source: newResponseNodeId,
              target: newAddNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const oldEdge = edges.find((edge) => edge.source === params.id);

            if (
              !_isNil(nextNode) &&
              nextNode.type !== 'delayNode' &&
              (_isEmpty(rootId) ||
                // eslint-disable-next-line
                (currentNode?.data.isMergeNode && !rootElement?.data.rootId))
            ) {
              const newDelayNodeId = generateUid('node_');
              const newAddNodeIdDelay = generateUid('node_');

              const newDelayNode: WorkflowNodeType = {
                id: newDelayNodeId,
                type: 'delayNode',
                data: {
                  nodeType: 'delayNode',
                  ...functionsForNode,
                  name: generateUniqueName(updatedNodes, 'DELAY'),
                  entityId: newDelayNodeId,
                  rootId: '',
                  entity: 'delayNode',
                  blockName: 'delay',
                  input: {
                    action: {
                      value: 'immed',
                    },
                    unit: {
                      value: null,
                    },
                    amount: {
                      value: null,
                    },
                    dateTime: {
                      value: null,
                    },
                  },
                  settings: {
                    timeout: 60,
                  },
                },
                position: {
                  x: (currentNode?.position.x ?? 100) - 29,
                  y: (currentNode?.position.y ?? 0) + 55,
                },
                targetPosition: Position.Top,
                draggable: false,
              };

              const newAddNodeDelay: WorkflowNodeType = {
                id: newAddNodeIdDelay,
                type: 'addNode',
                data: {
                  ...functionsForNode,
                  nodeType: 'addNodeMd',
                  rootId: '',
                },
                position: {
                  x: newDelayNode.position.x + 29,
                  y: newDelayNode.position.y + 110,
                },
                targetPosition: Position.Top,
                draggable: false,
              };

              const newEdge2 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: newAddNodeId,
                target: newDelayNodeId,
              };

              const newEdge3 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: newDelayNodeId,
                target: newAddNodeIdDelay,
              };

              const newEdge4 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: newAddNodeIdDelay,
                target: nextNode.id,
              };

              updatedEdges = updatedEdges.filter(
                (edg) => edg.source !== params.id
              );

              updatedEdges.push(newEdge2, newEdge3, newEdge4);
              updatedNodes.push(newDelayNode, newAddNodeDelay);
            } else if (!_isNil(nextNode) && !_isNil(oldEdge)) {
              const newEdge2 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: newAddNodeId,
                target: nextNode.id,
              };

              let distanceToBeAdded = 165;

              const cloneSuccessorNodes = JSON.parse(
                JSON.stringify(successorsNodes)
              );

              const newSuccessorsNodes = cloneSuccessorNodes.map(
                (updatedNode: any, i: number) => {
                  const parentNodes = getParentNodes(
                    currentNodes,
                    edges,
                    updatedNode.id
                  );

                  const sortedParentNodes =
                    sortNodesByPositionYDesc(parentNodes);

                  if (
                    _isNil(currentNode?.data.rootId) ||
                    currentNode?.data.rootId === ''
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode !== true
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId !== updatedNode.data?.rootId
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode === true
                  ) {
                    let y = 0;

                    if (i === 0) {
                      y =
                        (newAddNode.position.y ?? 0) -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    } else {
                      y =
                        // eslint-disable-next-line
                        (cloneSuccessorNodes[i - 1]?.position?.y ?? 0) +
                        distanceToBeAdded -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    }

                    if (
                      !_isNil(cloneSuccessorNodes[i - 1]) &&
                      cloneSuccessorNodes[i - 1].id === sortedParentNodes[0]?.id
                    ) {
                      distanceToBeAdded = 165;
                    } else if (y > 0) {
                      distanceToBeAdded = y;
                    } else {
                      distanceToBeAdded = 0;
                    }

                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  }

                  return updatedNode;
                }
              );

              updatedEdges = updatedEdges.filter((edg) => {
                if (edg.source === params.id) {
                  return false;
                }

                return true;
              });

              updatedEdges.push(newEdge2);

              updatedNodes = updatedNodes.map((updatedNode, i) => {
                const node = newSuccessorsNodes.find(
                  (node: any) => node.id === updatedNode.id
                );

                if (!_isNil(node)) {
                  return node;
                }

                return updatedNode;
              });
            }

            updatedEdges.push(newEdge1, newEdgeAddNode);

            setWorkflowEdges(appendTypeToEdges(updatedEdges));

            updatedNodes = [...updatedNodes, newResponseNode, newAddNode];

            openResponseNodeSheet({
              id: newResponseNode.id,
              data: newResponseNode.data,
            });

            return appendTypeToEdges(updatedEdges);
          });
        } else if (params.type === 'setVariableNode') {
          setEdges((edges) => {
            const newSetVariableNodeId = generateUid('node_');
            const newAddNodeId = generateUid('node_');

            let rootId: string = currentNode?.data.rootId ?? '';

            // eslint-disable-next-line
            if (currentNode?.data.isMergeNode) {
              rootId =
                getRootNodeEqualToCurrentNodeRootId(currentNodes, currentNode)
                  ?.data.rootId ?? '';
            }

            // LIST OF SUCCESSOR NODES
            const successorsNodes = getOrderedSuccessorNodesRecursiveDFS(
              currentNodes,
              edges,
              params.id
            );

            const newSetVariableNode: WorkflowNodeType = {
              id: newSetVariableNodeId,
              type: 'setVarNode',
              data: {
                nodeType: 'setVarNode',
                ...functionsForNode,
                name: generateUniqueName(updatedNodes, 'SetVariable'),
                entityId: newSetVariableNodeId,
                rootId,
                entity: 'setVariableNode',
                blockName: 'setVariable',
                input: {},
                settings: {
                  timeout: 30,
                  errorContinue: false,
                },
              },
              position: {
                x: (currentNode?.position.x ?? 100) - 29,
                y: (currentNode?.position.y ?? 0) + 55,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode: WorkflowNodeType = {
              id: newAddNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                rootId,
              },
              position: {
                x: newSetVariableNode.position.x + 29,
                y: newSetVariableNode.position.y + 110,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const nextNodeId = getNextNodeId(params.id, edges);

            const nextNode = currentNodes.find(
              (node) => node.id === nextNodeId
            );

            const newEdgeId = generateUid('edge_');
            const newEdgeId1 = generateUid('edge_');
            let updatedEdges = [...edges];

            const newEdgeAddNode = {
              id: newEdgeId,
              source: params.id,
              target: newSetVariableNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const newEdge1 = {
              id: newEdgeId1,
              source: newSetVariableNodeId,
              target: newAddNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const oldEdge = edges.find((edge) => edge.source === params.id);

            if (!_isNil(nextNode) && !_isNil(oldEdge)) {
              const newEdge2 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: newAddNodeId,
                target: nextNode.id,
              };

              let distanceToBeAdded = 165;

              const cloneSuccessorNodes = JSON.parse(
                JSON.stringify(successorsNodes)
              );

              const newSuccessorsNodes = cloneSuccessorNodes.map(
                (updatedNode: any, i: number) => {
                  const parentNodes = getParentNodes(
                    currentNodes,
                    edges,
                    updatedNode.id
                  );

                  const sortedParentNodes =
                    sortNodesByPositionYDesc(parentNodes);

                  if (
                    _isNil(currentNode?.data.rootId) ||
                    currentNode?.data.rootId === ''
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode !== true
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId !== updatedNode.data?.rootId
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode === true
                  ) {
                    let y = 0;

                    if (i === 0) {
                      y =
                        (newAddNode.position.y ?? 0) -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    } else {
                      y =
                        // eslint-disable-next-line
                        (cloneSuccessorNodes[i - 1]?.position?.y ?? 0) +
                        distanceToBeAdded -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    }

                    if (
                      !_isNil(cloneSuccessorNodes[i - 1]) &&
                      cloneSuccessorNodes[i - 1].id === sortedParentNodes[0]?.id
                    ) {
                      distanceToBeAdded = 165;
                    } else if (y > 0) {
                      distanceToBeAdded = y;
                    } else {
                      distanceToBeAdded = 0;
                    }

                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  }

                  return updatedNode;
                }
              );

              updatedEdges = updatedEdges.filter((edg) => {
                if (edg.source === params.id) {
                  return false;
                }

                return true;
              });

              updatedEdges.push(newEdge2);

              updatedNodes = updatedNodes.map((updatedNode, i) => {
                const node = newSuccessorsNodes.find(
                  (node: any) => node.id === updatedNode.id
                );

                if (!_isNil(node)) {
                  return node;
                }

                return updatedNode;
              });
            }

            updatedEdges.push(newEdge1, newEdgeAddNode);

            setWorkflowEdges(appendTypeToEdges(updatedEdges));

            updatedNodes = [...updatedNodes, newSetVariableNode, newAddNode];

            openSetVariableNodeSheet({
              id: newSetVariableNode.id,
              data: newSetVariableNode.data,
            });

            return appendTypeToEdges(updatedEdges);
          });
        } else if (params.type === 'restApiNode') {
          setEdges((edges) => {
            const newRestApiNodeId = generateUid('node_');
            const newAddNodeId = generateUid('node_');

            let rootId: string = currentNode?.data.rootId ?? '';

            // eslint-disable-next-line
            if (currentNode?.data.isMergeNode) {
              rootId =
                getRootNodeEqualToCurrentNodeRootId(currentNodes, currentNode)
                  ?.data.rootId ?? '';
            }

            // LIST OF SUCCESSOR NODES
            const successorsNodes = getOrderedSuccessorNodesRecursiveDFS(
              currentNodes,
              edges,
              params.id
            );

            const newRestApiNode: WorkflowNodeType = {
              id: newRestApiNodeId,
              type: 'restApiNode',
              data: {
                nodeType: 'restApiNode',
                ...functionsForNode,
                name: generateUniqueName(
                  updatedNodes,
                  params.connectorItem?.name ?? 'restApiAction'
                ),
                rootId,
                entity: 'connector',
                entityId: params.connectorItem?.id ?? '',
                blockName: params.metaData?.name,
                input: {},
                settings: {
                  timeout: 30,
                  errorContinue: false,
                },
              },
              position: {
                x: (currentNode?.position.x ?? 100) - 29,
                y: (currentNode?.position.y ?? 0) + 55,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode: WorkflowNodeType = {
              id: newAddNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                rootId,
              },
              position: {
                x: newRestApiNode.position.x + 29,
                y: newRestApiNode.position.y + 110,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const nextNodeId = getNextNodeId(params.id, edges);

            const nextNode = currentNodes.find(
              (node) => node.id === nextNodeId
            );

            const newEdgeId = generateUid('edge_');
            const newEdgeId1 = generateUid('edge_');
            let updatedEdges = [...edges];

            const newEdgeAddNode = {
              id: newEdgeId,
              source: params.id,
              target: newRestApiNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const newEdge1 = {
              id: newEdgeId1,
              source: newRestApiNodeId,
              target: newAddNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const oldEdge = edges.find((edge) => edge.source === params.id);

            if (!_isNil(nextNode) && !_isNil(oldEdge)) {
              const newEdge2 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: newAddNodeId,
                target: nextNode.id,
              };

              let distanceToBeAdded = 165;

              const cloneSuccessorNodes = JSON.parse(
                JSON.stringify(successorsNodes)
              );

              const newSuccessorsNodes = cloneSuccessorNodes.map(
                (updatedNode: any, i: number) => {
                  const parentNodes = getParentNodes(
                    currentNodes,
                    edges,
                    updatedNode.id
                  );

                  const sortedParentNodes =
                    sortNodesByPositionYDesc(parentNodes);

                  if (
                    _isNil(currentNode?.data.rootId) ||
                    currentNode?.data.rootId === ''
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode !== true
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId !== updatedNode.data?.rootId
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode === true
                  ) {
                    let y = 0;

                    if (i === 0) {
                      y =
                        (newAddNode.position.y ?? 0) -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    } else {
                      y =
                        // eslint-disable-next-line
                        (cloneSuccessorNodes[i - 1]?.position?.y ?? 0) +
                        distanceToBeAdded -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    }

                    if (
                      !_isNil(cloneSuccessorNodes[i - 1]) &&
                      cloneSuccessorNodes[i - 1].id === sortedParentNodes[0]?.id
                    ) {
                      distanceToBeAdded = 165;
                    } else if (y > 0) {
                      distanceToBeAdded = y;
                    } else {
                      distanceToBeAdded = 0;
                    }

                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  }

                  return updatedNode;
                }
              );

              updatedEdges = updatedEdges.filter((edg) => {
                if (edg.source === params.id) {
                  return false;
                }

                return true;
              });

              updatedEdges.push(newEdge2);

              updatedNodes = updatedNodes.map((updatedNode, i) => {
                const node = newSuccessorsNodes.find(
                  (node: any) => node.id === updatedNode.id
                );

                if (!_isNil(node)) {
                  return node;
                }

                return updatedNode;
              });
            }

            updatedEdges.push(newEdge1, newEdgeAddNode);

            setWorkflowEdges(appendTypeToEdges(updatedEdges));

            updatedNodes = [...updatedNodes, newRestApiNode, newAddNode];

            openRestApiActionSheet({
              id: newRestApiNode.id,
              data: newRestApiNode.data,
            });

            return appendTypeToEdges(updatedEdges);
          });
        } else if (params.type === 'switchNode') {
          setEdges((currentEdges) => {
            let updatedEdges = [...currentEdges];

            const oldEdge = currentEdges.find(
              (edge) => edge.source === params.id
            );

            let rootId: string = currentNode?.data.rootId ?? '';
            // eslint-disable-next-line
            if (currentNode?.data.isMergeNode) {
              rootId =
                // eslint-disable-next-line
                // eslint-disable-next-line
                getRootNodeEqualToCurrentNodeRootId(currentNodes, currentNode)
                  ?.data.rootId ?? '';
            }

            const newSwitchNodeId = generateUid('node_');
            const newAddNodeId = generateUid('node_');
            const newAddNodeId2 = generateUid('node_');
            const newAddMergeNodeId = generateUid('node_');

            const groupId = generateUid('group_');
            const conditionId = generateUid('condition_');
            const lhsId = generateUid('lhs_');

            const currentPath = {
              [groupId]: {
                nodeType: 'group',
                name: 'Path',
                operator: '',
                children: [conditionId],
              },
              [conditionId]: {
                dataType: '',
                operator: 'any',
                leftNode: [lhsId],
                rightNode: [],
                parent: groupId,
                nodeType: 'condition',
              },
              [lhsId]: {
                dataType: '',
                parent: conditionId,
                nodeType: 'params',
                sourceType: '',
                attribute: '',
              },
            };

            const conditions = {
              nodes: currentPath,
            };

            const switcher = [
              {
                pathId: groupId,
                name: 'Path',
              },
            ];

            // LIST OF SUCCESSOR NODES
            const successorsNodes = getOrderedSuccessorNodesRecursiveDFS(
              currentNodes,
              currentEdges,
              params.id
            );

            const nextNodeId = getNextNodeId(params.id, currentEdges);
            const nextNode = currentNodes.find(
              (node) => node.id === nextNodeId
            );

            const newSwitchNode: WorkflowNodeType = {
              id: newSwitchNodeId,
              type: 'switchNode',
              data: {
                nodeType: 'switchNode',
                ...functionsForNode,
                name: generateUniqueName(updatedNodes, 'SwitchBlock'),
                rootId,
                entityId: newSwitchNodeId,
                entity: 'switch',
                conditions,
                switcher,
                blockName: 'switchBlock',
                input: {
                  defaultPath: {
                    value: true,
                  },
                },
                settings: {
                  timeout: 30,
                },
              },
              position: {
                x: (currentNode?.position.x ?? 100) - 29,
                y: (currentNode?.position.y ?? 0) + 55,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode: WorkflowNodeType = {
              id: newAddNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                rootId: newSwitchNodeId,
              },
              position: {
                x: newSwitchNode.position.x - 120,
                y: newSwitchNode.position.y + 128,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode2: WorkflowNodeType = {
              id: newAddNodeId2,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                rootId: newSwitchNodeId,
              },
              position: {
                x: newSwitchNode.position.x + 180,
                y: newSwitchNode.position.y + 128,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNodeMerge: WorkflowNodeType = {
              id: newAddMergeNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                isMergeNode: true,
                rootId: newSwitchNodeId,
              },
              position: {
                x: newSwitchNode.position.x + 29,
                y: newSwitchNode.position.y + 274,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            if (successorsNodes.length === 0) {
              const newConnectEdgeId = generateUid('edge_');
              const newConnectEdge: WorkflowEdgeType = {
                id: newConnectEdgeId,
                source: params.id,
                target: newSwitchNodeId,
                animated: false,
                style: { stroke: 'gray', strokeWidth: 1 },
                type: 'smoothEdge',
                data: {
                  edgeType: 'then',
                },
              };

              updatedEdges.push(newConnectEdge);
            } else if (!_isNil(nextNode) && !_isNil(oldEdge)) {
              updatedEdges = updatedEdges.filter((edg) => {
                if (edg.source === params.id) {
                  return false;
                }

                return true;
              });

              const newConnectEdgeId = generateUid('edge_');

              const newConnectEdge: WorkflowEdgeType = {
                id: newConnectEdgeId,
                source: newAddMergeNodeId,
                target: nextNode.id,
                animated: false,
                style: { stroke: 'gray', strokeWidth: 1 },
                type: 'smoothEdge',
                data: {
                  edgeType: 'then',
                },
              };

              updatedEdges.push(newConnectEdge);

              const newEdge2 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: params.id,
                target: newSwitchNodeId,
              };

              let distanceToBeAdded = 315;

              const cloneSuccessorNodes = JSON.parse(
                JSON.stringify(successorsNodes)
              );

              const newSuccessorsNodes = cloneSuccessorNodes.map(
                (updatedNode: any, i: number) => {
                  const parentNodes = getParentNodes(
                    currentNodes,
                    currentEdges,
                    updatedNode.id
                  );

                  const sortedParentNodes =
                    sortNodesByPositionYDesc(parentNodes);

                  if (
                    _isNil(currentNode?.data.rootId) ||
                    currentNode?.data.rootId === ''
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode !== true
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId !== updatedNode.data?.rootId
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode === true
                  ) {
                    let y = 0;

                    if (i === 0) {
                      y =
                        (newAddNodeMerge.position.y ?? 0) -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    } else {
                      y =
                        // eslint-disable-next-line
                        (cloneSuccessorNodes[i - 1]?.position?.y ?? 0) +
                        distanceToBeAdded -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    }

                    if (
                      !_isNil(cloneSuccessorNodes[i - 1]) &&
                      cloneSuccessorNodes[i - 1].id === sortedParentNodes[0]?.id
                    ) {
                      distanceToBeAdded = 315;
                    } else if (y > 0) {
                      distanceToBeAdded = y;
                    } else {
                      distanceToBeAdded = 0;
                    }

                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  }

                  return updatedNode;
                }
              );

              updatedEdges.push(newEdge2);

              updatedNodes = updatedNodes.map((updatedNode, i) => {
                const node = newSuccessorsNodes.find(
                  (node: any) => node.id === updatedNode.id
                );

                if (!_isNil(node)) {
                  return node;
                }

                return updatedNode;
              });
            }

            const newEdgeAddId1 = generateUid('edge_');
            const newEdgeAddId2 = generateUid('edge_');
            const newEdgeAddId3 = generateUid('edge_');
            const newEdgeAddId4 = generateUid('edge_');

            const newEdgeAdd1: WorkflowEdgeType = {
              id: newEdgeAddId1,
              source: newSwitchNodeId,
              target: newAddNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              type: 'smoothEdge',
              data: {
                edgeType: 'case',
                pathId: groupId,
              },
            };

            const newEdgeAdd2 = {
              id: newEdgeAddId2,
              source: newSwitchNodeId,
              target: newAddNodeId2,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              type: 'smoothEdge',
              data: {
                edgeType: 'defaultCase',
              },
            };

            const newEdgeAddTarget: WorkflowEdgeType = {
              id: newEdgeAddId3,
              source: newAddNodeId,
              target: newAddMergeNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              type: 'smoothEdge',
              data: {
                edgeType: 'then',
              },
            };

            const newEdgeAddTarget2 = {
              id: newEdgeAddId4,
              source: newAddNodeId2,
              target: newAddMergeNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              type: 'smoothEdge',
              data: {
                edgeType: 'then',
              },
            };

            updatedEdges.push(
              newEdgeAdd1,
              newEdgeAdd2,
              newEdgeAddTarget,
              newEdgeAddTarget2
            );

            updatedNodes.push(
              newSwitchNode,
              newAddNode,
              newAddNode2,
              newAddNodeMerge
            );
            setWorkflowEdges(appendTypeToEdges(updatedEdges));

            openSwitchNodeSheet({
              id: newSwitchNode.id,
              data: newSwitchNode.data,
            });

            return appendTypeToEdges(updatedEdges);
          });
        } else if (params.type === 'gSheetNode') {
          setEdges((edges) => {
            const newGSheetNodeId = generateUid('node_');
            const newAddNodeId = generateUid('node_');

            let rootId: string = currentNode?.data.rootId ?? '';

            // eslint-disable-next-line
            if (currentNode?.data.isMergeNode) {
              rootId =
                getRootNodeEqualToCurrentNodeRootId(currentNodes, currentNode)
                  ?.data.rootId ?? '';
            }

            // LIST OF SUCCESSOR NODES
            const successorsNodes = getOrderedSuccessorNodesRecursiveDFS(
              currentNodes,
              edges,
              params.id
            );

            const newGSheetNode: WorkflowNodeType = {
              id: newGSheetNodeId,
              type: 'gSheetNode',
              data: {
                nodeType: 'gSheetNode',
                ...functionsForNode,
                name: generateUniqueName(
                  updatedNodes,
                  params.connectorItem?.name ?? 'gSheetAction'
                ),
                rootId,
                entity: 'connector',
                entityId: params.connectorItem?.id ?? '',
                blockName: params.metaData?.name,
                input: {
                  mappingMethod: { value: 'automatic' },
                },
                settings: {
                  timeout: 30,
                  cacheEnabled: false,
                  durationUnit: timeToExpireUnits[2].value,
                  durationValue: 5,
                  rowLimit: 1000,
                  errorContinue: false,
                },
              },
              position: {
                x: (currentNode?.position.x ?? 100) - 29,
                y: (currentNode?.position.y ?? 0) + 55,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const newAddNode: WorkflowNodeType = {
              id: newAddNodeId,
              type: 'addNode',
              data: {
                ...functionsForNode,
                nodeType: 'addNodeMd',
                rootId,
              },
              position: {
                x: newGSheetNode.position.x + 29,
                y: newGSheetNode.position.y + 110,
              },
              targetPosition: Position.Top,
              draggable: false,
            };

            const nextNodeId = getNextNodeId(params.id, edges);

            const nextNode = currentNodes.find(
              (node) => node.id === nextNodeId
            );

            const newEdgeId = generateUid('edge_');
            const newEdgeId1 = generateUid('edge_');
            let updatedEdges = [...edges];

            const newEdgeAddNode = {
              id: newEdgeId,
              source: params.id,
              target: newGSheetNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const newEdge1 = {
              id: newEdgeId1,
              source: newGSheetNodeId,
              target: newAddNodeId,
              animated: false,
              style: { stroke: 'gray', strokeWidth: 1 },
              data: {
                edgeType: 'then',
              },
            };

            const oldEdge = edges.find((edge) => edge.source === params.id);

            if (!_isNil(nextNode) && !_isNil(oldEdge)) {
              const newEdge2 = {
                ...oldEdge,
                id: generateUid('edge_'),
                source: newAddNodeId,
                target: nextNode.id,
              };

              let distanceToBeAdded = 165;

              const cloneSuccessorNodes = JSON.parse(
                JSON.stringify(successorsNodes)
              );

              const newSuccessorsNodes = cloneSuccessorNodes.map(
                (updatedNode: any, i: number) => {
                  const parentNodes = getParentNodes(
                    currentNodes,
                    edges,
                    updatedNode.id
                  );

                  const sortedParentNodes =
                    sortNodesByPositionYDesc(parentNodes);

                  if (
                    _isNil(currentNode?.data.rootId) ||
                    currentNode?.data.rootId === ''
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode !== true
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId !== updatedNode.data?.rootId
                  ) {
                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  } else if (
                    !_isNil(currentNode?.data.rootId) &&
                    currentNode?.data.rootId === updatedNode.data?.rootId &&
                    updatedNode.data?.isMergeNode === true
                  ) {
                    let y = 0;

                    if (i === 0) {
                      y =
                        (newAddNode.position.y ?? 0) -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    } else {
                      y =
                        // eslint-disable-next-line
                        (cloneSuccessorNodes[i - 1]?.position?.y ?? 0) +
                        distanceToBeAdded -
                        (sortedParentNodes[0]?.position?.y ?? 0);
                    }

                    if (
                      !_isNil(cloneSuccessorNodes[i - 1]) &&
                      cloneSuccessorNodes[i - 1].id === sortedParentNodes[0]?.id
                    ) {
                      distanceToBeAdded = 165;
                    } else if (y > 0) {
                      distanceToBeAdded = y;
                    } else {
                      distanceToBeAdded = 0;
                    }

                    updatedNode.position.y =
                      (updatedNode.position.y as number) + distanceToBeAdded;
                  }

                  return updatedNode;
                }
              );

              updatedEdges = updatedEdges.filter((edg) => {
                if (edg.source === params.id) {
                  return false;
                }

                return true;
              });

              updatedEdges.push(newEdge2);

              updatedNodes = updatedNodes.map((updatedNode, i) => {
                const node = newSuccessorsNodes.find(
                  (node: any) => node.id === updatedNode.id
                );

                if (!_isNil(node)) {
                  return node;
                }

                return updatedNode;
              });
            }

            updatedEdges.push(newEdge1, newEdgeAddNode);

            setWorkflowEdges(appendTypeToEdges(updatedEdges));

            updatedNodes = [...updatedNodes, newGSheetNode, newAddNode];

            openGSheetNodeSheet({
              id: newGSheetNode.id,
              data: newGSheetNode.data,
            });

            return appendTypeToEdges(updatedEdges);
          });
        }

        sendEventToGTM({
          event: 'workflow',
          source: 'listing',
          element: currentNode?.type,
          action: 'add_click',
          type: params.type,
        });

        setWorkflowNodes(updateNodesWithMethods(updatedNodes));

        return updateNodesWithMethods(updatedNodes);
      });

      if (
        !_isNil(params.type) &&
        ['srNode', 'dtNode', 'ruleSetNode', 'workflowNode'].includes(
          params.type
        )
      ) {
        let entityId = '';
        let name = '';
        let subType = '';
        let type = '';

        if (['srNode', 'dtNode', 'ruleSetNode'].includes(params.type)) {
          entityId = params.ruleItem?.id ?? '';
          name = params.ruleItem?.name ?? '';
          subType = params.ruleItem?.type ?? '';
          type = 'rules';
        } else if (params.type === 'workflowNode') {
          entityId = params.workflowItem?.id ?? '';
          name = params.workflowItem?.name ?? '';
          type = 'workflow';
        }

        void handleVersionMapping({
          nodeId,
          entityId,
          name,
          type,
          subType,
        });
      }
    },
    [nodes, edges]
  );

  const deleteNode = useCallback(
    (params: DeleteNodeParams) => {
      setNodes((currentNodes) => {
        const currentNode = currentNodes.find((node) => node.id === params.id);
        let updatedNodes = [...currentNodes];
        let nodeIdsToBeRemoved: string[] = [params.id];
        let edgeIdsToBeRemoved: string[] = [];

        setEdges((currentEdges) => {
          let updatedEdges = [...currentEdges];

          if (
            params.type === 'dtNode' ||
            params.type === 'dbNode' ||
            params.type === 'ruleSetNode' ||
            params.type === 'restApiNode' ||
            params.type === 'gSheetNode'
          ) {
            const parentEdges = getParentEdges(params.id, currentEdges);

            const { childNodes, childEdges } = getChildNodesAndEdges(
              params.id,
              currentEdges,
              currentNodes
            );

            const updatedSuccessors: WorkflowNodeType[] = [];

            childNodes.forEach((n) => {
              if (!_isNil(n)) {
                nodeIdsToBeRemoved.push(n.id);

                const { childEdges } = getChildNodesAndEdges(
                  n.id,
                  currentEdges,
                  currentNodes
                );

                const successorsNodes = getOrderedSuccessorNodesRecursiveDFS(
                  currentNodes,
                  currentEdges,
                  n.id
                );
                const cloneOfSuccessors: WorkflowNodeType[] = JSON.parse(
                  JSON.stringify(successorsNodes)
                );

                let distanceToBeDeletedWith = 165;

                cloneOfSuccessors.forEach(
                  (node: WorkflowNodeType, i: number) => {
                    const parentNodes = getParentNodes(
                      currentNodes,
                      currentEdges,
                      node.id
                    );

                    const sortedParentNodes =
                      sortNodesByPositionYDesc(parentNodes);

                    const parentsNotCurrentParent = sortedParentNodes.filter(
                      (n) => n.id !== cloneOfSuccessors[i - 1]?.id
                    );

                    // TODO: TO BE MODIFIED
                    if (
                      _isNil(currentNode?.data?.rootId) ||
                      currentNode?.data?.rootId === ''
                    ) {
                      updatedSuccessors.push({
                        ...node,
                        position: {
                          ...node.position,
                          y: node.position.y - distanceToBeDeletedWith,
                        },
                      });
                    } else if (
                      !_isNil(currentNode?.data.rootId) &&
                      currentNode?.data.rootId === node.data?.rootId &&
                      node.data?.isMergeNode !== true
                    ) {
                      updatedSuccessors.push({
                        ...node,
                        position: {
                          ...node.position,
                          y: node.position.y - distanceToBeDeletedWith,
                        },
                      });
                    } else if (
                      !_isNil(currentNode?.data.rootId) &&
                      currentNode?.data.rootId !== node.data?.rootId
                    ) {
                      updatedSuccessors.push({
                        ...node,
                        position: {
                          ...node.position,
                          y: node.position.y - distanceToBeDeletedWith,
                        },
                      });
                    } else if (
                      !_isNil(currentNode?.data.rootId) &&
                      currentNode?.data.rootId === node.data?.rootId &&
                      node.data?.isMergeNode === true
                    ) {
                      const oldPos = cloneOfSuccessors[i - 1]?.position.y ?? 0;

                      let y = 0;

                      if (i === 0) {
                        y =
                          (n.position.y ?? 0) -
                          (parentsNotCurrentParent.filter(
                            (nd) => nd.id !== n.id
                          )[0]?.position?.y ?? 0);
                      } else {
                        y =
                          (cloneOfSuccessors[i - 1]?.position?.y ?? 0) -
                          distanceToBeDeletedWith -
                          (parentsNotCurrentParent[0]?.position?.y ?? 0);
                      }

                      if (
                        !_isNil(cloneOfSuccessors[i - 1]) &&
                        cloneOfSuccessors[i - 1].id ===
                          parentsNotCurrentParent[0]?.id
                      ) {
                        updatedSuccessors.push({
                          ...node,
                          position: {
                            ...node.position,
                            y: node.position.y - distanceToBeDeletedWith,
                          },
                        });
                      } else if (y > 0) {
                        updatedSuccessors.push({
                          ...node,
                          position: {
                            ...node.position,
                            y: node.position.y - distanceToBeDeletedWith,
                          },
                        });
                      } else if (
                        y < 0 &&
                        oldPos > (parentsNotCurrentParent[0]?.position?.y ?? 0)
                      ) {
                        distanceToBeDeletedWith =
                          oldPos -
                          (parentsNotCurrentParent[0]?.position?.y ?? 0);

                        updatedSuccessors.push({
                          ...node,
                          position: {
                            ...node.position,
                            y: node.position.y - distanceToBeDeletedWith,
                          },
                        });
                      } else {
                        distanceToBeDeletedWith = 0;

                        updatedSuccessors.push({
                          ...node,
                          position: {
                            ...node.position,
                            y: node.position.y - distanceToBeDeletedWith,
                          },
                        });
                      }
                    }
                  }
                );

                childEdges.forEach((e) => {
                  if (!_isNil(e)) {
                    edgeIdsToBeRemoved.push(e.id);
                  }
                });
              }
            });

            childEdges.forEach((e) => {
              if (!_isNil(e)) {
                edgeIdsToBeRemoved.push(e.id);
              }
            });

            updatedNodes = updatedNodes.map((node) => {
              const matchedNode = updatedSuccessors.find(
                (n) => n.id === node.id
              );

              if (!_isNil(matchedNode)) {
                return matchedNode;
              }

              return node;
            });

            updatedEdges = updatedEdges.map((e) => {
              const tEdge = parentEdges.find((edg) => edg.id === e.id);

              if (!_isNil(tEdge)) {
                if (!_isNil(childNodes[0])) {
                  const { childNodes: grandChildNodes } = getChildNodesAndEdges(
                    childNodes[0].id,
                    currentEdges,
                    currentNodes
                  );

                  tEdge.target =
                    grandChildNodes[0] != null
                      ? grandChildNodes[0].id
                      : tEdge.target;
                }

                return tEdge;
              }

              return e;
            });
          } else if (
            params.type === 'codeNode' ||
            params.type === 'delayNode' ||
            params.type === 'setVarNode' ||
            params.type === 'workflowNode' ||
            params.type === 'responseNode'
          ) {
            const parentEdges = getParentEdges(params.id, currentEdges);

            const { childNodes, childEdges } = getChildNodesAndEdges(
              params.id,
              currentEdges,
              currentNodes
            );

            const updatedSuccessors: WorkflowNodeType[] = [];

            childNodes.forEach((n) => {
              if (!_isNil(n)) {
                nodeIdsToBeRemoved.push(n.id);

                const { childEdges } = getChildNodesAndEdges(
                  n.id,
                  currentEdges,
                  currentNodes
                );

                const successorsNodes = getOrderedSuccessorNodesRecursiveDFS(
                  currentNodes,
                  currentEdges,
                  n.id
                );
                const cloneOfSuccessors: WorkflowNodeType[] = JSON.parse(
                  JSON.stringify(successorsNodes)
                );

                let distanceToBeDeletedWith = 165;

                cloneOfSuccessors.forEach(
                  (node: WorkflowNodeType, i: number) => {
                    const parentNodes = getParentNodes(
                      currentNodes,
                      currentEdges,
                      node.id
                    );

                    const sortedParentNodes =
                      sortNodesByPositionYDesc(parentNodes);

                    const parentsNotCurrentParent = sortedParentNodes.filter(
                      (n) => n.id !== cloneOfSuccessors[i - 1]?.id
                    );

                    // TODO: TO BE MODIFIED
                    if (
                      _isNil(currentNode?.data?.rootId) ||
                      currentNode?.data?.rootId === ''
                    ) {
                      updatedSuccessors.push({
                        ...node,
                        position: {
                          ...node.position,
                          y: node.position.y - distanceToBeDeletedWith,
                        },
                      });
                    } else if (
                      !_isNil(currentNode?.data.rootId) &&
                      currentNode?.data.rootId === node.data?.rootId &&
                      node.data?.isMergeNode !== true
                    ) {
                      updatedSuccessors.push({
                        ...node,
                        position: {
                          ...node.position,
                          y: node.position.y - distanceToBeDeletedWith,
                        },
                      });
                    } else if (
                      !_isNil(currentNode?.data.rootId) &&
                      currentNode?.data.rootId !== node.data?.rootId
                    ) {
                      updatedSuccessors.push({
                        ...node,
                        position: {
                          ...node.position,
                          y: node.position.y - distanceToBeDeletedWith,
                        },
                      });
                    } else if (
                      !_isNil(currentNode?.data.rootId) &&
                      currentNode?.data.rootId === node.data?.rootId &&
                      node.data?.isMergeNode === true
                    ) {
                      const oldPos = cloneOfSuccessors[i - 1]?.position.y ?? 0;

                      let y = 0;

                      if (i === 0) {
                        y =
                          (n.position.y ?? 0) -
                          (parentsNotCurrentParent.filter(
                            (nd) => nd.id !== n.id
                          )[0]?.position?.y ?? 0);
                      } else {
                        y =
                          (cloneOfSuccessors[i - 1]?.position?.y ?? 0) -
                          distanceToBeDeletedWith -
                          (parentsNotCurrentParent[0]?.position?.y ?? 0);
                      }

                      if (
                        !_isNil(cloneOfSuccessors[i - 1]) &&
                        cloneOfSuccessors[i - 1].id ===
                          parentsNotCurrentParent[0]?.id
                      ) {
                        updatedSuccessors.push({
                          ...node,
                          position: {
                            ...node.position,
                            y: node.position.y - distanceToBeDeletedWith,
                          },
                        });
                      } else if (y > 0) {
                        updatedSuccessors.push({
                          ...node,
                          position: {
                            ...node.position,
                            y: node.position.y - distanceToBeDeletedWith,
                          },
                        });
                      } else if (
                        y < 0 &&
                        oldPos > (parentsNotCurrentParent[0]?.position?.y ?? 0)
                      ) {
                        distanceToBeDeletedWith =
                          oldPos -
                          (parentsNotCurrentParent[0]?.position?.y ?? 0);

                        updatedSuccessors.push({
                          ...node,
                          position: {
                            ...node.position,
                            y: node.position.y - distanceToBeDeletedWith,
                          },
                        });
                      } else {
                        distanceToBeDeletedWith = 0;

                        updatedSuccessors.push({
                          ...node,
                          position: {
                            ...node.position,
                            y: node.position.y - distanceToBeDeletedWith,
                          },
                        });
                      }
                    }
                  }
                );

                childEdges.forEach((e) => {
                  if (!_isNil(e)) {
                    edgeIdsToBeRemoved.push(e.id);
                  }
                });
              }
            });

            childEdges.forEach((e) => {
              if (!_isNil(e)) {
                edgeIdsToBeRemoved.push(e.id);
              }
            });

            updatedNodes = updatedNodes.map((node) => {
              const matchedNode = updatedSuccessors.find(
                (n) => n.id === node.id
              );

              if (!_isNil(matchedNode)) {
                return matchedNode;
              }

              return node;
            });

            updatedEdges = updatedEdges.map((e) => {
              const tEdge = parentEdges.find((edg) => edg.id === e.id);

              if (!_isNil(tEdge)) {
                if (!_isNil(childNodes[0])) {
                  const { childNodes: grandChildNodes } = getChildNodesAndEdges(
                    childNodes[0].id,
                    currentEdges,
                    currentNodes
                  );

                  tEdge.target =
                    grandChildNodes[0] != null
                      ? grandChildNodes[0].id
                      : tEdge.target;
                }

                return tEdge;
              }

              return e;
            });
          } else if (
            ['srNode', 'switchNode'].includes(params.type ?? '') &&
            !_isNil(currentNode)
          ) {
            const parentEdges = getParentEdges(params.id, currentEdges);

            const mergeNode = getMergeNodeCorrespondingToSrId(
              currentNodes,
              currentNode.id
            );

            const itemsToBeDeleted = findNodesAndEdgesBetween(
              currentNode.id,
              nodes,
              edges,
              mergeNode?.id ?? ''
            );

            const connectedEdges = currentEdges
              .filter((e) => e.source === currentNode.id)
              .map((x) => x.id);

            nodeIdsToBeRemoved = [
              ...nodeIdsToBeRemoved,
              ...itemsToBeDeleted.nodes.map((n) => n.id),
              mergeNode?.id ?? '',
            ];

            edgeIdsToBeRemoved = [
              ...connectedEdges,
              ...itemsToBeDeleted.edges.map((n) => n.id),
            ];

            const nextNodeToMergeNode = getNextNode(
              currentNodes,
              currentEdges,
              mergeNode?.id ?? ''
            );

            const successorNodes = getOrderedSuccessorNodesRecursiveDFS(
              currentNodes,
              currentEdges,
              mergeNode?.id ?? ''
            );

            const updatedSuccessors: WorkflowNodeType[] = [];

            if (successorNodes.length > 0 && !_isNil(mergeNode)) {
              const cloneOfSuccessors: WorkflowNodeType[] = JSON.parse(
                JSON.stringify(successorNodes)
              );

              let distanceToBeDeletedWith =
                mergeNode.position.y - currentNode.position.y > 315
                  ? mergeNode.position.y - currentNode.position.y
                  : 315;

              cloneOfSuccessors.forEach((node: WorkflowNodeType, i: number) => {
                const parentNodes = getParentNodes(
                  currentNodes,
                  currentEdges,
                  node.id
                );

                const sortedParentNodes = sortNodesByPositionYDesc(parentNodes);

                const parentsNotCurrentParent = sortedParentNodes.filter(
                  (n) => n.id !== cloneOfSuccessors[i - 1]?.id
                );

                // TODO: TO BE MODIFIED
                if (
                  _isNil(currentNode?.data?.rootId) ||
                  currentNode.data.rootId === ''
                ) {
                  updatedSuccessors.push({
                    ...node,
                    position: {
                      ...node.position,
                      y: node.position.y - distanceToBeDeletedWith,
                    },
                  });
                } else if (
                  !_isNil(currentNode?.data.rootId) &&
                  currentNode?.data.rootId === node.data?.rootId &&
                  node.data?.isMergeNode !== true
                ) {
                  updatedSuccessors.push({
                    ...node,
                    position: {
                      ...node.position,
                      y: node.position.y - distanceToBeDeletedWith,
                    },
                  });
                } else if (
                  !_isNil(currentNode?.data.rootId) &&
                  currentNode?.data.rootId !== node.data?.rootId
                ) {
                  updatedSuccessors.push({
                    ...node,
                    position: {
                      ...node.position,
                      y: node.position.y - distanceToBeDeletedWith,
                    },
                  });
                } else if (
                  !_isNil(currentNode?.data.rootId) &&
                  currentNode?.data.rootId === node.data?.rootId &&
                  node.data?.isMergeNode === true
                ) {
                  const oldPos = cloneOfSuccessors[i - 1]?.position.y ?? 0;

                  let y = 0;

                  if (i === 0) {
                    y =
                      (mergeNode?.position.y ?? 0) -
                      (parentsNotCurrentParent.filter(
                        (nd) => nd.id !== mergeNode?.id
                      )[0]?.position?.y ?? 0);
                  } else {
                    y =
                      (cloneOfSuccessors[i - 1]?.position?.y ?? 0) -
                      distanceToBeDeletedWith -
                      (parentsNotCurrentParent[0]?.position?.y ?? 0);
                  }

                  if (
                    !_isNil(cloneOfSuccessors[i - 1]) &&
                    cloneOfSuccessors[i - 1].id ===
                      parentsNotCurrentParent[0]?.id
                  ) {
                    updatedSuccessors.push({
                      ...node,
                      position: {
                        ...node.position,
                        y: node.position.y - distanceToBeDeletedWith,
                      },
                    });
                  } else if (y > 0) {
                    if (node.data.isMergeNode === true && i === 0) {
                      distanceToBeDeletedWith = y;
                    }

                    updatedSuccessors.push({
                      ...node,
                      position: {
                        ...node.position,
                        y: node.position.y - distanceToBeDeletedWith,
                      },
                    });
                  } else if (
                    y < 0 &&
                    oldPos > (parentsNotCurrentParent[0]?.position?.y ?? 0)
                  ) {
                    distanceToBeDeletedWith =
                      oldPos - (parentsNotCurrentParent[0]?.position?.y ?? 0);

                    updatedSuccessors.push({
                      ...node,
                      position: {
                        ...node.position,
                        y: node.position.y - distanceToBeDeletedWith,
                      },
                    });
                  } else {
                    distanceToBeDeletedWith = 0;

                    updatedSuccessors.push({
                      ...node,
                      position: {
                        ...node.position,
                        y: node.position.y - distanceToBeDeletedWith,
                      },
                    });
                  }
                }
              });
            }

            updatedEdges = updatedEdges.map((edg) => {
              if (edg.id === parentEdges[0]?.id) {
                edg.target = nextNodeToMergeNode?.id ?? '';
              }

              return edg;
            });

            updatedNodes = updatedNodes.map((nod) => {
              const nodeInSucc = updatedSuccessors.find(
                (successor) => successor.id === nod.id
              );

              if (!_isNil(nodeInSucc)) {
                return nodeInSucc;
              }

              return nod;
            });
          }

          updatedEdges = updatedEdges.filter(
            (edg) => !edgeIdsToBeRemoved.includes(edg.id)
          );

          updatedEdges = appendTypeToEdges(updatedEdges);

          updatedNodes = updatedNodes.filter(
            (node) => !nodeIdsToBeRemoved.includes(node.id)
          );

          setWorkflowEdges(removeNakedEdges(updatedEdges, updatedNodes));

          return removeNakedEdges(updatedEdges, updatedNodes);
        });

        sendEventToGTM({
          event: 'workflow',
          source: 'listing',
          element: currentNode?.type,
          action: 'delete_click',
          type: params.type,
        });

        setWorkflowNodes(updateNodesWithMethods(updatedNodes));

        return updateNodesWithMethods(updatedNodes);
      });
    },
    [nodes, edges]
  );

  const handleVersionMapping = async ({
    entityId,
    type,
    name,
    subType,
    nodeId,
  }: HandleVersionMappingParams) => {
    const defaultVersion: string = 'draft';

    // Please do not remove below code
    // As of now draft version is selected on adding new node

    // const payload = {
    //   id: entityId,
    //   entityType: getEntityTypeForApi(type),
    //   page: 1,
    //   perPage: 10,
    //   live: true,
    //   filters: {
    //     in: {
    //       status: ['published'],
    //     },
    //   },
    //   sort: {
    //     updatedAt: -1,
    //   },
    // };

    // try {
    //   const response =
    //     type === 'rules'
    //       ? await getRuleVersionListQuery({
    //           variables: payload,
    //           fetchPolicy: 'no-cache',
    //         })
    //       : await getWorkflowVersionListQuery({
    //           variables: payload,
    //           fetchPolicy: 'no-cache',
    //         });

    //   const methodName = getMethodNameForApi(type);

    //   if (!_isNil(response) && !_isNil(response.data) && !_isNil(methodName)) {
    //     const data = response.data[methodName].data;

    //     const defaultVersion: string = 'draft';

    // data?.forEach((currVersionInfo: Record<string, any>) => {
    //   if (
    //     currVersionInfo.status === 'published' &&
    //     (currVersionInfo.isLive as boolean)
    //   ) {
    //     defaultVersion = currVersionInfo.version;
    //   }
    // });

    //     setVersionMappingInfo([
    //       ...(versionMappingInfo ?? []),
    //       {
    //         entityId,
    //         type: getEntityTypeForApi(type),
    //         version: defaultVersion ?? '',
    //         nodeId,
    //       },
    //     ]);
    //   }
    // } catch (err) {
    //   if (err instanceof ApolloError) {
    //     showGraphQlErrorToast(err);
    //   }
    // }

    setVersionMappingInfo([
      ...(versionMappingInfo ?? []),
      {
        entityId,
        type: getEntityTypeForApi(type),
        version: defaultVersion ?? '',
        nodeId,
      },
    ]);
  };

  const deleteVersionMappingInfoNode = useCallback(
    (nodeId: string) => {
      setVersionMappingInfo(
        (prev) => prev?.filter((currInfo) => currInfo.nodeId !== nodeId) ?? []
      );
    },
    [versionMappingInfo]
  );

  const onNodeDragStop = (event: any, node: any) => {
    // Check if the node is draggable
    // eslint-disable-next-line
    if (!node.data.draggable) {
      // If not draggable, prevent the drag operation
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onConnect = useCallback((params: any) => setEdges((eds) => eds), []);

  const updateOnNameChange = useCallback(
    ({ id, name, newName }: UpdateOnNameChangeParams) => {
      setNodes((currentNodes) => {
        const updatedNodes = [...currentNodes];
        const currentNode = currentNodes.find((node) => node.id === id);

        updatedNodes.forEach((node, index) => {
          if (!_isNil(node.data.input) && !_isEmpty(node.data.input)) {
            Object.keys(node.data.input).forEach((key) => {
              if (node.data.input[key].source === name) {
                updatedNodes[index].data.input[key].source = newName;
              }

              if (typeof node.data.input[key].value === 'string') {
                node.data.input[key].value = node.data.input[
                  key
                ].value.replaceAll(`{{.${name}.`, `{{.${newName}.`);
              }
            });
          }
        });

        const nodesWithMethods = updateNodesWithMethods(updatedNodes);

        setWorkflowNodes(nodesWithMethods);

        sendEventToGTM({
          event: 'workflow',
          source: 'listing',
          element: currentNode?.type,
          action: 'node_updated',
          type: currentNode?.data.nodeType,
        });

        return nodesWithMethods;
      });
    },
    [nodes, edges]
  );

  const updateNodesWithMethods = (localNodes: WorkflowNodeType[]) => {
    const newNodes: WorkflowNodeType[] = [];

    localNodes.forEach((n) => {
      newNodes.push({
        ...n,
        data: {
          ...n.data,
          ...functionsForNode,
        },
      });
    });

    return newNodes;
  };

  const screenWidth = window.innerWidth - 84;
  const centerPosition = { x: screenWidth / 2 };

  useEffect(() => {
    if (!_isNil(type) && !_isEmpty(type) && type === 'create') {
      const firstNodes = [
        {
          id: '1',
          type: 'addNode',
          data: {
            ...functionsForNode,
            nodeType: 'addNodeLg',
            settings: {
              isEnabled: true,
              authType: 'none',
            },
          },
          position: { x: centerPosition.x, y: 0 },
          sourcePosition: Position.Bottom,
          draggable: false,
        },
      ];

      setWorkflowEdges([]);
      setEdges([]);

      setWorkflowNodes(firstNodes);
      setNodes(firstNodes);
    } else if (
      !_isNil(type) &&
      !_isEmpty(type) &&
      ['view', 'edit', 'clone'].includes(type)
    ) {
      if (!_isNil(oldNodes) && !_isEmpty(oldNodes)) {
        setWorkflowNodes(updateNodesWithMethods(oldNodes));
        setNodes(updateNodesWithMethods(oldNodes));
      }

      if (!_isNil(oldEdges) && !_isEmpty(oldEdges)) {
        setWorkflowEdges(oldEdges);
        setEdges(oldEdges);
      }
    }
  }, [oldNodes, oldEdges, type]);

  useEffect(() => {
    setWorkflowNodeSaving(false);
    setRuleReadOnly(false);
  }, []);

  useLayerCloseOnPath({
    entityName: location.pathname.split('/')[1] as unknown as EntityType,
    onRouteChange: () => {
      setWorkflowEdges([]);
      setWorkflowNodes([]);
      setOldWorkflowNodes([]);
      closeAllLayers();
      setWorkflowId(null);
      setWorkflowStatus('draft');
      setWorkflowStaticUrl('');
    },
  });

  const functionsForNode = {
    addNode,
    deleteNode,
    onWorkflowNodeChange,
    updateOnNameChange,
    deleteVersionMappingInfoNode,
    handleVersionMapping,
  };

  return {
    closeWorkflowSheet,
    nodes,
    edges,
    setNodes,
    setEdges,
    onNodesChange,
    onEdgesChange,
    reactFlowInstance,
    setReactFlowInstance,
    onConnect,
    onNodeDragStop,
    addNode,
    onWorkflowNodeChange,
    deleteNode,
    deleteVersionMappingInfoNode,
    updateNodesWithMethods,
  };
}
