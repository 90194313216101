import { ObservableQuery } from '@apollo/client';
import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { atom, useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  Attributes,
  Dataset,
  NectedSuggestionModel,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  toasts,
  useLayer,
} from 'ui';

import {
  checksumWarningAtom,
  usedConnectorMappingAtom,
} from '../../../../atom';
import { publishedConnectorsAtom } from '../../../../components/CreateAction/CreateAction';
import { permissionObj } from '../../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../../components/PermissionComponent/hooks/useCheckPermissions';
import { customAttributesAtom } from '../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { getUserState } from '../../../../hooks/getUserState';
import { useHandleRequests } from '../../../../hooks/useHandleRequests';
import { useUpdateMappedConnectorsData } from '../../../../hooks/useUpdateMappedConnectorsData';
import type {
  DependencyUsingMapType,
  UsedConnectorMappingInEntityType,
} from '../../../../types';
import {
  checksumMessage,
  createResultDataset,
  detectType,
  executeJSParallelPostRequests,
  getDataSetSuggestionsObj,
  getUpdatedUsedConnectorsMapping,
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
  isOnboardingCompleted,
  prepareCodeStringForExecution,
} from '../../../../utils/common';
import {
  ENTITY_ID,
  actionsAdded,
  addConditionAndResult,
  attachedDatabaseRule,
  useJSCode,
} from '../../../../utils/constant';
import { handleKeyDown } from '../../../../utils/form';
import { updateWidgetState } from '../../../Home/components/sub-components/UpdateWidgetState';
import type { TableResponseModel } from '../../hooks/graphql/useGetTableData';
import { useSaveDecisionTable } from '../../hooks/graphql/useSaveDecisionTable';
import { useUpdateConditionsAndActions } from '../../hooks/graphql/useUpdateConditionsAndAction';
import { useUpdateCustomInput } from '../../hooks/graphql/useUpdateCustomInput';
import { useUpdateDecisionTable } from '../../hooks/graphql/useUpdateDecisionTable';
import { useUpdateNameDesc } from '../../hooks/graphql/useUpdateNameDesc';
import { useUpdatePolicy } from '../../hooks/graphql/useUpdatePolicy';
import { useUpdateTriggers } from '../../hooks/graphql/useUpdateTriggers';
import { useEditDecisionTable } from '../../hooks/useEditDecisionTable';
import { useInitializeDecisionTable } from '../../hooks/useInitializeDecisionTable';
import {
  createRuleSheetAtom,
  dataSetFieldsByIdAtom,
  isLiveNodeSheetClosedAtom,
  isRulePublishOnlyAtom,
  isRuleReadOnlyAtom,
  isRuleTestOnlyAtom,
  selectedDataSetAtom,
} from '../../index';
import { ExecuteResult, SaveType } from '../../models';
import {
  getRequiredKey,
  isRuleNameValid,
  updateDataSetOnChange,
  validateAction,
  validateCustomAttributesBeforeTest,
} from '../../utils/common';
import {
  getAdditionalDataElementsWithoutExecutedValue,
  getDecisionTableOutputsWithoutExecutedValue,
  getDtOutputRows,
  getSuggestionsOnRuleLoad,
  handleTestBeforeSubmit,
  transformDecisionTableData,
  validateDecisionTableData,
} from '../../utils/decisionTable';
import {
  CreateRuleSheet,
  dataSetParamsAtom,
  enableCronTestButtonAtom,
  firstCustomAttributeAtom,
  ruleEnvironmentAtom,
  ruleSavingErrorAtom,
} from '../CreateRuleSheet/CreateRuleSheet';
import { ApiTriggerAndCronInfo } from '../RuleComponents/ApiTriggerAndCronInfo/ApiTriggerAndCronInfo';
import { RuleSheetFooter } from '../RuleComponents/RuleSheetFooter';
import { RuleSheetHeader } from '../RuleComponents/RuleSheetHeader';
import { RuleLoader } from '../RuleLoader/RuleLoader';
import { tokensAtom } from '../SimpleRule';
import { ActionThenModel, ResultAddDataModel } from '../SimpleRule/models';
import { authTypes } from '../Triggers/AuthenticationDropDown';
import {
  approvalInfoRuleAtom,
  datasetDetailsInRuleAtom,
  errorInRuleAtom,
  hasConnectorErrorInCustomAttrSheetAtom,
  isRuleLiveAtom,
  versionInfoRuleAtom,
  versionMappingInfoAtom,
} from '../atom/atom';
import { CreateRuleContainer } from './DecisionTable.styled';
import { DecisionTableEditor } from './DecisionTableEditor';
import { TestNodeSheet } from './TestNodeSheet/TestNodeSheet';
import { decisionTableInitialNodes } from './fixtures/sample';
import type {
  AdditionalActionFormData,
  DecisionTableFormStructure,
  DecisionTableNodesModel,
  DecisionTableRow,
} from './models';

export const decisionTableNodesAtom = atom<
  Record<string, DecisionTableNodesModel>
>(structuredClone(decisionTableInitialNodes));

export const decisionTableOutputAtom = atom<
  Record<string, boolean | string | number | null>
>({});

export const decisionTableNodeIdAtom = atom<string | undefined>(undefined);

export const decisionTableErrorConfig = atom<Record<number, number[]>>({});

export type DecisionTableItemsModel = {
  rowIndex: number;
  rowKey: string;
  resIndex: number;
  resKey: string;
};

export type DecisionTableProps = {
  ruleName?: string;

  // This variable RuleId comes from the top level in case the rule has already
  // been saved and comes in case of rule edit and clone
  ruleId?: string;
  isClone?: boolean;
  refetch?: ObservableQuery<TableResponseModel>['refetch'];
  isLive?: boolean;
  commitId?: string;
};

export function DecisionTable({
  ruleId,
  ruleName = 'Untitled',
  refetch,
  isClone = false,
  isLive = false,
  commitId,
}: DecisionTableProps) {
  const [simpleRuleEnvironment] = useAtom(ruleEnvironmentAtom);
  const [nodes] = useAtom(decisionTableNodesAtom);
  const [customAttributes] = useAtom(customAttributesAtom);
  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);
  // This Id is local variable and default value of this Id is 'Undefined'
  const [id, setId] = useAtom(decisionTableNodeIdAtom);
  const [scrollStarted, setScrollStarted] = useState(false);

  const [isRuleReadOnly, setIsRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [isLiveNodeSheetClosed, setIsLiveNodeSheetClosed] = useAtom(
    isLiveNodeSheetClosedAtom
  );
  const [dataset] = useAtom(dataSetParamsAtom);
  const [, setRuleSavingError] = useAtom(ruleSavingErrorAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);
  const [tokens] = useAtom(tokensAtom);
  const [dataSetFieldById] = useAtom(dataSetFieldsByIdAtom);
  const [publishedConnectors] = useAtom(publishedConnectorsAtom);
  const [dataSetVariables] = useAtom(dataSetParamsAtom);
  const [ruleType] = useAtom(createRuleSheetAtom);
  const [, setEnableCronTest] = useAtom(enableCronTestButtonAtom);
  const [, setErrorConfigData] = useAtom(decisionTableErrorConfig);
  const [firstCustomAttribute] = useAtom(firstCustomAttributeAtom);

  const [, setIsRuleTestOnly] = useAtom(isRuleTestOnlyAtom);
  const [, setIsRulePublishOnly] = useAtom(isRulePublishOnlyAtom);

  const [, setIsRuleLive] = useAtom(isRuleLiveAtom);
  const [, setVersionInfoRule] = useAtom(versionInfoRuleAtom);
  const [, setApprovalInfoRule] = useAtom(approvalInfoRuleAtom);

  const [versionMappingInfo, setVersionMappingInfo] = useAtom(
    versionMappingInfoAtom
  );
  const [, setErrorInRule] = useAtom(errorInRuleAtom);

  const [datasetDetailsInRule, setDatasetDetailsInRule] = useAtom(
    datasetDetailsInRuleAtom
  );

  const [, setUsedConnectorMapping] = useAtom(usedConnectorMappingAtom);
  const [, setHasConnectorError] = useAtom(
    hasConnectorErrorInCustomAttrSheetAtom
  );

  const [dataSetResults, setDataSetResults] = useState<ResultAddDataModel[]>(
    []
  );

  const [enableAutoSave, setEnableAutoSave] = useState(false);
  const [isTesting, setIsTesting] = useState(false);

  const [saveDecisionTable, { loading: isRuleCreating }] =
    useSaveDecisionTable();
  const [updateDecisionTable, { loading: isRuleUpdating }] =
    useUpdateDecisionTable();
  const [updateRuleName, { loading: isRuleUpdatingName }] = useUpdateNameDesc();
  const [updateCustomInput, { loading: isRuleUpdatingCI }] =
    useUpdateCustomInput();
  const [updateTriggers, { loading: isRuleUpdatingTriggers }] =
    useUpdateTriggers();
  const [
    updateConditionsAndActions,
    { loading: isRuleUpdatingConditionsAndActions },
  ] = useUpdateConditionsAndActions();

  const { fetchMappedConnectorsData } = useUpdateMappedConnectorsData(false);

  const [updatePolicy, { loading: isRuleUpdatingPolicy }] = useUpdatePolicy();

  const { openWithProps: openLiveNodeSheet } = useLayer(
    <CreateRuleSheet ruleId={ruleId} isLive={true} from="viewLive" />
  );

  const isUpdating =
    isRuleUpdating ||
    isRuleUpdatingName ||
    isRuleUpdatingCI ||
    isRuleUpdatingTriggers ||
    isRuleUpdatingPolicy ||
    isRuleUpdatingConditionsAndActions;

  const isMutating = isRuleCreating || isUpdating;

  const { control, setValue, handleSubmit, setError, clearErrors } =
    useForm<any>({
      defaultValues: {
        ruleName,
        ruleDescription: '',
        rulePolicy: null,
        rows: [],
        properties: [],
        results: [],
        thenActionParams: [],
        additionalData: [],
        stagingConfig: {
          staticUrl: '',
          order: 0,
          startDate: null,
          endDate: null,
          auditIO: false,
          api: '',
          isEnabled: true,
          authType: authTypes.find((authType) => authType.label === 'None'),
          isApiEnabled: true,
        },
        productionConfig: {
          staticUrl: '',
          order: 0,
          startDate: null,
          endDate: null,
          auditIO: false,
          api: '',
          isEnabled: true,
          authType: authTypes.find((authType) => authType.label === 'None'),
          isApiEnabled: true,
          schedule: null,
        },
        createdAt: null,
        publishedAt: null,
      },
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    });

  const { openWithProps: openTestNodeSheet } = useLayer(
    <TestNodeSheet ruleName={ruleName} setValue={setValue} />
  );

  const decisionTableRows: Array<Record<string, DecisionTableRow>> = useWatch({
    control,
    name: 'rows',
  });

  const thenActionParams = useWatch({
    control,
    name: 'thenActionParams',
  });

  const decisionTableResults = useWatch({
    control,
    name: 'results',
  });

  const decisionTableRuleName = useWatch({
    control,
    name: 'ruleName',
  });

  const decisionTableRuleDescription = useWatch({
    control,
    name: 'ruleDescription',
  });

  const rulePolicy = useWatch({
    control,
    name: 'rulePolicy',
  });

  const stagingConfiguration = useWatch({
    control,
    name: 'stagingConfig',
  });

  const productionConfiguration = useWatch({
    control,
    name: 'productionConfig',
  });

  const ruleAdditionalData = useWatch({
    name: 'additionalData',
    control,
  });

  const statusValue = useWatch({
    control,
    name: 'status',
  });

  const { isHide: isEditDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.rules],
    entityStatus: statusValue,
  });

  const { isHide: testDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.test],
    entityList: [ENTITY_ID.rules],
    entityStatus: statusValue,
  });
  const { isHide: publishDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.publish],
    entityList: [ENTITY_ID.rules],
    entityStatus: statusValue,
  });

  const {
    properties,
    nodeList,
    outputData,
    result,
    decisionTableRow,
    decisionTableName,
    decesionTableDescription,
    ruleLoading,
    stagingConfig,
    productionConfig,
    decisionTablePolicy,
    customDataParams,
    discardRuleById,
    ruleVersion,
    selectedDataSets,
    thenActionResponse,
    additionalData,
    isDemo,
    loadingData,
    currentRuleData,
    handleGetRuleAfterStateTransition,
  } = useEditDecisionTable({
    isClone,
    isLive,
    ruleId: ruleId ?? id,
    ruleIdExist: !_isNil(ruleId) && !_isEmpty(ruleId),
    isLiveNodeSheetClosed,
    commitId,
  });

  useInitializeDecisionTable({
    customDataParams,
    properties,
    setValue,
    nodeList,
    outputData,
    result,
    decisionTableRow,
    decisionTableName,
    decesionTableDescription,
    stagingConfig,
    productionConfig,
    decisionTablePolicy,
    selectedDataSets,
    thenActionResponse,
    additionalData,
    isDemo,
    currentRuleData,
  });

  useEffect(() => {
    const resultAddDataModels: ResultAddDataModel[] = [];

    decisionTableResults?.forEach((field: any) => {
      const fieldKey = getRequiredKey(field, ['id']);
      const currentResult = field[fieldKey];

      resultAddDataModels.push({
        keyName: currentResult.keyName,
        dataType: currentResult.dataType,
        value: '',
      });
    });

    if (ruleType === 'decisionTable') {
      setDataSetResults(resultAddDataModels);
    }
  }, [decisionTableResults, ruleType]);

  const handlePostUpdate = (
    payload: Record<string, any>,
    updatedRule?: Record<string, any> | null,
    type?: string
  ) => {
    handleSetCheckSumByEntityName('rule', updatedRule?.updateRule.checksum);

    setId(updatedRule?.updateRule.id);
    setRuleSavingError('');

    setValue('status', updatedRule?.updateRule.status ?? 'draft');
    setValue('publishedAt', updatedRule?.updateRule.publishedAt);

    setVersionInfoRule(updatedRule?.updateRule.versionInfo);
    setIsRuleLive(updatedRule?.updateRule.isLive ?? false);
    setApprovalInfoRule(updatedRule?.updateRule.approvalInfo);

    setVersionMappingInfo(
      updatedRule?.updateRule.dependencyMap?.map(
        (currMapping: DependencyUsingMapType) => ({
          entityId: currMapping.id,
          type: currMapping.type,
          version: currMapping.version,
          nodeId: currMapping.nodeId,
        })
      ) ?? []
    );

    if (type === 'customInp') {
      setDatasetDetailsInRule(updatedRule?.updateRule.datasetDetail);
    }

    // if (!isDemo) {
    //   updateOnboardingStatus(
    //     payload.action.then,
    //     payload.dataSetId,
    //     payload.conditions
    //   );
    // }
  };

  const validateUsedConnectors = async (
    currErrorInRule: Record<string, boolean>
  ) => {
    const currentUsedConnectors: UsedConnectorMappingInEntityType = {};

    const connectorPresentInActions =
      thenActionParams?.reduce(
        (connObj: Record<string, boolean>, currAction: Record<string, any>) => {
          currentUsedConnectors[currAction.connectorId] = {
            status: true,
            source: ['action'],
          };

          return {
            ...connObj,
            [currAction.connectorId]: true,
          };
        },
        {}
      ) ?? {};

    const mappedDatasetConnId = datasetDetailsInRule?.connector.id;

    if (!_isNil(mappedDatasetConnId)) {
      const originalSource =
        currentUsedConnectors?.[mappedDatasetConnId]?.source ?? [];

      const source = originalSource?.includes('dataset')
        ? originalSource
        : [...originalSource, 'dataset'];

      currentUsedConnectors[mappedDatasetConnId] = {
        status: true,
        source,
      };
    }

    const restAPIConnectorKeys: Record<string, boolean> = {};

    Object.keys(customDataParams).forEach((key) => {
      const currCustomInput = customDataParams[key];
      const id = currCustomInput.attribute;

      if (
        currCustomInput.dataType?.value === 'restAPI' &&
        !_isNil(id) &&
        !_isEmpty(id)
      ) {
        restAPIConnectorKeys[id] = true;

        const originalSource = currentUsedConnectors?.[id]?.source ?? [];

        const source = originalSource?.includes('restApi')
          ? originalSource
          : [...originalSource, 'restApi'];

        currentUsedConnectors[id] = {
          status: true,
          source,
        };
      }
    });

    const response = await fetchMappedConnectorsData(currentUsedConnectors);
    let error = false;
    let source = '';

    try {
      if (!_isNil(response)) {
        const updatedUsedConnectorMapping = getUpdatedUsedConnectorsMapping(
          response,
          currentUsedConnectors
        );

        setUsedConnectorMapping(updatedUsedConnectorMapping);

        const connectorList = response.getConnector.data;

        for (let i = 0; i < connectorList.length; i++) {
          const connector = connectorList[i];

          error =
            !(connector.staging.isTested as boolean) ||
            !(connector.staging.isPublish as boolean);

          if (connector.id in connectorPresentInActions) {
            currErrorInRule.action = error;
            source = 'action';
          }

          if (connector.id === mappedDatasetConnId) {
            setHasConnectorError((prev) => ({
              ...prev,
              dataset: error,
            }));

            source = _isEmpty(source)
              ? 'Custom Input (Datasource)'
              : `${source}, Custom Input (Datasource)`;
          }

          if (connector.id in restAPIConnectorKeys) {
            setHasConnectorError((prev) => ({
              ...prev,
              restAPI: error,
            }));

            source = _isEmpty(source)
              ? 'Custom Input (Rest API)'
              : source.includes('Custom Input')
              ? source.substring(0, source.length - 1).concat(', Rest API)')
              : `${source}, Custom Input (Rest API)`;
          }

          if (error) {
            toasts.error(
              `Seems integration used in the ${source} is not published with staging config, Publish your integration staging config before testing this rule.`,
              'rule-connector-staging-not-published'
            );

            break;
          }
        }
      }
    } catch (err) {}

    return error;
  };

  const onSubmit = async (
    data: DecisionTableFormStructure,
    test: boolean = false,
    notSave: boolean = false,
    type: SaveType = 'all'
  ) => {
    if (test) {
      setIsTesting(true);
    }

    const updatedFilteredDataSet: Record<string, Dataset> =
      updateDataSetOnChange(
        customAttributes,
        dataset,
        dataSetSelected,
        false,
        true
      );

    let isTestingValid = true;
    let isActionValid = true;
    const isNameValid = isRuleNameValid(data.ruleName, setError);

    const isDecisionTableDataValid = validateDecisionTableData(
      data,
      !notSave ? setError : undefined
    );

    const selectedDataSet = dataSetSelected[0];

    const updatedDataSet = () => {
      const newDs = createResultDataset({ ...updatedFilteredDataSet });

      const suggestionName = 'resultData';

      if (
        !_isNil(newDs.dataSet) &&
        !_isNil(newDs.dataSet.attributes) &&
        _isNil(newDs.dataSet.attributes[suggestionName])
      ) {
        const suggestionPayload: Attributes = {
          name: suggestionName,
          dataType: 'list',
          executedValue: [
            Object.keys(newDs.dataSet.attributes).reduce(
              (acc: Record<string, any>, key: string) => {
                acc[key] = newDs.dataSet.attributes[key].executedValue;

                return acc;
              },
              {}
            ),
          ],
        };
        newDs.dataSet.attributes = {
          ...newDs.dataSet.attributes,
          [suggestionName]: {
            ...suggestionPayload,
          },
        };
      }

      return {
        ...newDs,
      };
    };

    let currErrorInRule = {
      action: false,
      condition: false,
      outputData: false,
      additionalData: false,
    };

    if (test) {
      const { isTestValid, errorInRule: updatedErrorInRule } =
        await handleTestBeforeSubmit(
          data,
          nodes,
          updatedDataSet(),
          customAttributes,
          dataset,
          tokens,
          !notSave && test ? setError : undefined,
          !notSave && test ? setErrorConfigData : undefined
        );

      currErrorInRule = updatedErrorInRule;

      let isConnectorError = true;

      if (test) {
        isConnectorError = await validateUsedConnectors(currErrorInRule);
      }

      isTestingValid = isTestValid && !isConnectorError;
    }

    const customAttributeValidationErrors = validateCustomAttributesBeforeTest(
      customAttributes,
      dataSetFieldById,
      selectedDataSet
    );
    const finalRows = getDtOutputRows(
      data.results,
      data.rows,
      dataSetVariables
    );

    isActionValid = await validateAction(
      data.thenActionParams,
      dataSetVariables,
      publishedConnectors,
      dataSetResults,
      !notSave && test ? setError : undefined,
      'thenActionParams',
      data.additionalData,
      tokens,
      'DecisionTable',
      finalRows
    );

    if (!isActionValid) {
      currErrorInRule.action = true;
    }

    if (customAttributeValidationErrors.length > 0 && test) {
      toasts.error(customAttributeValidationErrors[0].message, 'error');
      isTestingValid = false;
    }

    if (isTestingValid && isDecisionTableDataValid && isActionValid) {
      setEnableCronTest(true);
    } else {
      setEnableCronTest(false);
    }

    if (!isNameValid) {
      removeRequest();
      setIsTesting(false);

      return;
    }

    setIsTesting(false);

    if (test && !notSave) {
      setErrorInRule(currErrorInRule);
    }

    if (!test && !notSave) {
      if (_isNil(id) && isDecisionTableDataValid) {
        const payload = {
          ...transformDecisionTableData(
            data,
            nodes,
            customAttributes,
            dataSetSelected,
            firstCustomAttribute
          ),
          dependencyMap: versionMappingInfo,
        };
        try {
          const { data: savedRule } = await saveDecisionTable({
            variables: {
              ...payload,
            },
          });

          handleSetCheckSumByEntityName('rule', savedRule.createRule.checksum);

          setId(savedRule.createRule.id);

          setValue(
            'productionConfig.staticUrl',
            savedRule.createRule.staticUrl
          );
          setValue('stagingConfig.staticUrl', savedRule.createRule.staticUrl);
          setRuleSavingError('');

          if (!isDemo) {
            updateOnboardingStatus(
              payload.action.then,
              payload.dataSetId,
              payload.conditions
            );
          }
        } catch (error: unknown) {
          if (error instanceof Error) {
            setRuleSavingError(error.message);

            if (error.message.includes(checksumMessage)) {
              setShowChecksumPopup({
                showPopup: true,
                metaData: { ruleId: id, ruleName, type: 'decisionTable' },
              });
            } else {
              toasts.error(error.message, 'error');
            }
          }
        }
      } else if (isDecisionTableDataValid) {
        const localPayload = transformDecisionTableData(
          data,
          nodes,
          customAttributes,
          dataSetSelected,
          firstCustomAttribute
        );

        if (type === 'all') {
          try {
            const checksum = handleGetCheckSumByEntityName('rule');
            const payload = {
              id: id ?? ruleId,
              checksum: checksum ?? '',
              ...localPayload,
              dependencyMap: versionMappingInfo,
            };
            const { data: updatedRule } = await updateDecisionTable({
              variables: {
                ...payload,
              },
            });

            handlePostUpdate(payload, updatedRule);
          } catch (error: unknown) {
            if (error instanceof Error) {
              setRuleSavingError(error.message);

              if (error.message.includes(checksumMessage)) {
                setShowChecksumPopup({
                  showPopup: true,
                  metaData: {
                    ruleId: id,
                    ruleName,
                    ruleType: 'decisionTable',
                  },
                });
              } else {
                toasts.error(error.message, 'error');
              }
            }
          }
        } else if (type === 'nameDesc') {
          try {
            const checksum = handleGetCheckSumByEntityName('rule');
            const payload = {
              id: id ?? ruleId,
              checksum: checksum ?? '',
              name: localPayload.name,
              description: localPayload.description,
              editMode: localPayload.editMode,
              dataSetId: dataSetSelected[0] ?? '',
              dependencyMap: versionMappingInfo,
            };

            const { data: updatedRule } = await updateRuleName({
              variables: {
                ...payload,
              },
            });

            handlePostUpdate(payload, updatedRule);
          } catch (error: unknown) {
            if (error instanceof Error) {
              setRuleSavingError(error.message);

              if (error.message.includes(checksumMessage)) {
                setShowChecksumPopup({
                  showPopup: true,
                  metaData: {
                    ruleId: id,
                    ruleName,
                    ruleType: 'decisionTable',
                  },
                });
              } else {
                toasts.error(error.message, 'error');
              }
            }
          }
        } else if (type === 'customInp') {
          try {
            const checksum = handleGetCheckSumByEntityName('rule');
            const payload = {
              id: id ?? ruleId,
              checksum: checksum ?? '',
              customInput: localPayload.customInput,
              firstCustomInput: localPayload.firstCustomInput,
              dataSetId: dataSetSelected[0] ?? '',
              editMode: localPayload.editMode,
              dependencyMap: versionMappingInfo,
            };

            const { data: updatedRule } = await updateCustomInput({
              variables: {
                ...payload,
              },
            });

            handlePostUpdate(payload, updatedRule, type);
          } catch (error: unknown) {
            if (error instanceof Error) {
              setRuleSavingError(error.message);

              if (error.message.includes(checksumMessage)) {
                setShowChecksumPopup({
                  showPopup: true,
                  metaData: {
                    ruleId: id,
                    ruleName,
                    ruleType: 'decisionTable',
                  },
                });
              } else {
                toasts.error(error.message, 'error');
              }
            }
          }
        } else if (type === 'triggers') {
          try {
            const checksum = handleGetCheckSumByEntityName('rule');
            const payload = {
              id: id ?? ruleId,
              checksum: checksum ?? '',
              staging: localPayload.staging,
              production: localPayload.production,
              editMode: localPayload.editMode,
              dataSetId: dataSetSelected[0] ?? '',
              dependencyMap: versionMappingInfo,
            };

            const { data: updatedRule } = await updateTriggers({
              variables: {
                ...payload,
              },
            });

            handlePostUpdate(payload, updatedRule);
          } catch (error: unknown) {
            if (error instanceof Error) {
              setRuleSavingError(error.message);

              if (error.message.includes(checksumMessage)) {
                setShowChecksumPopup({
                  showPopup: true,
                  metaData: {
                    ruleId: id,
                    ruleName,
                    ruleType: 'decisionTable',
                  },
                });
              } else {
                toasts.error(error.message, 'error');
              }
            }
          }
        } else if (type === 'conditions') {
          try {
            const checksum = handleGetCheckSumByEntityName('rule');
            const payload = {
              id: id ?? ruleId,
              checksum: checksum ?? '',
              conditions: localPayload.conditions,
              decisionTable: localPayload.decisionTable,
              action: localPayload.action,
              editMode: localPayload.editMode,
              dataSetId: dataSetSelected[0] ?? '',
              dependencyMap: versionMappingInfo,
            };

            const { data: updatedRule } = await updateConditionsAndActions({
              variables: {
                ...payload,
              },
            });

            handlePostUpdate(payload, updatedRule);
          } catch (error: unknown) {
            if (error instanceof Error) {
              setRuleSavingError(error.message);

              if (error.message.includes(checksumMessage)) {
                setShowChecksumPopup({
                  showPopup: true,
                  metaData: {
                    ruleId: id,
                    ruleName,
                    ruleType: 'decisionTable',
                  },
                });
              } else {
                toasts.error(error.message, 'error');
              }
            }
          }
        } else if (type === 'policy') {
          try {
            const checksum = handleGetCheckSumByEntityName('rule');
            const payload = {
              id: id ?? ruleId,
              checksum: checksum ?? '',
              editMode: localPayload.editMode,
              policy: localPayload.policy,
              dataSetId: dataSetSelected[0] ?? '',
              dependencyMap: versionMappingInfo,
            };

            const { data: updatedRule } = await updatePolicy({
              variables: {
                ...payload,
              },
            });

            handlePostUpdate(payload, updatedRule);
          } catch (error: unknown) {
            if (error instanceof Error) {
              setRuleSavingError(error.message);

              if (error.message.includes(checksumMessage)) {
                setShowChecksumPopup({
                  showPopup: true,
                  metaData: {
                    ruleId: id,
                    ruleName,
                    ruleType: 'decisionTable',
                  },
                });
              } else {
                toasts.error(error.message, 'error');
              }
            }
          }
        }
      }

      removeRequest();
    } else if (
      isTestingValid &&
      isDecisionTableDataValid &&
      isActionValid &&
      !notSave
    ) {
      openTestNodeSheet({ isDemo, ruleName: decisionTableRuleName });
    }
  };

  const getExecutedValue = async (
    values: Array<{
      id: string;
      code: string;
    }>,
    suggestions: NectedSuggestionModel[] = []
  ) => {
    const items: DecisionTableItemsModel[] = [];

    values.forEach((value) => {
      decisionTableRows.forEach((row, rowIndex: number) => {
        const rowKey = getRequiredKey(row, ['id']);

        row[rowKey].ruleResult.forEach((res, resIndex: number) => {
          const resKey = getRequiredKey(res, ['id']);

          if (value.id === resKey) {
            items.push({
              rowIndex,
              rowKey,
              resIndex,
              resKey,
            });
          }
        });
      });
    });

    try {
      const data = await executeJSParallelPostRequests(
        values.map((value) => ({
          payload: {
            snippet: value.code,
            language: 'JS',
          },
        })),
        suggestions
      );

      setTimeout(() => {
        (data as ExecuteResult[]).forEach((val, index) => {
          if (val.code === 'success') {
            setValue(
              // eslint-disable-next-line
              `rows.${items[index].rowIndex}.${items[index].rowKey}.ruleResult.${items[index].resIndex}.${items[index].resKey}.executedValue`,
              val.data.result
            );

            setValue(
              // eslint-disable-next-line
              `rows.${items[index].rowIndex}.${items[index].rowKey}.ruleResult.${items[index].resIndex}.${items[index].resKey}.returnType`,
              detectType(val.data.result ?? null)
            );
          }
        });
      }, 1000);
    } catch (error) {}
  };

  const getExecutedValuesForAddData = async (
    indexes: number[],
    aData: AdditionalActionFormData[],
    suggestions: NectedSuggestionModel[]
  ) => {
    const aDataWithoutExeValue = aData.filter((a, i) => {
      if (i in indexes) {
        return true;
      }

      return false;
    });

    try {
      const data = await executeJSParallelPostRequests(
        aDataWithoutExeValue.map((value) => ({
          payload: {
            snippet: value.value,
            language: 'JS',
          },
        })),
        suggestions
      );

      setTimeout(() => {
        (data as ExecuteResult[]).forEach((val, index) => {
          if (val.code === 'success') {
            setValue(
              // eslint-disable-next-line
              `additionalData.${indexes[index]}.executedValue`,
              val.data.result
            );

            setValue(
              // eslint-disable-next-line
              `additionalData.${indexes[index]}.returnType`,
              detectType(val.data.result ?? null)
            );
          }
        });
      }, 1000);
    } catch (error) {}
  };

  const updateOnboardingStatus = (
    action: ActionThenModel,
    dataSetSelected: string,
    conditions: Record<string, any>
  ) => {
    if (!_isEmpty(action.firstActionNode)) {
      if (!isOnboardingCompleted(actionsAdded)) {
        updateWidgetState(actionsAdded)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    }

    // check if action.outputData is not empty and if not then check if any keys contains name "aggData"
    if (
      !_isEmpty(action.outputData) &&
      Object.keys(action.outputData).some((key) => key.includes('aggData'))
    ) {
      if (!isOnboardingCompleted(useJSCode)) {
        updateWidgetState(useJSCode)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    }

    if (
      !_isEmpty(conditions.nodes) &&
      Object.keys(action.outputData)?.length > 0
    ) {
      if (!isOnboardingCompleted(addConditionAndResult)) {
        updateWidgetState(addConditionAndResult)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    }

    if (
      dataSetSelected.length > 0 &&
      dataSetSelected !== '' &&
      !_isNil(dataSetSelected) &&
      !isOnboardingCompleted(attachedDatabaseRule)
    ) {
      updateWidgetState(attachedDatabaseRule)
        .then(() => {
          void getUserState();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  };

  useEffect(() => {
    setId(isClone ? undefined : ruleId);

    return () => {
      if (typeof refetch === 'function') {
        void refetch();
      }

      if (!isLive) {
        setId(undefined);
      }
    };
  }, [ruleId, isLive]);

  const ruleIsLoading = ruleLoading || loadingData;

  const { appendRequest, removeRequest } = useHandleRequests({
    onResolve: async (resArgs: { type: string }) => {
      if (resArgs.type === 'policy') {
        await submitFormPolicy();
      } else if (resArgs.type === 'conditions') {
        await submitFormConditions();
      } else if (resArgs.type === 'triggers') {
        await submitFormTriggers();
      } else if (resArgs.type === 'nameDesc') {
        await submitFormNameDesc();
      } else if (resArgs.type === 'customInp') {
        await submitFormCI();
      } else {
        await submitForm();
      }
    },
    hasActivity: isMutating,
  });

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (!isLive && !isRuleReadOnly) {
      clearErrors();
    }

    setRuleSavingError('');

    if (
      !_isNil(nodes) &&
      !_isEmpty(nodes) &&
      !isLive &&
      (!isRuleReadOnly || isClone) &&
      !ruleIsLoading
    ) {
      submitTimeout = setTimeout(() => {
        setEnableAutoSave(true);

        if (!enableAutoSave) {
          const dataSetSuggestionsObj = getDataSetSuggestionsObj(
            updateDataSetOnChange(
              customAttributes,
              dataSetVariables,
              dataSetSelected
            )
          );

          const suggestionsOnLoad = getSuggestionsOnRuleLoad(
            dataSetSuggestionsObj,
            decisionTableRows,
            decisionTableResults,
            additionalData
          );

          const elements =
            getDecisionTableOutputsWithoutExecutedValue(decisionTableRows);

          const additionalDataElements =
            getAdditionalDataElementsWithoutExecutedValue(additionalData);

          void getExecutedValue(
            elements.map((element) => {
              const resultKey = getRequiredKey(element, ['id']);

              return {
                code: prepareCodeStringForExecution(
                  element[resultKey].value as string,
                  element[resultKey].dataType
                ),
                id: resultKey,
              };
            }),
            suggestionsOnLoad
          );

          void getExecutedValuesForAddData(
            additionalDataElements,
            additionalData,
            suggestionsOnLoad
          );

          void onSubmitAndTestCron();
        }

        setErrorConfigData({});

        if ((enableAutoSave || isClone) && _isNil(id)) {
          appendRequest({ type: 'all' });
        }
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    JSON.stringify(nodes),
    JSON.stringify(customAttributes),
    decisionTableRuleName,
    decisionTableRuleDescription,
    JSON.stringify(stagingConfiguration),
    JSON.stringify(productionConfiguration),
    JSON.stringify(decisionTableRows),
    JSON.stringify(rulePolicy),
    JSON.stringify(decisionTableResults),
    JSON.stringify(dataSetSelected),
    JSON.stringify(thenActionParams),
    JSON.stringify(ruleAdditionalData),
    JSON.stringify(versionMappingInfo),
    ruleIsLoading,
    isRuleReadOnly,
  ]);

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (enableAutoSave && !_isNil(id)) {
      submitTimeout = setTimeout(() => {
        // void submitFormNameDesc();
        appendRequest({ type: 'nameDesc' });
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [decisionTableRuleName, decisionTableRuleDescription]);

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (enableAutoSave && !_isNil(id)) {
      submitTimeout = setTimeout(() => {
        // void submitFormCI();
        appendRequest({ type: 'customInp' });
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    JSON.stringify(customAttributes),
    JSON.stringify(dataSetSelected),
    JSON.stringify(versionMappingInfo),
  ]);

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (enableAutoSave && !_isNil(id)) {
      submitTimeout = setTimeout(() => {
        // void submitFormTriggers();
        appendRequest({ type: 'triggers' });
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    JSON.stringify(stagingConfiguration),
    JSON.stringify(productionConfiguration),
  ]);

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (enableAutoSave && !_isNil(id)) {
      submitTimeout = setTimeout(() => {
        appendRequest({ type: 'policy' });
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [rulePolicy]);

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (enableAutoSave && !_isNil(id)) {
      submitTimeout = setTimeout(() => {
        // void submitFormConditions();
        appendRequest({ type: 'conditions' });
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    JSON.stringify(decisionTableRows),
    JSON.stringify(nodes),
    JSON.stringify(ruleAdditionalData),
    JSON.stringify(decisionTableResults),
    JSON.stringify(thenActionParams),
  ]);

  useEffect(() => {
    return () => setErrorConfigData({});
  }, []);

  useEffect(() => {
    setIsRuleReadOnly(isEditDisable);
  }, [isEditDisable]);

  useEffect(() => {
    setIsRuleTestOnly(!testDisable);
  }, [testDisable]);

  useEffect(() => {
    setIsRulePublishOnly(!publishDisable);
  }, [publishDisable]);

  const submitAndTestForm = handleSubmit(
    async (data) => await onSubmit(data, true)
  );

  const handleEditButton = handleSubmit(
    async (data) => await onSubmit({ ...data, editMode: true })
  );

  const submitForm = handleSubmit(async (data) => await onSubmit(data));

  const submitFormNameDesc = handleSubmit(
    async (data) => await onSubmit(data, false, false, 'nameDesc')
  );

  const submitFormCI = handleSubmit(
    async (data) => await onSubmit(data, false, false, 'customInp')
  );
  const submitFormTriggers = handleSubmit(
    async (data) => await onSubmit(data, false, false, 'triggers')
  );
  const submitFormConditions = handleSubmit(
    async (data) => await onSubmit(data, false, false, 'conditions')
  );
  const submitFormPolicy = handleSubmit(
    async (data) => await onSubmit(data, false, false, 'policy')
  );

  const onSubmitAndTestCron = handleSubmit(
    async (data) => await onSubmit(data, false, true)
  );

  const handleDiscardRule = async () => {
    const checksum = handleGetCheckSumByEntityName('rule') ?? '';

    if (!_isNil(id)) {
      try {
        await discardRuleById({ variables: { id, checksum } });
        toasts.success('Rule reverted to live version successfully', 'success');
      } catch (err) {}
    }
    setEnableAutoSave(false);
  };

  const handleFetchRule = () => {
    setEnableAutoSave(false);
    void handleGetRuleAfterStateTransition();
  };

  if (ruleIsLoading) {
    return <RuleLoader />;
  }

  const handleScroll = (event: any) => {
    const scrollValue = event.target.scrollTop;

    if (scrollValue > 0) {
      setScrollStarted(true);
    } else if (scrollValue === 0) {
      setScrollStarted(false);
    }
  };

  return (
    <form onKeyDown={handleKeyDown}>
      <CreateRuleContainer onScroll={handleScroll}>
        <PadBox padding={[10, 20]}>
          <Stack gutter={10}>
            <RuleSheetHeader
              control={control}
              title="decisionTable"
              id={id ?? ''}
              onLiveButtonClick={() => {
                setIsRuleReadOnly(true);
                openLiveNodeSheet({ ruleId: id, from: 'viewLive' });
                setEnableAutoSave(false);
                setIsLiveNodeSheetClosed(false);
              }}
              ruleVersion={ruleVersion}
              showLiveButton={!isLive}
              setValue={setValue}
              isReadOnly={isLive || isRuleReadOnly}
              handleFetchRule={handleFetchRule}
            />
          </Stack>
        </PadBox>

        <ApiTriggerAndCronInfo id={id ?? ''} control={control} />

        <Tabs>
          <TabList styleClassName="dtTabListStyle">
            <Tab>Editor</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {ruleIsLoading ? (
                <Inline justify="center">
                  <Spinner size="small" />
                </Inline>
              ) : (
                <DecisionTableEditor
                  control={control}
                  isReadOnly={isLive || isRuleReadOnly}
                  setValue={setValue}
                  ruleId={id}
                  scrollStarted={scrollStarted}
                  discardRule={handleDiscardRule}
                  isClone={isClone}
                  handleFetchRule={handleFetchRule}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>

        <RuleSheetFooter
          isLive={isLive}
          isRuleCreating={isRuleCreating}
          isRuleUpdating={isUpdating}
          ruleEnvironment={simpleRuleEnvironment}
          submitAndTestForm={submitAndTestForm}
          id={id}
          isClone={isClone}
          ruleName={decisionTableRuleName}
          status={statusValue}
          setValue={setValue}
          handleEditButton={handleEditButton}
          isTesting={isTesting}
        />
      </CreateRuleContainer>
    </form>
  );
}
