import { Inline, Stack } from '@bedrock-layout/primitives';
import { useEffect, useMemo } from 'react';
import { Control, UseFormSetValue, useForm, useWatch } from 'react-hook-form';
import {
  Button,
  DateTimePickerField,
  DropdownField,
  TextField,
  Typography,
  useLayer,
} from 'ui';

import { getValueFromObject } from '../../../../../components/Listing/utils';
import {
  getCronExpressionByUnit,
  validateCron,
} from '../../../../../utils/common';
import {
  CRON_UNITS,
  CronInitialData,
  defaultSchedulerValues,
} from '../../../../../utils/constant';
import { stopPropagate } from '../../../../../utils/form';
import { LocalCronRuleModel } from '../../../models';
import { formatCronInput, transformCronOutput } from '../../../utils/common';
import { RuleCronScheduleModel } from '../../SimpleRule/models';
import { SchedulerCASheet } from './SchedulerCASheet';
import { SchedulerComponents } from './SchedulerComponents';
import { SchedulerCron } from './SchedulerCron';
import {
  CronEnglishContainer,
  ScheduleFooter,
  SchedulerDateWrapper,
  SchedulerFormWrapper,
  TimeZoneContainer,
} from './SchedulerForm.styled';

type SchedulerFormProps = {
  onClose: () => void;
  index: number;
  isVisible: boolean;
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
};

export function SchedulerForm({
  onClose,
  index,
  control,
  setValue,
  isVisible,
}: SchedulerFormProps) {
  const oldValue: RuleCronScheduleModel | undefined = useWatch({
    name: `productionConfig.schedule.${index}`,
    control,
  });

  const {
    control: localControl,
    handleSubmit,
    watch,
    reset,
    setError,
  } = useForm<any>({
    mode: 'onSubmit',
    defaultValues: structuredClone(defaultSchedulerValues),
  });

  useEffect(() => {
    const updatedCronData = formatCronInput(oldValue);
    reset(updatedCronData);
  }, [oldValue, isVisible]);

  const { open: openCronSheet } = useLayer(
    <SchedulerCASheet
      cronIndex={index}
      parentControl={control}
      setValue={setValue}
    />
  );

  const onSubmit = async (data: LocalCronRuleModel) => {
    // eslint-disable-next-line
    const isValid = validateCron(data, setError);

    if (typeof setValue === 'function' && isValid) {
      setValue(
        `productionConfig.schedule.${index}`,
        transformCronOutput(data, oldValue ?? CronInitialData)
      );

      openCronSheet();
      onClose();
    }
  };

  const unit = watch('unit');

  const getNameByUnit = (unitValue: string) => {
    switch (unitValue) {
      case 'minute':
        return 'minutes';
      case 'hourly':
        return 'hours';
      case 'weekly':
        return 'weekdays';
      case 'monthly':
        return 'days';
      default:
        return '';
    }
  };

  const showMinuteHourMonthDay = useMemo(() => {
    return ['minute', 'monthly'].includes(unit.value);
  }, [unit]);

  const showWeekdays = unit.value === 'weekly';
  const showCron = unit.value === 'cron';

  const values = watch();

  const plan = JSON.parse(window.sessionStorage.getItem('userPlan') ?? '{}');

  return (
    <SchedulerFormWrapper
      as="form"
      onSubmit={stopPropagate(handleSubmit(onSubmit))}
    >
      <Stack gutter="1.6rem">
        <Inline align="center">
          <SchedulerDateWrapper gutter="0.8rem">
            <Typography fontWeight={700}>Start Date & Time</Typography>
            <DateTimePickerField
              name="startAt"
              control={localControl}
              minDate={new Date()}
              showCustomInput
            />
          </SchedulerDateWrapper>

          <SchedulerDateWrapper gutter="0.8rem">
            <Typography fontWeight={700}>End Date & Time</Typography>
            <DateTimePickerField
              name="endAt"
              control={localControl}
              minDate={new Date()}
              showCustomInput
            />
          </SchedulerDateWrapper>

          <TimeZoneContainer>{oldValue?.timezone}</TimeZoneContainer>
        </Inline>

        <Stack gutter="0.8rem">
          <Typography fontWeight={700}>Scheduler</Typography>

          <Inline gutter="1rem">
            <DropdownField
              name="unit"
              control={localControl}
              options={CRON_UNITS}
            />
            {showMinuteHourMonthDay && (
              <SchedulerComponents
                type={unit.value}
                control={localControl}
                name={getNameByUnit(unit.value)}
              />
            )}

            {unit.value === 'hourly' && (
              <TextField
                name="hours"
                control={localControl}
                type="number"
                size="small"
                heightFull
              />
            )}
          </Inline>

          <Stack>
            {showWeekdays && (
              <SchedulerComponents
                type={unit.value}
                control={localControl}
                name={getNameByUnit(unit.value)}
              />
            )}

            {showCron && <SchedulerCron control={localControl} />}
          </Stack>
        </Stack>
      </Stack>

      <ScheduleFooter align="end">
        <CronEnglishContainer>
          <Typography>{getCronExpressionByUnit(values)}</Typography>
        </CronEnglishContainer>
        <Button
          type="submit"
          id={getValueFromObject(plan, 'plan.cronPopup.componentId')}
          data-premium-component-id={getValueFromObject(
            plan,
            'plan.cronPopup.componentId'
          )}
          data-premium-component-trigger={getValueFromObject(
            plan,
            'plan.cronPopup.trigger'
          )}
        >
          Create Schedule
        </Button>
      </ScheduleFooter>
    </SchedulerFormWrapper>
  );
}
