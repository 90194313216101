import { gql, useMutation } from '@apollo/client';

import { DecisionTableResponse } from '../../components/DecisionTable/models';

const discardRule = gql`
  mutation discardRule($id: String!, $checksum: String!) {
    discardRule(id: $id, checksum: $checksum) {
      id
      name
      description
      version
      status
      isLive
      workspaceId
      type
      staticUrl
      policy
      dataSetId
      checksum
      firstCustomInput
      warnings
      decisionTable {
        firstProperty
        firstRow
        firstResult
        firstAggOutputData
        properties
        rows
        results
      }
      production {
        startAt
        endAt
        isEnabled
        auditIO
        api {
          isEnabled
          isPrivate
          authId
        }
        salience
        schedule {
          startAt
          endAt
          inputParam
          unit
          spec
          timezone
          isEnabled
        }
      }
      staging {
        startAt
        endAt
        isEnabled
        auditIO
        api {
          isEnabled
          isPrivate
          authId
        }
        salience
      }
      conditions {
        startNode
        nodes
      }
      action {
        then {
          outputData
          firstOutputData
          firstActionNode
          actionNode
        }
        else {
          outputData
          firstOutputData
          actionNode
        }
      }
      customInput
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
      dependencyMap {
        id
        name
        type
        version
        commitTitle
        subType
        nodeId
      }
    }
  }
`;

export type DiscardDecisionTableResponse = {
  discardRule: DecisionTableResponse;
};

export function useDiscardDecisionTable() {
  return useMutation<
    DiscardDecisionTableResponse,
    {
      id: string;
      checksum: string;
    }
  >(discardRule);
}
