import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _map from 'lodash/map';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ExpandingTextField,
  Sheet,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { stopPropagate } from '../../../../../utils/form';
import { KeyValuePairList } from '../../../../Integrations/types';
import {
  changedNodeIdsAtom,
  isWorkflowReadOnlyAtom,
  isWorkflowTestOnlyAtom,
  workflowErrorByNodeAtom,
  workflowNodeSavingAtom,
  workflowNodesAtom,
} from '../../../atoms/atoms';
import { useGetConnectorById } from '../../../hooks/graphql/useGetConnectorById';
import { useGetPluginByName } from '../../../hooks/graphql/useGetPluginByName';
import { useTestRestApiNode } from '../../../hooks/useTestRestApiNode';
import { useUpdateExecutedValue } from '../../../hooks/useUpdateExecutedValue';
import type { RestApiNodeForm } from '../../../models/models';
import {
  checkIfNameExists,
  formatRestApiInWorkflow,
  getExecutedValueAndStatus,
  transformRestNodeInWorkflow,
} from '../../../utils/common';
import {
  nodeNameValidationBeforeSave,
  validateRestApiNode,
} from '../../../utils/validations';
import {
  SheetFooterStyled,
  WorkflowSheetFormStyled,
  WorkflowSheetTabContentStyled,
} from '../../CommonStyles/CommonStyles.styled';
import { RuleSheetCloseModal } from '../../Modals/RuleSheetCloseModal/RuleSheetCloseModal';
import { RestApiSettings } from './RestApiActionSettings';
import { RestApiMapping } from './RestApiMapping/RestApiMapping';
import { RestApiNodeTest } from './RestApiTest/RestApiTest';

type RestApiNodeSheetProps = {
  data?: any;
  id?: string;
};

export function RestApiActionSheet({
  data: localData,
  id = '',
}: RestApiNodeSheetProps) {
  const [isResultDisabled] = useState(false);
  const [nodeTestData, setNodeTestData] = useState<Record<string, any>>({});

  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);

  const [, setWorkflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);
  const [workflowNodeSaving, setWorkflowNodeSaving] = useAtom(
    workflowNodeSavingAtom
  );

  const [connector, setConnector] = useState<any>(null);
  const [isSaving, setIsSaving] = useState(false);

  const [counter, setCounter] = useState(0);
  const [currentStatus, setCurrentStatus] = useState<string>(
    localData.status ?? ''
  );
  const [currentTab, setCurrentTab] = useState(0);

  const [disabledHeaders, setDisabledHeaders] = useState<KeyValuePairList[]>(
    []
  );
  const [disabledQueryParams, setDisabledQueryParams] = useState<
    KeyValuePairList[]
  >([]);

  const { close } = useCurrentLayer();

  const { openWithProps: openSheetCloseModal } = useLayer(
    <RuleSheetCloseModal onClose={close} title="Close rest api Sheet" />
  );

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const [getConnectorById] = useGetConnectorById();

  const {
    testData,
    restApiData,
    restApiError,
    tokens,
    updatedDataSet,
    parentNodes,
    isTesting,
    setIsTesting,
  } = useTestRestApiNode({
    localData,
    id,
  });

  const [getPluginByName, { data: pluginData }] = useGetPluginByName();

  const { executedValue, handleGetExecutionValues } = useUpdateExecutedValue({
    parentNodes,
    updatedDataset: updatedDataSet,
  });

  const { control, clearErrors, watch, handleSubmit, setError, setValue } =
    useForm<RestApiNodeForm>({
      defaultValues: formatRestApiInWorkflow(localData),
    });

  const onSubmit = async (dt: any, test: boolean = false) => {
    setIsSaving(true);

    clearErrors();
    setWorkflowErrorByNode((prev) => ({
      ...prev,
      [id]: undefined,
    }));

    const isNameValid = nodeNameValidationBeforeSave(dt.name, setError);

    if (!isNameValid) {
      setIsTesting(false);
      setIsSaving(false);

      return;
    }

    const sheetData = transformRestNodeInWorkflow(dt);

    if (!_isNil(workflowNode)) {
      const newWorkflowNode = workflowNode;
      newWorkflowNode.data.status = currentStatus;

      if (
        dt.name !== localData.name &&
        typeof localData.updateOnNameChange === 'function'
      ) {
        const doesNameExist = checkIfNameExists(
          workflowNodes,
          dt.name,
          newWorkflowNode
        );

        if (doesNameExist) {
          setError('name', {
            message: 'Duplicate name provided',
          });

          return null;
        }

        localData.updateOnNameChange({
          id,
          name: localData.name,
          newName: dt.name,
        });
      }

      newWorkflowNode.data.name = sheetData.name;
      newWorkflowNode.data.input = sheetData.input;
      newWorkflowNode.data.entityId = sheetData.entityId;

      newWorkflowNode.data.executedValue =
        currentStatus !== ''
          ? !_isNil(restApiData) && !_isNil(restApiData?.data)
            ? getExecutedValueAndStatus(restApiData).executedValue
            : newWorkflowNode.data.executedValue ?? {}
          : null;

      newWorkflowNode.data.settings = sheetData.settings;

      if (counter > 1) {
        setChangedNodeIds([id]);
        setWorkflowNodeSaving(true);
      }

      localData.onWorkflowNodeChange(newWorkflowNode);
    }

    if (!test) {
      close();
    }

    if (test) {
      setCounter(1);
      setNodeTestData(sheetData);

      const isValid = await validateRestApiNode(dt, setError, updatedDataSet);

      if (!isValid) {
        setIsSaving(false);

        return;
      }

      setIsTesting(true);
    }

    setIsSaving(false);

    return null;
  };

  const handleSaveData = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, false))
  );

  const handleSaveDataAndTest = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, true))
  );

  const integration = watch('integration');

  useEffect(() => {
    void handleGetConnectorProps(integration?.value ?? '', true);
  }, [JSON.stringify(integration)]);

  useEffect(() => {
    if (!_isNil(restApiData)) {
      if (!_isNil(workflowNode)) {
        const newWorkflowNode = workflowNode;
        const exec = getExecutedValueAndStatus(restApiData);
        setCurrentStatus(exec.status);

        newWorkflowNode.data.status = exec.status;
        newWorkflowNode.data.executedValue = exec.executedValue;

        setChangedNodeIds([]);

        setTimeout(() => {
          localData.onWorkflowNodeChange(newWorkflowNode);
        }, 100);
      }
    }
  }, [restApiData]);

  useEffect(() => {
    // eslint-disable-next-line
    if (!localData?.entityId && !!localData?.blockName) {
      void getPluginByName({
        variables: {
          filters: {
            eq: {
              name: localData?.blockName,
            },
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!_isNil(pluginData)) {
      setValue('plugin.name', pluginData?.getPlugin.data?.[0].name);
      setValue('plugin.imageUrl', pluginData?.getPlugin.data?.[0].imageUrl);
      setValue(
        'plugin.displayName',
        pluginData?.getPlugin.data?.[0].displayName
      );
      setValue('plugin.category', pluginData?.getPlugin.data?.[0].category);
      setValue('plugin.id', pluginData?.getPlugin.data?.[0].id);
    }
  }, [pluginData]);

  const formValues = watch();

  useEffect(() => {
    setCounter((count) => count + 1);
  }, [JSON.stringify(formValues)]);

  useEffect(() => {
    if (counter > 1) {
      setCurrentStatus('');
    }
  }, [counter]);

  useEffect(() => {
    if (!workflowNodeSaving && isTesting) {
      void handleTestNode();
    }
  }, [workflowNodeSaving, isTesting]);

  const handleTestNode = async () => {
    await testData(nodeTestData, () => {
      setCurrentTab(1);
      setIsTesting(false);
      setNodeTestData({});
    });

    setIsSaving(false);
  };

  const updateConfig = (connectorData: Record<string, any>) => {
    if (!_isNil(connectorData) && !_isNil(connectorData.staging.conf)) {
      if (Array.isArray(connectorData.staging.conf.headers)) {
        setDisabledHeaders(connectorData.staging.conf.headers);
      } else {
        setDisabledHeaders(
          _map(connectorData.staging.conf.headers, (value, key) => {
            return {
              key,
              value,
            };
          })
        );
      }

      if (Array.isArray(connectorData.staging.conf.queryParams)) {
        setDisabledQueryParams(connectorData.staging.conf.queryParams);
      } else {
        setDisabledQueryParams(
          _map(connectorData.staging.conf.queryParams, (value, key) => {
            return {
              key,
              value,
            };
          })
        );
      }
    }
  };

  const handleGetConnectorProps = async (
    id: string,
    isSetValue: boolean = false
  ) => {
    if (_isEmpty(id)) {
      return;
    }

    try {
      const data = await getConnectorById({
        variables: {
          id,
        },
      });

      const connectorData = data.data.getConnector?.data[0];

      setConnector(connectorData);

      if (isSetValue) {
        setValue('plugin.name', connectorData.plugin.name);
        setValue('plugin.imageUrl', connectorData.plugin.imageUrl);
        setValue('plugin.displayName', connectorData.plugin.displayName);
        setValue('plugin.category', connectorData.plugin.category);
        setValue('plugin.id', connectorData.plugin.id);

        setValue('integration', {
          label: connectorData.name,
          value: connectorData.id,
        });

        setValue('input.baseUrl.value', connectorData.staging.conf.baseUrl);
        setValue(
          'input.authentication.value',
          !_isNil(connectorData.staging.conf.credentialId) &&
            !_isEmpty(connectorData.staging.conf.credentialId)
            ? 'Private'
            : 'None'
        );

        updateConfig(connectorData);
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('error', error);
    }
  };

  const isLoading = isTesting || workflowNodeSaving || isSaving;
  const selectedConnector = watch('integration');

  return (
    <Sheet
      size="small"
      onClose={
        counter > 2
          ? () => {
              openSheetCloseModal({
                onClose: () => {
                  // eslint-disable-next-line
                  if (!(!!localData?.entityId || !!selectedConnector?.value)) {
                    localData.deleteNode({
                      id,
                      type: localData.nodeType ?? 'dbNode',
                    });
                  }
                  close();
                },
              });
            }
          : () => {
              // eslint-disable-next-line
              if (!(!!localData?.entityId || !!selectedConnector?.value)) {
                localData.deleteNode({
                  id,
                  type: localData.nodeType ?? 'dbNode',
                });
              }
              close();
            }
      }
    >
      <WorkflowSheetFormStyled>
        <Inline stretch="start">
          <Stack as={PadBox} gutter={48} padding={[16, 24]}>
            <Inline stretch="start">
              <Stack gutter={8}>
                <Inline align="center" gutter="1.6rem" justify="start">
                  <Typography name="heading2">
                    <ExpandingTextField
                      control={control}
                      name="name"
                      disabled={isWorkflowReadOnly}
                    />
                  </Typography>
                </Inline>
              </Stack>
            </Inline>
          </Stack>
        </Inline>

        <Tabs defaultOpen={currentTab} onTabChange={(i) => setCurrentTab(i)}>
          <TabList>
            <Tab>
              <Typography fontWeight={700}>Input Params</Typography>
            </Tab>

            <Tab disabled={isResultDisabled}>
              <Typography
                fontWeight={700}
                name={isResultDisabled ? 'secondarySmall' : 'paragraph'}
              >
                Test Results
              </Typography>
            </Tab>

            <Tab>
              <Typography fontWeight={700}>Settings</Typography>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <WorkflowSheetTabContentStyled>
                <PadBox padding="2rem">
                  <RestApiMapping
                    disabledHeaders={disabledHeaders}
                    disabledQueryParams={disabledQueryParams}
                    control={control}
                    tokens={tokens}
                    handleGetExecutionValues={handleGetExecutionValues}
                    execValues={executedValue}
                    setValue={setValue}
                    watch={watch}
                    connectorData={connector}
                    localData={localData}
                  />
                </PadBox>
              </WorkflowSheetTabContentStyled>
            </TabPanel>
            <TabPanel>
              <RestApiNodeTest
                output={
                  !_isNil(restApiData?.data?.data)
                    ? getExecutedValueAndStatus(restApiData).executedValue
                    : restApiError?.response?.data ?? localData.executedValue
                }
              />
            </TabPanel>
            <TabPanel>
              <RestApiSettings control={control} name="settings" />
            </TabPanel>
          </TabPanels>
        </Tabs>

        <SheetFooterStyled>
          <Button
            appearance="filled"
            disabled={isLoading || !isWorkflowTestOnly}
            onClick={handleSaveDataAndTest}
          >
            {isLoading ? <Spinner size="extraSmall" /> : <Inline>Test</Inline>}
          </Button>

          <Button
            disabled={isWorkflowReadOnly || isLoading}
            appearance="contained"
            onClick={handleSaveData}
          >
            {isLoading && !isTesting ? (
              <Spinner size="extraSmall" />
            ) : (
              <Inline>Save</Inline>
            )}
          </Button>
        </SheetFooterStyled>
      </WorkflowSheetFormStyled>
    </Sheet>
  );
}
