import { gql, useMutation } from '@apollo/client';

const createWorkflowQuery = gql`
  mutation CreateWorkflow(
    $name: String!
    $description: String
    $nodes: [NodeInput!]
    $edges: [EdgeInput!]
    $isEnabled: Boolean
    $startNode: String
    $trigger: TriggerInput
    $staging: SettingsInput
    $production: SettingsInput
    $dependencyMap: [DependencyInput!]
  ) {
    createWorkflow(
      input: {
        name: $name
        description: $description
        nodes: $nodes
        edges: $edges
        isEnabled: $isEnabled
        startNode: $startNode
        trigger: $trigger
        staging: $staging
        production: $production
        dependencyMap: $dependencyMap
      }
    ) {
      id
      name
      checksum
      staticUrl
      status
      isLive
      createdAt
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
      dependencyMap {
        id
        name
        type
        version
        commitTitle
        subType
        nodeId
      }
    }
  }
`;

export function useCreateWorkflow() {
  return useMutation(createWorkflowQuery);
}
