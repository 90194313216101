import type { ObservableQuery } from '@apollo/client';
import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { atom, useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  SimpleDropDownModel,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  toasts,
  useLayer,
} from 'ui';

import { checksumWarningAtom } from '../../../../atom';
import { permissionObj } from '../../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../../components/PermissionComponent/hooks/useCheckPermissions';
import { customAttributesAtom } from '../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { useHandleRequests } from '../../../../hooks/useHandleRequests';
import { DependencyUsingMapType } from '../../../../types';
import {
  checksumMessage,
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
} from '../../../../utils/common';
import { ENTITY_ID } from '../../../../utils/constant';
import type { TableResponseModel } from '../../hooks/graphql/useGetTableData';
import { useSaveRuleset } from '../../hooks/graphql/useSaveRuleset';
import { useUpdateNameDesc } from '../../hooks/graphql/useUpdateNameDesc';
import { useUpdateRsPolicy } from '../../hooks/graphql/useUpdateRsPolicy';
import { useUpdateRuleSet } from '../../hooks/graphql/useUpdateRuleSet';
import { useUpdateRuleSetItems } from '../../hooks/graphql/useUpdateRuleSetItems';
import { useUpdateTriggers } from '../../hooks/graphql/useUpdateTriggers';
import { useEditRuleSet } from '../../hooks/useEditRuleSet';
import { useInitializeRuleSet } from '../../hooks/useInitializeRuleSet';
import {
  isLiveNodeSheetClosedAtom,
  isRulePublishOnlyAtom,
  isRuleReadOnlyAtom,
  isRuleTestOnlyAtom,
} from '../../index';
import { SaveType } from '../../models';
import {
  formatCustomAttributesRuleSet,
  isRuleNameValid,
  transformRulesetToRequestPayload,
  validateRuleSetBeforeTesting,
} from '../../utils/common';
import {
  CreateRuleSheet,
  enableCronTestButtonAtom,
  isRulePublishableAtom,
  isRulePublishedAtom,
  ruleEnvironmentAtom,
  ruleSavingErrorAtom,
} from '../CreateRuleSheet/CreateRuleSheet';
import { ApiTriggerAndCronInfo } from '../RuleComponents/ApiTriggerAndCronInfo/ApiTriggerAndCronInfo';
import { RuleSheetFooter } from '../RuleComponents/RuleSheetFooter';
import { RuleSheetHeader } from '../RuleComponents/RuleSheetHeader';
import { RuleLoader } from '../RuleLoader/RuleLoader';
import { authTypes } from '../Triggers/AuthenticationDropDown';
import {
  approvalInfoRuleAtom,
  isRuleLiveAtom,
  versionInfoRuleAtom,
  versionMappingInfoAtom,
} from '../atom/atom';
import {
  RuleSetEditor,
  customAttributesByIdAtom,
  resultByRuleAtom,
  rulesListAtom,
} from './RuleSetEditor/RuleSetEditor';
import { CreateRuleContainer } from './Ruleset.styled';
import { TestNodeSheet } from './TestNodeSheet/TestNodeSheet';
import { RuleSetNodesModel, RulesetModel } from './models';
import { rulesetValidationSchema } from './schema';

export type RulesetProps = {
  ruleName?: string;

  // This variable RuleId comes from the top level in case the rule has already
  // been saved and comes in case of rule edit and clone
  ruleId?: string;
  isClone?: boolean;
  refetch?: ObservableQuery<TableResponseModel>['refetch'];
  isLive?: boolean;
  commitId?: string;
};

const ruleListAtom = atom<RuleSetNodesModel[]>([]);

export const ruleSetNodeId = atom<string | undefined>(undefined);

export const filteredRuleOptionsAtom = atom<SimpleDropDownModel[]>([]);

export function RuleSet({
  ruleName = 'Untitled',
  ruleId,
  isClone = false,
  refetch,
  isLive = false,
  commitId,
}: RulesetProps) {
  const [ruleList] = useAtom(ruleListAtom);
  const [ruleResultById] = useAtom(resultByRuleAtom);

  // This Id is local variable and default value of this Id is 'Undefined'
  const [id, setId] = useAtom(ruleSetNodeId);
  const [, setIsRulePublishable] = useAtom(isRulePublishableAtom);
  const [ruleSetEnvironment] = useAtom(ruleEnvironmentAtom);
  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);
  const [isRuleReadOnly, setIsRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [isLiveNodeSheetClosed, setIsLiveNodeSheetClosed] = useAtom(
    isLiveNodeSheetClosedAtom
  );

  const [, setRuleSavingError] = useAtom(ruleSavingErrorAtom);
  const [, setIsRulePublished] = useAtom(isRulePublishedAtom);
  const [, setFilteredRuleOptions] = useAtom(filteredRuleOptionsAtom);
  const [ruleOptions] = useAtom(rulesListAtom);
  const [, setEnableCronTest] = useAtom(enableCronTestButtonAtom);
  const [customAttributes, setCustomAttributes] = useAtom(customAttributesAtom);
  const [customAttributesById] = useAtom(customAttributesByIdAtom);

  const [, setIsRuleTestOnly] = useAtom(isRuleTestOnlyAtom);
  const [, setIsRulePublishOnly] = useAtom(isRulePublishOnlyAtom);

  const [, setIsRuleLive] = useAtom(isRuleLiveAtom);
  const [, setVersionInfoRule] = useAtom(versionInfoRuleAtom);
  const [, setApprovalInfoRule] = useAtom(approvalInfoRuleAtom);
  const [versionMappingInfo, setVersionMappingInfo] = useAtom(
    versionMappingInfoAtom
  );

  const [enableAutoSave, setEnableAutoSave] = useState(false);
  const [isTesting, setIsTesting] = useState(false);

  const { openWithProps: openLiveNodeSheet } = useLayer(
    <CreateRuleSheet ruleId={ruleId} isLive={true} from="viewLive" />
  );

  const { control, handleSubmit, setValue, setError, watch } = useForm<any>({
    resolver: zodResolver(rulesetValidationSchema),
    defaultValues: {
      ruleName,
      ruleDescription: '',
      rulePolicy: null,
      ruleList,
      stagingConfig: {
        staticUrl: '',
        order: 0,
        startDate: null,
        endDate: null,
        auditIO: false,
        api: '',
        isEnabled: true,
        authType: authTypes.find((authType) => authType.label === 'None'),
        isApiEnabled: true,
      },
      productionConfig: {
        staticUrl: '',
        order: 0,
        startDate: null,
        endDate: null,
        auditIO: false,
        api: '',
        isEnabled: true,
        authType: authTypes.find((authType) => authType.label === 'None'),
        isApiEnabled: true,
        schedule: null,
      },
      createdAt: null,
      publishedAt: null,
    },
    mode: 'onSubmit',
  });

  const { openWithProps: openTestNodesSheet } = useLayer(
    <TestNodeSheet ruleName={ruleName} setValue={setValue} />
  );

  const [saveRuleSet, { loading: isRuleCreating }] = useSaveRuleset();
  const [updateRuleSet, { loading: isRuleUpdating }] = useUpdateRuleSet();
  const [updateRuleName, { loading: isRuleUpdatingName }] = useUpdateNameDesc();
  const [updateTriggers, { loading: isRuleUpdatingTriggers }] =
    useUpdateTriggers();
  const [updateRsPolicy, { loading: isRuleUpdatingPolicy }] =
    useUpdateRsPolicy();
  const [updateRulesetItems, { loading: isRuleUpdatingItems }] =
    useUpdateRuleSetItems();

  const isUpdating =
    isRuleUpdatingTriggers ||
    isRuleUpdating ||
    isRuleUpdatingPolicy ||
    isRuleUpdatingItems ||
    isRuleUpdatingName;

  const isMutating = isRuleCreating || isUpdating;

  const {
    stagingConfig,
    productionConfig,
    ruleList: listOfRules,
    ruleSetPolicy,
    discardRuleById,
    ruleSetName,
    ruleSetDescription,
    ruleLoading,
    ruleVersion,
    loadingData,
    currentRuleData,
    handleGetRuleAfterStateTransition,
  } = useEditRuleSet({
    isClone,
    ruleId: ruleId ?? id,
    isLive,
    ruleIdExist: !_isNil(ruleId) && !_isEmpty(ruleId),
    isLiveNodeSheetClosed,
    commitId,
  });

  useInitializeRuleSet({
    ruleList: listOfRules,
    setValue,
    productionConfig,
    stagingConfig,
    ruleSetPolicy,
    ruleSetName,
    ruleSetDescription,
    currentRuleData,
  });

  const ruleSetRuleName = useWatch({
    control,
    name: 'ruleName',
  });

  const ruleSetRuleDescription = useWatch({
    control,
    name: 'ruleDescription',
  });

  const rulePolicy = useWatch({
    control,
    name: 'rulePolicy',
  });

  const stagingConfiguration = useWatch({
    control,
    name: 'stagingConfig',
  });

  const productionConfiguration = useWatch({
    control,
    name: 'productionConfig',
  });

  const ruleSetList: RuleSetNodesModel[] = useWatch({
    control,
    name: 'ruleList',
  });

  const statusValue = useWatch({
    control,
    name: 'status',
  });

  const { isHide: isEditDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.rules],
    entityStatus: statusValue,
  });

  const { isHide: testDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.test],
    entityList: [ENTITY_ID.rules],
    entityStatus: statusValue,
  });
  const { isHide: publishDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.publish],
    entityList: [ENTITY_ID.rules],
    entityStatus: statusValue,
  });

  const handlePostUpdate = (updatedRule?: Record<string, any> | null) => {
    handleSetCheckSumByEntityName('rule', updatedRule?.updateRule.checksum);

    setRuleSavingError('');

    setValue('status', updatedRule?.updateRule.status ?? 'draft');
    setValue('publishedAt', updatedRule?.updateRule.publishedAt);

    setVersionInfoRule(updatedRule?.updateRule.versionInfo);
    setIsRuleLive(updatedRule?.updateRule.isLive ?? false);
    setApprovalInfoRule(updatedRule?.updateRule.approvalInfo);

    setVersionMappingInfo(
      updatedRule?.updateRule.dependencyMap?.map(
        (currMapping: DependencyUsingMapType) => ({
          entityId: currMapping.id,
          type: currMapping.type,
          version: currMapping.version,
          nodeId: currMapping.nodeId,
        })
      ) ?? []
    );
  };

  const onSubmit = async (
    data: RulesetModel,
    test: boolean = false,
    notSave: boolean = false,
    type: SaveType = 'all'
  ) => {
    if (test) {
      setIsTesting(true);
    }

    const ruleJson = transformRulesetToRequestPayload(
      data,
      ruleId,
      data.ruleList[0]?.ruleId
    );

    const isNameValid = isRuleNameValid(data.ruleName, setError);

    const { ruleErrors, commonItems } = validateRuleSetBeforeTesting(
      data,
      ruleResultById,
      customAttributesById
    );

    const testingValid =
      ruleErrors.length === 0 &&
      commonItems.length === 0 &&
      !_isNil(rulePolicy) &&
      !_isEmpty(data.ruleList) &&
      isNameValid;

    const isTestingValid = testingValid && test;

    const { newCustomAttributes: customInput, newSaveCustomAttributes } =
      formatCustomAttributesRuleSet(
        customAttributes,
        data.ruleList,
        customAttributesById
      );

    setCustomAttributes(customInput);

    if (test) {
      ruleErrors.forEach((error) => {
        if (!notSave) {
          setError(`ruleList.${error}.ruleId`, {
            message: 'Select a valid rule',
            type: 'validate',
          });
        }
      });

      commonItems.forEach((item) => {
        item.indexes.forEach((index: number) => {
          if (!notSave) {
            setError(`ruleList.${index}.ruleId`, {
              message: 'Inputs have conflicting dataTypes',
              type: 'validate',
            });
          }
        });
      });

      if (_isEmpty(data.ruleList)) {
        toasts.error('Please add at least one rule', 'error');
      }

      if (_isNil(data.rulePolicy)) {
        if (!notSave) {
          setError(`rulePolicy`, {
            message: 'Must select a policy',
            type: 'validate',
          });
        }
      }
    }

    if (testingValid) {
      setEnableCronTest(true);
    } else {
      setEnableCronTest(false);
    }

    if (!isNameValid) {
      removeRequest();
      setIsTesting(false);

      return;
    }
    setIsTesting(false);

    if (!_isNil(id) && !_isEmpty(id) && !test) {
      if (type === 'all') {
        try {
          const checksum = handleGetCheckSumByEntityName('rule');

          const { data: updatedRule } = await updateRuleSet({
            variables: {
              id,
              name: ruleJson.name,
              description: ruleJson.description,
              firstRuleChain: ruleJson.firstRuleChain,
              ruleChain: ruleJson.ruleChain,
              ruleSet: ruleJson.ruleSet,
              ruleSetPolicy: ruleJson.ruleSetPolicy,
              staging: ruleJson.trigger.staging,
              production: ruleJson.trigger.production,
              checksum: checksum ?? '',
              customInput: newSaveCustomAttributes,
              editMode: ruleJson.editMode,
              dependencyMap: versionMappingInfo,
            },
          });

          handlePostUpdate(updatedRule);
        } catch (error: unknown) {
          if (error instanceof Error) {
            setRuleSavingError(error.message);

            if (error.message.includes(checksumMessage)) {
              setShowChecksumPopup({
                showPopup: true,
                metaData: { ruleId: id, ruleName, type: 'ruleSet' },
              });
            } else {
              toasts.error(error.message, 'error');
            }
          }
        }
      } else if (type === 'nameDesc') {
        try {
          const checksum = handleGetCheckSumByEntityName('rule');

          const { data: updatedRule } = await updateRuleName({
            variables: {
              id,
              name: ruleJson.name,
              description: ruleJson.description,
              checksum: checksum ?? '',
              editMode: ruleJson.editMode,
              dependencyMap: versionMappingInfo,
            },
          });

          handlePostUpdate(updatedRule);
        } catch (error: unknown) {
          if (error instanceof Error) {
            setRuleSavingError(error.message);

            if (error.message.includes(checksumMessage)) {
              setShowChecksumPopup({
                showPopup: true,
                metaData: { ruleId: id, ruleName, type: 'ruleSet' },
              });
            } else {
              toasts.error(error.message, 'error');
            }
          }
        }
      } else if (type === 'triggers') {
        try {
          const checksum = handleGetCheckSumByEntityName('rule');

          const { data: updatedRule } = await updateTriggers({
            variables: {
              id,
              staging: ruleJson.trigger.staging,
              production: ruleJson.trigger.production,
              checksum: checksum ?? '',
              editMode: ruleJson.editMode,
              dependencyMap: versionMappingInfo,
            },
          });

          handlePostUpdate(updatedRule);
        } catch (error: unknown) {
          if (error instanceof Error) {
            setRuleSavingError(error.message);

            if (error.message.includes(checksumMessage)) {
              setShowChecksumPopup({
                showPopup: true,
                metaData: { ruleId: id, ruleName, type: 'ruleSet' },
              });
            } else {
              toasts.error(error.message, 'error');
            }
          }
        }
      } else if (type === 'rsPolicy') {
        try {
          const checksum = handleGetCheckSumByEntityName('rule');

          const { data: updatedRule } = await updateRsPolicy({
            variables: {
              id,
              ruleSetPolicy: ruleJson.ruleSetPolicy,
              checksum: checksum ?? '',
              editMode: ruleJson.editMode,
              dependencyMap: versionMappingInfo,
            },
          });

          handlePostUpdate(updatedRule);
        } catch (error: unknown) {
          if (error instanceof Error) {
            setRuleSavingError(error.message);

            if (error.message.includes(checksumMessage)) {
              setShowChecksumPopup({
                showPopup: true,
                metaData: { ruleId: id, ruleName, type: 'ruleSet' },
              });
            } else {
              toasts.error(error.message, 'error');
            }
          }
        }
      } else if (type === 'ruleSet') {
        try {
          const checksum = handleGetCheckSumByEntityName('rule');

          const { data: updatedRule } = await updateRulesetItems({
            variables: {
              id,
              ruleSet: ruleJson.ruleSet,
              ruleChain: ruleJson.ruleChain,
              firstRuleChain: ruleJson.firstRuleChain,
              customInput: newSaveCustomAttributes,
              checksum: checksum ?? '',
              editMode: ruleJson.editMode,
              dependencyMap: versionMappingInfo,
            },
          });

          handlePostUpdate(updatedRule);
        } catch (error: unknown) {
          if (error instanceof Error) {
            setRuleSavingError(error.message);

            if (error.message.includes(checksumMessage)) {
              setShowChecksumPopup({
                showPopup: true,
                metaData: { ruleId: id, ruleName, type: 'ruleSet' },
              });
            } else {
              toasts.error(error.message, 'error');
            }
          }
        }
      }
    } else if (!test) {
      try {
        const data = await saveRuleSet({
          variables: {
            type: ruleJson.type,
            name: ruleJson.name,
            description: ruleJson.description,
            firstRuleChain: ruleJson.firstRuleChain,
            ruleChain: ruleJson.ruleChain,
            ruleSet: ruleJson.ruleSet,
            ruleSetPolicy: ruleJson.ruleSetPolicy,
            staging: ruleJson.trigger.staging,
            production: ruleJson.trigger.production,
            customInput: newSaveCustomAttributes,
            dependencyMap: versionMappingInfo,
          },
        });

        handleSetCheckSumByEntityName('rule', data.data.createRule.checksum);

        setId(data.data.createRule.id);

        setValue('productionConfig.staticUrl', data.data.createRule.staticUrl);
        setValue('stagingConfig.staticUrl', data.data.createRule.staticUrl);

        setRuleSavingError('');
      } catch (error: unknown) {
        if (error instanceof Error) {
          setRuleSavingError(error.message);

          if (error.message.includes(checksumMessage)) {
            setShowChecksumPopup({
              showPopup: true,
              metaData: { ruleId: id, ruleName, ruleType: 'ruleSet' },
            });
          } else {
            toasts.error(error.message, 'error');
          }
        }
      }
    }

    removeRequest();

    if (isTestingValid && !notSave) {
      openTestNodesSheet({ ruleName: ruleSetRuleName });
    }
  };

  useEffect(() => {
    setId(isClone ? undefined : ruleId);

    return () => {
      if (typeof refetch === 'function') {
        void refetch();
      }

      if (!isLive) {
        setId(undefined);
      }
    };
  }, [ruleId, isLive]);

  const submitForm = handleSubmit(async (data) => await onSubmit(data));

  const submitFormTriggers = handleSubmit(
    async (data) => await onSubmit(data, false, false, 'triggers')
  );

  const submitFormPolicy = handleSubmit(
    async (data) => await onSubmit(data, false, false, 'rsPolicy')
  );

  const submitFormNameDesc = handleSubmit(
    async (data) => await onSubmit(data, false, false, 'nameDesc')
  );

  const submitFormRuleSet = handleSubmit(
    async (data) => await onSubmit(data, false, false, 'ruleSet')
  );

  const submitAndTestForm = handleSubmit(
    async (data) => await onSubmit(data, true)
  );

  const handleEditButton = handleSubmit(
    async (data) => await onSubmit({ ...data, editMode: true })
  );

  const onSubmitAndTestCron = handleSubmit(
    async (data) => await onSubmit(data, true, true)
  );

  const { appendRequest, removeRequest } = useHandleRequests({
    onResolve: async (resArgs: { type: string }) => {
      if (resArgs.type === 'policy') {
        await submitFormPolicy();
      } else if (resArgs.type === 'ruleSet') {
        await submitFormRuleSet();
      } else if (resArgs.type === 'triggers') {
        await submitFormTriggers();
      } else if (resArgs.type === 'nameDesc') {
        await submitFormNameDesc();
      } else {
        await submitForm();
      }
    },
    hasActivity: isMutating,
  });

  const ruleIsLoading = ruleLoading || loadingData;

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (!isLive && (!isRuleReadOnly || isClone) && !ruleIsLoading) {
      submitTimeout = setTimeout(() => {
        if (!enableAutoSave) {
          void onSubmitAndTestCron();
        }
        setEnableAutoSave(true);

        if ((enableAutoSave || isClone) && _isNil(id)) {
          appendRequest({ type: 'all' });
          setIsRulePublishable(false);
          setIsRulePublished(false);
        }
      }, 1000);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    ruleSetList,
    ruleSetRuleName,
    ruleSetRuleDescription,
    stagingConfiguration,
    productionConfiguration,
    rulePolicy,
    ruleIsLoading,
    ruleResultById,
    isRuleReadOnly,
    JSON.stringify(versionMappingInfo),
  ]);

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (enableAutoSave && !_isNil(id)) {
      submitTimeout = setTimeout(() => {
        void submitFormNameDesc();
        appendRequest({ type: 'nameDesc' });
      }, 1000);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [ruleSetRuleName, ruleSetRuleDescription]);

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (enableAutoSave && !_isNil(id)) {
      submitTimeout = setTimeout(() => {
        // void submitFormTriggers();
        appendRequest({ type: 'triggers' });
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    JSON.stringify(stagingConfiguration),
    JSON.stringify(productionConfiguration),
  ]);

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (enableAutoSave && !_isNil(id)) {
      submitTimeout = setTimeout(() => {
        // void submitFormRuleSet();
        appendRequest({ type: 'ruleSet' });
      }, 1000);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [JSON.stringify(ruleSetList), JSON.stringify(versionMappingInfo)]);

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (enableAutoSave && !_isNil(id)) {
      submitTimeout = setTimeout(() => {
        // void submitFormPolicy();
        appendRequest({ type: 'policy' });
      }, 1000);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [rulePolicy]);

  useEffect(() => {
    const updatedOptions = ruleOptions.filter(
      (ruleOption) =>
        !ruleSetList.some(
          (rule: { ruleId: string }) => rule.ruleId === ruleOption.id
        )
    );

    setFilteredRuleOptions(updatedOptions);
  }, [ruleOptions, ruleSetList]);

  useEffect(() => {
    setIsRuleReadOnly(isEditDisable);
  }, [isEditDisable]);

  useEffect(() => {
    setIsRuleTestOnly(!testDisable);
  }, [testDisable]);

  useEffect(() => {
    setIsRulePublishOnly(!publishDisable);
  }, [publishDisable]);

  const handleFetchRule = () => {
    setEnableAutoSave(false);
    void handleGetRuleAfterStateTransition();
  };

  if (ruleIsLoading) {
    return <RuleLoader />;
  }

  const onPublishFail = (rules: Record<string, any>) => {
    const ruleList = watch('ruleList');

    ruleList?.forEach((r: any, i: number) => {
      if (!_isNil(rules[r.ruleId])) {
        setError(`ruleList.${i}.ruleId`, {
          message: rules[r.ruleId].message ?? 'Please publish the rule before',
        });
      }
    });
  };

  return (
    <form>
      <CreateRuleContainer>
        <PadBox padding={[10, 20]}>
          <Stack gutter={10}>
            <RuleSheetHeader
              control={control}
              title="ruleset"
              id={ruleId ?? ''}
              onLiveButtonClick={() => {
                setIsRuleReadOnly(true);
                openLiveNodeSheet({ ruleId: id, from: 'viewLive' });
                setEnableAutoSave(false);
                setIsLiveNodeSheetClosed(false);
              }}
              ruleVersion={ruleVersion}
              showLiveButton={!isLive}
              setValue={setValue}
              isReadOnly={isLive || isRuleReadOnly}
              handleFetchRule={handleFetchRule}
            />
          </Stack>
        </PadBox>

        <ApiTriggerAndCronInfo id={ruleId ?? ''} control={control} />

        <Tabs>
          <TabList styleClassName="rsTabListStyle">
            <Tab>Editor</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {!ruleIsLoading ? (
                <RuleSetEditor
                  control={control}
                  ruleSetId={ruleId}
                  isReadOnly={isLive || isRuleReadOnly}
                  isClone={isClone}
                  discardRule={async () => {
                    if (!_isNil(id)) {
                      const checksum =
                        handleGetCheckSumByEntityName('rule') ?? '';

                      try {
                        await discardRuleById({ variables: { id, checksum } });
                        toasts.success(
                          'Rule reverted to live version successfully',
                          'success'
                        );
                        setEnableAutoSave(false);
                      } catch (err) {}
                    }
                  }}
                  setValue={setValue}
                  handleFetchRule={handleFetchRule}
                />
              ) : (
                <Inline justify="center">
                  <Spinner size="small" />
                </Inline>
              )}
            </TabPanel>
            <TabPanel>Summary</TabPanel>
          </TabPanels>
        </Tabs>

        <RuleSheetFooter
          isLive={isLive}
          isRuleCreating={isRuleCreating}
          isRuleUpdating={isRuleUpdating}
          ruleEnvironment={ruleSetEnvironment}
          submitAndTestForm={submitAndTestForm}
          id={id}
          isClone={isClone}
          ruleName={ruleSetRuleName}
          onPublishFail={onPublishFail}
          status={statusValue}
          setValue={setValue}
          handleEditButton={handleEditButton}
          isTesting={isTesting}
        />
      </CreateRuleContainer>
    </form>
  );
}
