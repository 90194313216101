import { gql, useLazyQuery } from '@apollo/client';

export const getRuleById = gql`
  query getRule($id: String, $live: Boolean, $filters: QueryOperators) {
    getRule(id: $id, live: $live, filters: $filters) {
      data {
        id
        name
        version
        description
        isDemo
        workspaceId
        type
        staticUrl
        policy
        checksum
        dataSetId
        firstCustomInput
        warnings
        decisionTable {
          firstProperty
          firstRow
          firstResult
          firstAggOutputData
          properties
          rows
          results
        }
        datasetDetail {
          connector {
            id
          }
        }
        production {
          startAt
          endAt
          isEnabled
          auditIO
          api {
            isEnabled
            isPrivate
            authId
          }
          salience
          schedule {
            startAt
            endAt
            inputParam
            unit
            spec
            timezone
            isEnabled
          }
        }
        staging {
          startAt
          endAt
          isEnabled
          auditIO
          api {
            isEnabled
            isPrivate
            authId
          }
          salience
        }
        conditions {
          startNode
          nodes
        }
        action {
          then {
            outputData
            firstOutputData
            firstActionNode
            actionNode
          }
          else {
            outputData
            firstOutputData
            actionNode
          }
        }
        customInput
        createdAt
        publishedAt
        status
        isLive
        approvalInfo {
          title
          description
          approvers
        }
        versionInfo {
          currentVersion
          currentLiveVersion
          lastVersion
        }
        dependencyMap {
          id
          name
          type
          version
          commitTitle
          subType
          nodeId
        }
      }
    }
  }
`;

export function useGetDecisionTableById() {
  return useLazyQuery(getRuleById, {
    fetchPolicy: 'no-cache',
  });
}
