import { gql, useLazyQuery } from '@apollo/client';

const workflowByIdQuery = gql`
  query getWorkflow($id: String, $live: Boolean, $filters: QueryOperators) {
    getWorkflow(id: $id, live: $live, filters: $filters) {
      data {
        id
        name
        version
        description
        checksum
        status
        isLive
        startNode
        approvalInfo {
          title
          description
          approvers
        }
        versionInfo {
          currentVersion
          currentLiveVersion
          lastVersion
        }
        staticUrl
        createdAt
        publishedAt
        isEnabled
        staging {
          auditIO
        }
        production {
          auditIO
        }
        nodes {
          draggable
          id
          position
          style
          sourcePosition
          targetPosition
          type
          data {
            input
            output
            settings
            executedValue
            conditions
            switcher {
              pathId
              name
            }
            nodeType
            entity
            entityId
            name
            blockName
            runInLoop {
              status
              attribute
              source
            }
            sourceIds
            isMergeNode
            rootId
            status
          }
        }
        edges {
          style
          id
          markerEnd
          label
          source
          target
          animated
          sourceHandle
          type
          data {
            edgeType
            pathId
          }
        }
        dependencyMap {
          id
          name
          type
          version
          commitTitle
          subType
          nodeId
        }
      }
    }
  }
`;

export function useGetWorkflowById() {
  return useLazyQuery(workflowByIdQuery, {
    fetchPolicy: 'no-cache',
  });
}
