import { ApolloError } from '@apollo/client';
import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FaArrowDownLong } from 'react-icons/fa6';
import { IoMdArrowDropdown } from 'react-icons/io';
import {
  Button,
  PopoverMethods,
  PopoverPanel,
  PublishedStatusType,
  Spinner,
  Typography,
  useLayer,
} from 'ui';

import { getMethodNameForApi, showGraphQlErrorToast } from '../../utils/common';
import { EnvironmentText } from '../EntityDependencyUsage/components/CommonStyles.styled';
import { CurrVersionContainer } from '../EntityDependencyUsage/components/EntityUsedByCard/EntityUsedByCard.styled';
import { AutoSelectVersionWarningModal } from '../Modals/AutoSelectVersionWarningModal/AutoSelectVerisonWarningModal';
import { getEntityTypeForApi } from '../Modals/utils/common';
import { RefreshIconComponent } from '../RefreshIconComponent/RefreshIconComponent';
import type {
  EntityInfoType,
  PaginationType,
} from '../VersionControl/VersionControl';
import { useGetVersionList } from '../VersionControl/hooks/graphql/useGetVersionList';
import { StackAsItem } from '../layouts/Stack.styled';
import {
  LauncherContainer,
  SelectVersionContainer,
  VersionItem,
} from './EntityVersionSelection.styled';

type VersionMappingType = {
  status: PublishedStatusType;
  version?: string;
  commitTitle: string;
};

type LauncherSize = 'small' | 'medium';

type EntityVersionSelectionProps = {
  entityInfo: EntityInfoType;
  selectedVersion?: string;
  selectedStatus?: string;
  onLoadGetData?: boolean;
  launcherSize?: LauncherSize;
  updateDataOnParent?: (data: Record<string, any>) => void;
  autoSelectVersionInfo?: {
    liveVersion: string;
  };
  isReadOnly?: boolean;
  showError?: boolean;
  isRefresh?: boolean;
  setIsRefresh?: (data: boolean) => void;
  showRefreshBtn?: boolean;
};

const pageSize = 10;

export function EntityVersionSelection({
  entityInfo,
  selectedVersion,
  selectedStatus,
  onLoadGetData = true,
  launcherSize = 'small',
  updateDataOnParent,
  isReadOnly = true,
  showError = false,
  isRefresh = false,
  setIsRefresh,
  showRefreshBtn = false,
}: EntityVersionSelectionProps) {
  const ref = useRef<PopoverMethods>(null);

  const { type, id } = entityInfo;

  const [getInitialData, setGetInitialData] = useState(onLoadGetData);
  const [isLoading, setIsLoading] = useState(false);
  const [canAppendData, setCanAppendData] = useState(false);
  const [currentLiveVersion, setCurrentLiveVersion] = useState<string>();

  const [isRefreshLocal, setIsRefreshLocal] = useState(false);

  const [paginationInfo, setPaginationInfo] = useState<PaginationType>();
  const [versionListData, setVersionListData] = useState<VersionMappingType[]>(
    []
  );

  const { openWithProps: openAutoSelectWarningModal } = useLayer(
    <AutoSelectVersionWarningModal entityType={type} />
  );

  const showLoadMoreBtn =
    !_isNil(paginationInfo) &&
    paginationInfo.currentPage < paginationInfo.totalPage;

  const [getVersionListQuery, { data, error }] = useGetVersionList(type);

  const methodName = useMemo(() => getMethodNameForApi(type), [type]);

  useEffect(() => {
    if (!_isNil(id) && !_isEmpty(id) && getInitialData) {
      void handleGetInitialData();
    }
  }, [id, getInitialData]);

  useEffect(() => {
    if (!_isNil(id) && !_isEmpty(id) && isRefreshLocal) {
      void handleGetInitialData();
      setIsRefreshLocal(false);
    }
  }, [isRefreshLocal, id]);

  useEffect(() => {
    if (!_isNil(id) && !_isEmpty(id) && isRefresh) {
      void handleGetInitialData();

      if (typeof setIsRefresh === 'function') setIsRefresh(false);
    }
  }, [id, isRefresh]);

  useEffect(() => {
    setGetInitialData(onLoadGetData);
  }, [onLoadGetData]);

  useEffect(() => {
    if (!_isNil(data) && !_isNil(methodName) && !_isNil(data[methodName])) {
      const defaultVersion: string = 'draft';

      const versionListApi: VersionMappingType[] =
        data[methodName]?.data.map((currObj: Record<string, any>) => {
          if (currObj.status === 'published' && (currObj.isLive as boolean)) {
            setCurrentLiveVersion(currObj.version);
            // As of now draft version is selected by default
            // defaultVersion = currObj.version;
          }

          return {
            status: currObj.status,
            version: currObj?.version ?? '',
            commitTitle: currObj?.approvalInfo?.title ?? '',
          };
        }) ?? [];

      setVersionListData([
        ...(canAppendData ? versionListData : []),
        ...versionListApi,
      ]);

      if (typeof updateDataOnParent === 'function' && !canAppendData) {
        updateDataOnParent({
          selectedVersion: {
            id,
            version: selectedVersion ?? defaultVersion,
          },
        });
      }

      setPaginationInfo(data[methodName].paginationInfo);
    }
  }, [data]);

  useEffect(() => {
    if (!_isNil(error) && error instanceof ApolloError) {
      showGraphQlErrorToast(error);
      setVersionListData([]);
    }
  }, [error]);

  const handleGetInitialData = async () => {
    const payload = {
      id,
      entityType: getEntityTypeForApi(type),
      page: 1,
      perPage: pageSize,
      live: true,
      filters: {
        in: {
          status: ['published'],
        },
      },
      sort: {
        updatedAt: -1,
      },
    };
    setCanAppendData(false);
    setIsLoading(true);

    await getVersionListQuery({
      variables: payload,
      fetchPolicy: 'no-cache',
    });

    setIsLoading(false);
  };

  const handleLoadMoreData = async () => {
    const payload = {
      id,
      live: true,
      page: (paginationInfo?.currentPage ?? 0) + 1,
      filters: {
        in: {
          status: ['published'],
        },
      },
      perPage: pageSize,
    };

    setCanAppendData(true);
    setIsLoading(true);

    await getVersionListQuery({
      variables: payload,
      fetchPolicy: 'no-cache',
    });

    setIsLoading(false);
  };

  const handleAutoSelection = () => {
    if (typeof updateDataOnParent === 'function') {
      updateDataOnParent({
        selectedVersion: {
          id,
          version: 'live',
        },
      });
    }
    ref?.current?.hide();
  };

  const handleDraftVersionSelection = () => {
    if (typeof updateDataOnParent === 'function') {
      updateDataOnParent({
        selectedVersion: {
          id,
          version: 'draft',
          commitTitle: 'draft',
          status: 'draft',
        },
      });
    }
    ref?.current?.hide();
  };

  const Launcher = () => {
    const version =
      !_isNil(selectedVersion) && !_isEmpty(selectedVersion)
        ? selectedVersion
        : 'draft';

    const env = version === 'draft' ? 'staging' : 'production';

    return (
      <LauncherContainer
        size={launcherSize}
        $showError={showError}
        version={version}
        onClick={() => setGetInitialData(true)}
      >
        <Typography fontWeight={700} className="text-styled">
          {version}
        </Typography>
        <EnvironmentText env={env} fontWeight={700}>
          {env}
        </EnvironmentText>
        <IoMdArrowDropdown />
      </LauncherContainer>
    );
  };

  return (
    <Inline align="center" gutter="1rem">
      <PopoverPanel
        ref={ref}
        padding={5}
        placement="bottom-start"
        trigger="click"
        disabled={isReadOnly}
        launcher={
          <span>
            <Launcher />
          </span>
        }
      >
        <SelectVersionContainer gutter={0}>
          <VersionItem
            $isSelected={selectedVersion === 'draft'}
            onClick={handleDraftVersionSelection}
          >
            <CurrVersionContainer className="curr-version-cont" env={'staging'}>
              <div className="version-text-styled">draft</div>
            </CurrVersionContainer>
            <Typography className="draft-name-styled" name="secondaryXs">
              {'draft'}
            </Typography>
            <EnvironmentText env={'staging'} fontWeight={700}>
              staging
            </EnvironmentText>
          </VersionItem>

          {!_isNil(currentLiveVersion) && !isLoading && (
            <VersionItem
              $isSelected={selectedVersion === 'live'}
              onClick={() => {
                openAutoSelectWarningModal({
                  handleAutoSelection,
                });
              }}
            >
              <div className="auto-select-version-cont">
                <div className="auto-selection-styled">
                  <div>Latest live version</div>

                  <div>({currentLiveVersion})</div>
                </div>
              </div>

              <EnvironmentText env={'production'} fontWeight={700}>
                production
              </EnvironmentText>
            </VersionItem>
          )}

          {versionListData.length > 0 &&
            !isLoading &&
            versionListData.map((currVersionInfo, index) => {
              const { status, version, commitTitle } = currVersionInfo;

              const env =
                _isNil(version) || _isEmpty(version) || version === 'draft'
                  ? 'staging'
                  : 'production';

              return (
                <VersionItem
                  key={index}
                  $isSelected={selectedVersion === version}
                  onClick={() => {
                    if (typeof updateDataOnParent === 'function') {
                      updateDataOnParent({
                        selectedVersion: {
                          id,
                          version,
                          commitTitle,
                          status,
                        },
                      });
                    }
                    ref?.current?.hide();
                  }}
                >
                  <CurrVersionContainer className="curr-version-cont" env={env}>
                    <div className="version-text-styled">{version}</div>
                  </CurrVersionContainer>

                  <Typography className="draft-name-styled" name="secondaryXs">
                    {commitTitle}
                  </Typography>

                  <EnvironmentText env={env} fontWeight={700}>
                    {env}
                  </EnvironmentText>
                </VersionItem>
              );
            })}

          {showLoadMoreBtn && !isLoading && (
            <VersionItem $showHoverStyle={false}>
              <StackAsItem grow={1}>
                <Button
                  appearance="filled"
                  leadingIcon={<FaArrowDownLong size={12} />}
                  onClick={handleLoadMoreData}
                >
                  Click here to load more
                </Button>
              </StackAsItem>
            </VersionItem>
          )}

          {isLoading && (
            <Inline justify="center">
              <Spinner size="extraSmall" />
            </Inline>
          )}
        </SelectVersionContainer>
      </PopoverPanel>
      {showRefreshBtn && (
        <RefreshIconComponent
          isLoading={isLoading}
          isReadOnly={isReadOnly}
          onClick={() => setIsRefreshLocal(true)}
        />
      )}
    </Inline>
  );
}
