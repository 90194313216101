import styled from 'styled-components';

export const EditorPanelContainer = styled.div<{ $hasMarginRight?: boolean }>`
  overflow: auto;
  block-size: inherit;
  inline-size: 70%;
  display: flex;
  flex: 1;
  position: relative;

  ${({ $hasMarginRight = true }) => $hasMarginRight && 'margin-right: 0.5rem;'}
`;

export const EditorContainerStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DynamicPanelContainer = styled.div<{ activePanel?: string }>`
  inline-size: 25%;
  max-inline-size: 45rem;
  block-size: inherit;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.15) -4px 0px 5px;
  z-index: 1;

  ${({ activePanel = '' }) =>
    activePanel === 'versionControl' && 'position: relative;'}

  @media screen and (min-width: 1500px) {
    inline-size: 25%;
  }
`;

export const SidePanelContainer = styled.div`
  block-size: inherit;
  inline-size: 5%;
  max-inline-size: 4.5rem;
  min-inline-size: 5.5rem;
  border-inline-start: 1px solid var(--color-darkGray);
`;
