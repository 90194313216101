import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useMemo, useState } from 'react';
import {
  DataTypes,
  type Dataset,
  getDataTypeNected,
  getObjectUnion,
  toasts,
} from 'ui';

import { useSendEventToGTM } from '../../../hooks/useSendEventToGTM';
import { getPropertyIfExists } from '../../../utils/common';
import { TESTABLE_NODES } from '../../../utils/constant';
import { AttributeModel } from '../../Rules/models';
import { getUpdatedTokens } from '../../Rules/utils/common';
import {
  changedNodeIdsAtom,
  versionMappingWfInfoAtom,
  workflowEdgesAtom,
  workflowIdAtom,
  workflowNodesAtom,
  workflowStatusAtom,
} from '../atoms/atoms';
import { RunInLoopType } from '../models/models';
import {
  formatRuleAttributes,
  getAllPredecessorsSkippingType,
  getDirectParentsSkippingType,
  getExecutedValueAndStatus,
  getExecutedValueOfUsedAttributes,
  transformRuleAttributes,
} from '../utils/common';
import { statusListForDataUpdate } from '../utils/constant';
import { useGetWorkflowById } from './graphql/useGetWorkflowById';
import { useTestWorkflow } from './restApi/useTestWorkflow';
import { useGetDataset } from './useGetDataset';
import { WorkflowNodeType } from './useOpenWorkflow';

type UseTestWorkflowNodeProps = {
  localData: Record<string, any>;
  id: string;
  type: string;
};

export function useTestWorkflowNode({
  localData,
  id,
  type,
}: UseTestWorkflowNodeProps) {
  const [getWorkflowById] = useGetWorkflowById();
  const {
    testWorkflow,
    data: workflowData,
    error: workflowError,
  } = useTestWorkflow();
  const [isTesting, setIsTesting] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string>(
    localData.status ?? ''
  );

  const runInLoop: RunInLoopType = localData.runInLoop;

  const [attributes, setAttributes] = useState<any[]>([]);
  const [currentWorkflow, setCurrentWorkflow] = useState<{
    name: string;
    link: string;
  }>({
    name: '',
    link: '',
  });
  const [parentNodes, setParentNodes] = useState<WorkflowNodeType[]>([]);
  const [directParents, setDirectParents] = useState<WorkflowNodeType[]>([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [tokens, setTokens] = useState<string[]>([]);

  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);

  const [currentCustomInputs, setCurrentCustomInputs] = useState<
    Record<string, AttributeModel>
  >({});

  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [workflowStatus] = useAtom(workflowStatusAtom);

  const [workflowId] = useAtom(workflowIdAtom);

  const [versionMappingInfo] = useAtom(versionMappingWfInfoAtom);

  const currNodeVersionMapping = versionMappingInfo?.find(
    (currMapping) => currMapping.nodeId === id
  );

  const version = currNodeVersionMapping?.version;

  const { sendEventToGTM } = useSendEventToGTM();
  const { updatedDataSet } = useGetDataset({ parentNodes });

  const finalDataSet: Record<string, Dataset> = useMemo(() => {
    if (runInLoop?.status) {
      const parentData = getPropertyIfExists(
        JSON.parse(
          JSON.stringify(
            parentNodes.find((p) => p.data.name === runInLoop.source)?.data
              ?.executedValue ?? {}
          )
        ),
        runInLoop.attribute
      );

      return {
        ...updatedDataSet,
        loop: {
          name: 'Loop',
          attributes: {
            currentItem: {
              dataType: getDataTypeNected(
                getObjectUnion(parentData ?? [])
              ) as DataTypes,
              name: 'currentItem',
              executedValue: getObjectUnion(parentData ?? []),
            },
          },
          id: 'currentItem',
        },
      };
    }

    return updatedDataSet;
  }, [updatedDataSet, JSON.stringify(runInLoop), parentNodes.length]);

  const handleGetWorkflowProps = async (
    id: string = '',
    isSetValue: boolean = false
  ) => {
    if (_isEmpty(id)) {
      return [];
    }

    const version = currNodeVersionMapping?.version ?? '';

    const filters: Record<string, any> = {};

    if (!['live', 'draft'].includes(version)) {
      filters.eq = { version };
    }

    try {
      const data = await getWorkflowById({
        variables: {
          id,
          live: version !== 'draft',
          filters,
        },
        fetchPolicy: 'no-cache',
      });

      if (!_isNil(data.data.getWorkflow.data[0])) {
        const triggerNode: WorkflowNodeType | undefined =
          data.data.getWorkflow.data[0].nodes.find(
            (n: WorkflowNodeType) => n.type === 'trigger'
          );

        if (!_isNil(triggerNode)) {
          setCurrentCustomInputs(triggerNode.data.input ?? {});

          setAttributes(
            formatRuleAttributes(triggerNode.data.input, localData?.input)
          );

          updateWfAttributesInNode(triggerNode.data.input ?? {});
        }
      }

      if (
        !_isNil(data.data.getWorkflow.data[0]?.name) &&
        !_isNil(data.data.getWorkflow.data[0]?.id)
      ) {
        let versionInfoLink = '';

        if (!_isNil(version) && !_isEmpty(version) && version !== 'draft') {
          versionInfoLink += `&type=view&isLive=true`;

          if (version !== 'live') {
            versionInfoLink += `&version=${version}`;
          }
        } else if (version === 'draft') {
          versionInfoLink += `&type=edit`;
        }

        setCurrentWorkflow({
          name: data.data.getWorkflow?.data[0]?.name,
          link: `workflow/${
            data.data.getWorkflow?.data[0]?.id as string
          }?stage=staging&wsid=${
            sessionStorage.getItem('workspaceUUID') as string
          }${versionInfoLink}`,
        });
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('error', error);
    }
  };

  const testWorkflowData = async (attr?: any[]) => {
    sendEventToGTM({
      event: 'workflow',
      source: 'listing',
      element: type,
      action: 'test_click',
      type: localData?.nodeType,
    });

    if (
      parentNodes.find(
        (node) =>
          TESTABLE_NODES.includes(node.type ?? '') &&
          node.data.status !== 'success'
      ) != null
    ) {
      setIsTesting(false);

      toasts.error(
        'Previous node(s) are  not tested. Please test the previous node(s) before proceeding',
        'parent-node'
      );

      return;
    }

    const attributeObj: Record<string, any> = _reduce(
      attr ?? attributes,
      (finalObj, currAttribute) => {
        return {
          ...finalObj,
          [currAttribute.keyName]: currAttribute,
        };
      },
      {}
    );

    const params: Record<string, any> = getExecutedValueOfUsedAttributes(
      attributeObj,
      updatedDataSet,
      false
    );

    if (runInLoop?.status) {
      const mappedData = getPropertyIfExists(
        JSON.parse(
          JSON.stringify(
            parentNodes.find((p) => p.data.name === runInLoop?.source)?.data
              ?.executedValue ?? {}
          )
        ),
        runInLoop?.attribute ?? ''
      );

      params.loop = mappedData;

      if (runInLoop?.source === 'globalVar') {
        const mappedData = getPropertyIfExists(
          JSON.parse(
            JSON.stringify(
              Object.keys(updatedDataSet?.globalVar?.attributes ?? {}).reduce(
                (acc, curr) => {
                  return {
                    ...acc,
                    [curr]:
                      updatedDataSet.globalVar.attributes[`${curr}`]
                        .executedValue,
                  };
                },
                {}
              )
            )
          ) ?? {},
          runInLoop?.attribute ?? ''
        );

        params.loop = mappedData;
      }
    }

    try {
      await testWorkflow({
        id: workflowId ?? '',
        nodeId: id,
        params,
      });
    } catch (error) {
      if (error instanceof Error) {
        toasts.error(error.message, 'node-test-fail');
      }
    }
  };

  useEffect(() => {
    void handleGetWorkflowProps(localData?.entityId, true);
  }, [JSON.stringify(localData), JSON.stringify(currNodeVersionMapping)]);

  useEffect(() => {
    if (
      !_isEmpty(currentWorkflow.link) &&
      !_isNil(version) &&
      !_isEmpty(version) &&
      version !== 'draft'
    ) {
      setCurrentWorkflow((prev) => ({
        ...prev,
        link:
          prev.link +
          `&isLive=true${version !== 'live' ? `&version=${version}` : ''}`,
      }));
    }
  }, [version]);

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const updateWfAttributesInNode = (attributes: Record<string, any>) => {
    if (!_isNil(localData.onWorkflowNodeChange) && !_isNil(workflowNode)) {
      let newWorkflowNode = { ...workflowNode };

      newWorkflowNode = {
        ...newWorkflowNode,
        data: {
          ...newWorkflowNode.data,
          input: transformRuleAttributes(
            formatRuleAttributes(attributes, localData.input)
          ),
        },
      };

      localData.onWorkflowNodeChange(newWorkflowNode);
    }
  };

  useEffect(() => {
    if (!_isNil(workflowData)) {
      if (
        !_isNil(workflowNode) &&
        statusListForDataUpdate.includes(workflowStatus)
      ) {
        const newWorkflowNode = workflowNode;
        const entity = getExecutedValueAndStatus(workflowData);

        newWorkflowNode.data.status = entity.status;

        newWorkflowNode.data.executedValue = entity.executedValue;

        setChangedNodeIds([]);

        setCurrentStatus(entity.status);

        if (entity.status !== 'error') {
          toasts.success('Node tested successfully', 'node-test-success');
        } else {
          toasts.error('Node test failed', 'node-test-fail');
        }

        setTimeout(() => {
          localData.onWorkflowNodeChange(workflowNode);
        }, 100);
      }

      setCurrentTab(0);
      setCurrentTab(1);
      setIsTesting(false);
    }
  }, [workflowData]);

  useEffect(() => {
    if (!_isNil(workflowError)) {
      setIsTesting(false);
    }
  }, [workflowError]);

  useEffect(() => {
    setTokens(getUpdatedTokens(updatedDataSet));
  }, [updatedDataSet]);

  useEffect(() => {
    setParentNodes(
      getAllPredecessorsSkippingType(
        id,
        workflowNodes,
        workflowEdges,
        'addNode'
      )
    );

    setDirectParents(
      getDirectParentsSkippingType(workflowNodes, workflowEdges, id, 'addNode')
    );
  }, [JSON.stringify(workflowNodes)]);

  return {
    parentNodes,
    updatedDataSet,
    finalDataSet,
    attributes,
    isTesting,
    setIsTesting,
    currentTab,
    currentWorkflow,
    testWorkflowData,
    setCurrentTab,
    setCurrentStatus,
    currentStatus,
    currentCustomInputs,
    workflowNode,
    workflowData,
    workflowError,
    tokens,
    setAttributes,
    directParents,
  };
}
