import type {
  UseControllerProps,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { Dataset, NectedSuggestionModel } from 'ui';

import { permissionObj } from '../PermissionComponent/constant';
import { useCheckPermissions } from '../PermissionComponent/hooks/useCheckPermissions';
import { FieldConfigWrapper } from './FieldConfigWrapper';
import { FormContainer } from './form.styled';
import { FormFields } from './types';

type FormProps = UseControllerProps<any> & {
  formFields: FormFields[];
  entity: string;
  watch: UseFormWatch<Record<string, any>>;
  entityStatus?: string;
  formKeyPrefix?: string;
  getDropdownOptions?: (obj?: any) => Promise<void>;
  newCustomSuggestions?: NectedSuggestionModel[];
  footerComponent?: (id: string) => JSX.Element | undefined;
  parentFormData?: Record<string, any>;
  formJson?: Record<string, any>;
  setValue?: UseFormSetValue<any>;
  handleGetExecutionValues?: (data: Record<string, any>) => void;
  execValues?: Record<string, any>;
  dataSet?: Record<string, Dataset>;
};

export function Form({
  formFields,
  entity,
  watch,
  control,
  entityStatus,
  formKeyPrefix = '',
  footerComponent,
  getDropdownOptions = async () => {},
  newCustomSuggestions = [],
  parentFormData = {},
  formJson = {},
  setValue,
  handleGetExecutionValues,
  execValues,
  dataSet,
}: FormProps) {
  const { isHide } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [entity],
    entityStatus,
  });

  return (
    <FormContainer>
      {formFields.map((field) => {
        const { key } = field;

        return (
          <FieldConfigWrapper
            key={key}
            field={field}
            control={control}
            footerComponent={footerComponent}
            isPermissionDisabled={isHide}
            watch={watch}
            getDropdownOptions={getDropdownOptions}
            newCustomSuggestions={newCustomSuggestions}
            parentFormData={parentFormData}
            formJson={formJson}
            setValue={setValue}
            formKeyPrefix={formKeyPrefix}
            handleGetExecutionValues={handleGetExecutionValues}
            execValues={execValues}
            dataSet={dataSet}
          />
        );
      })}
    </FormContainer>
  );
}
