import { Inline, Stack } from '@bedrock-layout/primitives';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { Control } from 'react-hook-form';
import { BiSolidError } from 'react-icons/bi';
import { FiRefreshCcw } from 'react-icons/fi';
import { MdOutlineEdit } from 'react-icons/md';
import { DropdownField, IconButton, Image, TooltipReact, Typography } from 'ui';

import { siteConstantsAtom, usedConnectorMappingAtom } from '../../atom';
import { useUpdateMappedConnectorsData } from '../../hooks/useUpdateMappedConnectorsData';
import { getTooltipText } from '../../utils/common';
import { envMap } from '../../utils/constant';
import {
  ActionIcon,
  CreateConnector,
  ErrorIcon,
} from './IntegrationField.styled';

type RotateIconButtonProps = {
  isLoading: boolean;
  onClick: () => void;
};

type IntegrationFieldProps = {
  control?: Control<any>;
  connectorId?: string;
  connectorList?: any[];
  editPlugin: () => void;
  refreshPlugins: () => void;
  pluginId?: string;
  name: string;
  disabled?: boolean;
  showEdit?: boolean;
  showRefresh?: boolean;
  onSelect?: (newValue: any, oldValue?: any) => void;
  required?: boolean;
};

export function IntegrationField({
  control,
  connectorId = '',
  pluginId = '',
  connectorList = [],
  refreshPlugins,
  editPlugin,
  name,
  disabled = false,
  showEdit = true,
  showRefresh = true,
  onSelect,
  required = false,
}: IntegrationFieldProps) {
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [usedConnectorMapping] = useAtom(usedConnectorMappingAtom);

  const { fetchMappedConnectorsData, isLoading } =
    useUpdateMappedConnectorsData();

  const hasError = usedConnectorMapping?.[connectorId]?.status === false;

  const MenuListFooter = () => (
    <CreateConnector
      onClick={() => {
        window.open(`${window.location.origin}/integrations/${pluginId}`);
      }}
      gutter={8}
    >
      <Image
        src={`${envMap.VITE_ASSETS_URL}website/icons/plus.svg`}
        alt="plus"
        size="xs"
      />

      <Typography>Create Connector</Typography>
    </CreateConnector>
  );

  const RotateIconButton = ({ isLoading, onClick }: RotateIconButtonProps) => {
    return (
      <motion.div
        animate={{
          rotate: isLoading ? 360 : 0,
        }}
        transition={{
          duration: isLoading ? 2 : 0,
          repeat: isLoading ? Infinity : 0,
          ease: 'linear',
        }}
      >
        <IconButton onClick={onClick}>
          <FiRefreshCcw />
        </IconButton>
      </motion.div>
    );
  };

  return (
    <Stack gutter={4}>
      <Inline gutter={8}>
        <Typography fontWeight={700}>Integration</Typography>
      </Inline>

      <Inline gutter={8} stretch="start" align="start">
        <div>
          <DropdownField
            control={control}
            name={name}
            options={connectorList}
            MenuListFooter={<MenuListFooter />}
            disabled={disabled}
            onSelect={onSelect}
            rules={{
              required: {
                value: required,
                message: 'Please select integration',
              },
            }}
          />
        </div>

        {showEdit && (
          <ActionIcon type="button" onClick={editPlugin}>
            <MdOutlineEdit />
            {hasError && (
              <ErrorIcon>
                <TooltipReact
                  id={`connector_error_${name}`}
                  launcher={
                    <BiSolidError
                      size={18}
                      color="var(--color-fireEngineRed)"
                    />
                  }
                >
                  <Typography>
                    {getTooltipText(
                      siteConstants,
                      'integrations',
                      'integrationNotConnected'
                    )}
                  </Typography>
                </TooltipReact>
              </ErrorIcon>
            )}
          </ActionIcon>
        )}

        {showRefresh && (
          <ActionIcon type="button">
            <RotateIconButton
              isLoading={isLoading}
              onClick={() => {
                void fetchMappedConnectorsData();

                if (!disabled) {
                  void refreshPlugins();
                }
              }}
            />
          </ActionIcon>
        )}
      </Inline>
    </Stack>
  );
}
