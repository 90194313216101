import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { Dispatch, useEffect, useState } from 'react';
import {
  Control,
  UseFormSetValue,
  UseFormWatch,
  useWatch,
} from 'react-hook-form';
import {
  Attributes,
  Dataset,
  DropdownField,
  EditorLanguages,
  Image,
  NectedSuggestionModel,
  OnClickRuleArgs,
  TooltipReact,
  Typography,
  toasts,
} from 'ui';

import {
  siteConstantsAtom,
  usedConnectorMappingAtom,
} from '../../../../../../atom';
import { GSheetColumnPopover } from '../../../../../../components/Form/components/GSheetColumnPopover/GSheetColumnPopover';
import { WebGSheetPickerField } from '../../../../../../components/Form/components/WebGSheetPickerField';
import { IntegrationField } from '../../../../../../components/IntegrationField/IntegrationField';
import { useAxiosPrivate } from '../../../../../../hooks';
import {
  generateUid,
  getDataTypesByAllowedTypes,
  getTooltipText,
  isValidImageURL,
} from '../../../../../../utils/common';
import {
  GSHEET_ACTION_METHODS,
  GSHEET_HEADERS_SELECTION,
  GSHEET_MAPPING_METHODS,
  GSHEET_UPDATING_METHODS,
} from '../../../../../../utils/constant';
import { useGetPublishedConnectors } from '../../../../../DataSets/hooks/useGetPublishedConnectors';
import { PluginDropDownField } from '../../../../../Integrations/components/connector/form/fields/PluginDropdownField';
import { ResultHeader } from '../../../../../Rules/components/DecisionTable/components/ResultHeader';
import { PublishedConnectors } from '../../../../../Rules/components/DecisionTable/types';
import { ResultRhs } from '../../../../../Rules/components/RestltRhs/ResultRhs';
import { getGroupNode } from '../../../../../Rules/utils/common';
import {
  isWorkflowReadOnlyAtom,
  workflowEnvironmentAtom,
} from '../../../../atoms/atoms';
import { getBlankConditions } from '../../../../utils/common';
import { PathBlock } from '../ConditionsBlock/PathBlock';
import { LinkStyled, PluginContainer } from './GSheetMapping.styled';

type GSheetMappingProps = {
  tokens: NectedSuggestionModel[];
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  connectorData?: any;
  localData: any;
  updatedDataSet?: Record<string, Dataset>;
  setColumns: Dispatch<any>;
  columns: any[];
};

export function GSheetMapping({
  tokens,
  control,
  setValue,
  watch,
  connectorData,
  localData,
  updatedDataSet = {},
  columns,
  setColumns,
}: GSheetMappingProps) {
  const { data, refetch } = useGetPublishedConnectors(false);
  const [connectorList, setConnectorList] = useState<any>();
  const [, setBodyEditorMode] = useState<EditorLanguages>('text');
  const [results, setResults] = useState<any>([]);

  const [publishConnectorbyId, setPublishConnectors] =
    useState<PublishedConnectors>({});

  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [workflowEnvironment] = useAtom(workflowEnvironmentAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [, setUsedConnectorMapping] = useAtom(usedConnectorMappingAtom);

  const { axiosPrivate } = useAxiosPrivate();

  const getSheetLists = async () => {
    try {
      const response = await axiosPrivate.post(
        // eslint-disable-next-line
        `/integration/connector/execute?fileId=${selectedFile?.value}`,
        {
          id: selectedConnector?.value,
          environment: 'staging',
          method: 'getSheets',
          params: {
            cr: false,
          },
        },
        {}
      );

      // eslint-disable-next-line
      if (response?.data.code === 'success') {
        setResults(response?.data?.data.result);
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts.error(err.message, 'get-schema-error');
      }
    }
  };

  const getSheetColumnLists = async () => {
    try {
      const payload = {
        id: selectedConnector?.value,
        environment: 'staging',
        method: 'getColumns',
        params: {
          cr: false,
          file: selectedFile,
          sheet: selectedSheet,
        },
      };

      const response = await axiosPrivate.post(
        `/integration/connector/execute`,
        payload,
        {}
      );

      if (response?.data.code === 'success') {
        setValue('columnData', response.data?.data?.result ?? {});

        if (hasHeader?.value === 'yes') {
          setColumns(() => {
            const cols = [
              ...Object.keys(response.data?.data?.result ?? {}).map((k) => {
                return {
                  label:
                    response.data?.data?.result[k].headerColumnName ??
                    response.data?.data?.result[k].columnName,
                  value:
                    response.data?.data?.result[k].headerColumnName ??
                    response.data?.data?.result[k].columnName,
                  dataType: response.data?.data?.result[k].dataType,
                };
              }),
            ];

            if (watch('actionMethod') === 'update') {
              cols.push({
                label: 'ROW_NUMBER',
                value: 'ROW_NUMBER',
                dataType: 'numeric',
              });
            }

            return cols;
          });
        } else {
          setColumns(() => {
            const cols = [
              ...Object.keys(response.data?.data?.result ?? {}).map((k) => {
                return {
                  label: k,
                  value: k,
                  dataType: response.data?.data?.result[k].dataType,
                };
              }),
            ];

            if (watch('actionMethod') === 'update') {
              cols.push({
                label: 'ROW_NUMBER',
                value: 'ROW_NUMBER',
                dataType: 'numeric',
              });
            }

            return cols;
          });
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts.error(err.message, 'get-schema-error');
      }
    }
  };

  const plugin = useWatch({
    name: 'plugin',
    control,
  });

  const selectedConnector = useWatch({
    name: 'integration',
    control,
  });

  const selectedSheet = useWatch({
    name: 'sheet',
    control,
  });

  const selectedFile = useWatch({
    name: 'file',
    control,
  });

  const hasHeader = useWatch({
    name: 'hasHeader',
    control,
  });

  const editPlugin = () => {
    window.open(
      `${window.location.origin}/integrations/${
        selectedConnector?.value as string
      }?stage=staging&connector=${connectorData.plugin.name as string}`
    );
  };

  useEffect(() => {
    if (
      // eslint-disable-next-line
      !_isNil(selectedFile) &&
      // eslint-disable-next-line
      !!selectedFile?.value &&
      // eslint-disable-next-line
      !_isNil(selectedConnector)
    ) {
      void getSheetLists();
    }
  }, [selectedFile, selectedConnector]);

  useEffect(() => {
    // eslint-disable-next-line
    if (!_isNil(selectedSheet) && !_isNil(selectedConnector)) {
      void getSheetColumnLists();
    }
  }, [selectedSheet, hasHeader, selectedConnector]);

  useEffect(() => {
    if (!_isEmpty(publishConnectorbyId)) {
      const id = selectedConnector?.value;

      if (!_isNil(id) && !_isEmpty(id)) {
        const currConnectorConfig = publishConnectorbyId[id];

        const connectorStatus: boolean =
          ((currConnectorConfig?.staging?.isTested as boolean) ?? true) &&
          ((currConnectorConfig?.staging?.isPublish as boolean) ?? true);

        setUsedConnectorMapping((prev) => ({
          ...prev,
          [id]: {
            status: connectorStatus,
            source: [],
          },
        }));
      }
    }
  }, [JSON.stringify(selectedConnector), JSON.stringify(publishConnectorbyId)]);

  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data ?? [];
      const publishConnectorIdAndPlugin: PublishedConnectors = {};

      if (publishedConnectors.length > 0) {
        publishedConnectors.forEach((connector) => {
          publishConnectorIdAndPlugin[connector.id] = connector;
        });
      }
      setPublishConnectors(publishConnectorIdAndPlugin);
    }
  }, [data]);

  const refreshPlugins = async () => {
    await refetch();
  };

  const formValues: any = watch();

  const contentType = formValues.input?.contentType?.value ?? null;
  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data
        .filter((connector) => connector.plugin.name === 'gsheet')
        .map((connector) => {
          return {
            label: connector.name,
            value: connector.id,
          };
        });

      setConnectorList(publishedConnectors);
    }
  }, [data]);

  useEffect(() => {
    const isJsonMode = ['application/json', 'application/javascript'].includes(
      contentType?.value
    );

    setBodyEditorMode(isJsonMode ? 'json' : 'text');
  }, [contentType]);

  const checkIfEntityIsToBeShown = (entity: string) => {
    // eslint-disable-next-line
    const actionMethod: boolean = !!watch('actionMethod');
    // eslint-disable-next-line
    const file: boolean = !!watch('file');
    // eslint-disable-next-line
    const sheet: boolean = !!watch('sheet');
    // eslint-disable-next-line
    const actionIsUpdate: boolean = watch('actionMethod')?.value === 'update';

    // eslint-disable-next-line
    const actionIsLookup: boolean = watch('actionMethod')?.value === 'lookup';
    // eslint-disable-next-line
    const isMappingManual = watch('mappingMethod')?.value === 'manual';

    // eslint-disable-next-line
    const hasHeader = watch('hasHeader')?.value;

    if (entity === 'file') {
      return actionMethod;
    } else if (entity === 'sheet') {
      return actionMethod && file;
    } else if (entity === 'hasHeader') {
      return actionMethod && file && sheet;
    } else if (entity === 'columnMatch') {
      return actionMethod && actionIsUpdate && file && sheet;
    } else if (entity === 'policy') {
      return actionMethod && actionIsUpdate && file && sheet && !actionIsLookup;
    } else if (entity === 'mappingMethod') {
      return actionMethod && file && sheet && !actionIsLookup;
    } else if (entity === 'filterList') {
      return (
        // eslint-disable-next-line
        actionMethod && file && sheet && !isMappingManual && !actionIsLookup
      );
    } else if (entity === 'filterListManual') {
      return (
        actionMethod &&
        file &&
        sheet &&
        !actionIsLookup &&
        // eslint-disable-next-line
        !!hasHeader &&
        isMappingManual
      );
    } else if (entity === 'lookupSelected') {
      return actionMethod && file && sheet && actionIsLookup;
    }

    return false;
  };

  const remove = (index: number) => {
    const paths = watch('paths').filter((p: any, i: number) => i !== index);
    setValue('paths', paths);
  };

  const suggestionsColumn = [
    ...columns.map((column: any) => ({
      meta: 'columnName',
      value: column.label,
      name: column.label,
      score: 999,
    })),
    {
      meta: 'columnName',
      value: 'ROW_NUMBER',
      name: 'ROW_NUMBER',
      score: 999,
    },
  ].filter((c) => {
    if (watch('actionMethod')?.value === 'insert') {
      return false;
    }

    return true;
  });

  const columnNameDataSet: Record<string, Dataset> = {
    columnName: {
      name: 'Column Name',
      id: 'columnName',
      attributes: columns.reduce<Record<string, Attributes>>((acc, curr) => {
        return {
          ...acc,
          [curr.label]: {
            dataType: curr.dataType,
            name: curr.label,
          },
        };
      }, {}),
    },
  };

  const resetPaths = () => {
    const groupId = generateUid('group_');
    const conditionId = generateUid('condition_');

    const groupNode = getGroupNode('', [conditionId], 'and');
    const conditionNode = getBlankConditions(conditionId, groupId);

    const paths = [{ [groupId]: groupNode, ...conditionNode }];

    setValue('paths', paths);
  };

  return (
    <Stack gutter="1.5rem">
      <PluginContainer justify="start" align="center" gap="0.8rem">
        <Image
          src={isValidImageURL(plugin?.imageUrl)}
          alt="minus"
          size="small"
          round
        />
        <Typography> {plugin?.displayName ?? ''}</Typography>
      </PluginContainer>

      <IntegrationField
        control={control}
        editPlugin={editPlugin}
        refreshPlugins={refreshPlugins}
        name="integration"
        connectorList={connectorList}
        pluginId={plugin?.id ?? ''}
        disabled={isWorkflowReadOnly}
        connectorId={selectedConnector?.value}
        required
      />

      {
        // eslint-disable-next-line
        (!!selectedConnector?.value as boolean) && (
          <>
            <Stack gutter={4}>
              <Typography fontWeight={700}>Action</Typography>
              <DropdownField
                name="actionMethod"
                control={control}
                options={GSHEET_ACTION_METHODS}
                onSelect={(nValue, oValue) => {
                  if (nValue?.value !== oValue?.value) {
                    setValue('filterList', {});
                    setValue('columnMatch', null);
                    setValue('policy', null);
                    setValue('hasHeader', null);
                    resetPaths();
                  }
                }}
              />
            </Stack>
            {checkIfEntityIsToBeShown('file') && (
              <WebGSheetPickerField
                control={control}
                name="file"
                disabled={isWorkflowReadOnly}
                label="Spreadsheet"
                type="googlePicker"
                tooltipText="Pick a file"
                required
                onPick={(nValue) => {
                  setValue('filterList', {});
                  setValue('columnMatch', null);
                  setValue('policy', null);
                  setValue('hasHeader', null);
                  resetPaths();
                }}
                // eslint-disable-next-line
                regex={new RegExp('/^[-d]+$/')}
                setValue={setValue}
                parentFormData={{
                  connectorId: selectedConnector?.value,
                  currentEnvironment: workflowEnvironment,
                }}
              />
            )}

            {checkIfEntityIsToBeShown('sheet') && (
              <PluginDropDownField
                control={control}
                name="sheet"
                disabled={isWorkflowReadOnly}
                label="Worksheet"
                type="map"
                required
                onSelect={(nValue, oldValue) => {
                  if (nValue?.value !== oldValue?.value) {
                    setValue('columnMatch', null);
                    setValue('filterList', {});
                    setValue('policy', null);
                    resetPaths();
                  }
                }}
                // eslint-disable-next-line
                regex={new RegExp('/^[-d]+$/')}
                options={results}
              />
            )}

            {checkIfEntityIsToBeShown('hasHeader') && (
              <PluginDropDownField
                control={control}
                name="hasHeader"
                disabled={isWorkflowReadOnly}
                label="Does the first row of sheet have headers?"
                onSelect={(nValue, oValue) => {
                  if (nValue?.value !== oValue?.value) {
                    setValue('columnMatch', null);
                    setValue('filterList', {});
                    setValue('policy', null);
                    resetPaths();
                  }
                }}
                type="map"
                tooltipText="First row of sheet has headers or not"
                required
                // eslint-disable-next-line
                regex={new RegExp('/^[-d]+$/')}
                options={GSHEET_HEADERS_SELECTION}
              />
            )}

            {checkIfEntityIsToBeShown('columnMatch') && (
              <PluginDropDownField
                control={control}
                name="columnMatch"
                disabled={isWorkflowReadOnly}
                label="Column to match on?"
                type="multi-select"
                isMulti
                required={watch('actionMethod')?.value === 'update'}
                // eslint-disable-next-line
                regex={new RegExp('/^[-d]+$/')}
                options={[...columns]}
              />
            )}

            {checkIfEntityIsToBeShown('policy') && (
              <PluginDropDownField
                control={control}
                name="policy"
                disabled={isWorkflowReadOnly}
                label="Update All/First Matching"
                type="map"
                required={watch('actionMethod')?.value === 'update'}
                // eslint-disable-next-line
                regex={new RegExp('/^[-d]+$/')}
                options={GSHEET_UPDATING_METHODS}
              />
            )}

            {checkIfEntityIsToBeShown('mappingMethod') && (
              <Stack gutter={4}>
                <PluginDropDownField
                  control={control}
                  name="mappingMethod"
                  disabled={isWorkflowReadOnly}
                  label="Mapping Method"
                  type="map"
                  required
                  onSelect={(nValue, oValue) => {
                    if (nValue?.value !== oValue?.value) {
                      setValue('filterList', {});
                    }
                  }}
                  // eslint-disable-next-line
                  regex={new RegExp('/^[-d]+$/')}
                  options={GSHEET_MAPPING_METHODS}
                />
              </Stack>
            )}

            {checkIfEntityIsToBeShown('filterList') && (
              <Stack gutter={4}>
                <Inline gutter={8}>
                  <Typography fontWeight={700}>Row Values</Typography>
                  <TooltipReact
                    id="action-rest-authentication"
                    placement="bottom-start"
                  >
                    <Typography>
                      Enter a list of values or select a token{' '}
                      <LinkStyled
                        to={getTooltipText(
                          siteConstants,
                          'workflow',
                          'howToAddGSheetTokens',
                          'howToLinks'
                        )}
                        target="_blank"
                      >
                        Know more
                      </LinkStyled>
                    </Typography>
                  </TooltipReact>
                </Inline>

                <ResultRhs
                  nodeName="filterList"
                  dataType="list"
                  typesToAllow={['list']}
                  updatedDataSet={updatedDataSet}
                  control={control}
                  isFull
                  disabled={isWorkflowReadOnly}
                  setValue={setValue}
                  header={
                    <ResultHeader
                      dataSet={updatedDataSet}
                      // eslint-disable-next-line
                      isAdd={!watch('filterList')?.value}
                      nodeName={`filterList`}
                      resIndex={0}
                      control={control}
                      setOriginalValue={setValue}
                      disabled={isWorkflowReadOnly}
                      returnTypeName={`filterList.returnType`}
                      executedValueName={`filterList.executedValue`}
                      editorType={'list'}
                      suggestionsObjs={suggestionsColumn}
                    />
                  }
                />

                <Typography name="secondarySm">
                  Keys of this token will be automatically matched with the
                  columns of this sheet, the row will be ignored if not matched
                </Typography>
              </Stack>
            )}

            {checkIfEntityIsToBeShown('filterListManual') && (
              <Stack gutter={8}>
                <Typography fontWeight={700}>COLUMNS</Typography>

                {columns.map((field, index) => (
                  <div key={index}>
                    <Inline gutter={8}>
                      <Typography fontWeight={700}>{field.label}</Typography>
                    </Inline>

                    <GSheetColumnPopover
                      control={control}
                      name={`filterList.value.0.${field.label as string}`}
                      onClick={(args: OnClickRuleArgs) => {
                        if (typeof setValue === 'function') {
                          if (args.key === 'nected') {
                            setValue(
                              `filterList.value.0.${field.label as string}`,
                              `<<${args.value}>>`
                            );
                          } else {
                            setValue(
                              `filterList.value.0.${field.label as string}`,
                              `{{.${args.key}.${args.value}}}`
                            );
                          }
                        }
                      }}
                      disabled={isWorkflowReadOnly}
                      typesToAllow={[
                        ...getDataTypesByAllowedTypes(field.dataType),
                        'generic',
                        'nected',
                      ]}
                      dataSet={updatedDataSet}
                    />
                  </div>
                ))}
              </Stack>
            )}

            {checkIfEntityIsToBeShown('lookupSelected') && (
              <Stack gutter="1rem">
                <Typography fontWeight={700}>Filters*</Typography>

                <PathBlock
                  name={`paths`}
                  index={0}
                  remove={remove}
                  setValue={setValue}
                  control={control}
                  watch={watch}
                  path={watch('paths.0')}
                  updatedDataSet={updatedDataSet}
                  size={1}
                  dataSetLHS={columnNameDataSet}
                />
              </Stack>
            )}
          </>
        )
      }
    </Stack>
  );
}
