import { Column, Columns, Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import {
  Control,
  UseFormSetValue,
  UseFormWatch,
  useWatch,
} from 'react-hook-form';
import {
  DropdownField,
  Image,
  NectedSuggestionModel,
  Typography,
  toasts,
} from 'ui';

import { axiosPrivate } from '../../../../../api/axios';
import { usedConnectorMappingAtom } from '../../../../../atom';
import { SyntaxErrorContainer } from '../../../../../components/ActionComponents/ActionSheet.styled';
import { IntegrationField } from '../../../../../components/IntegrationField/IntegrationField';
import { QueryEditor } from '../../../../../components/QueryEditor/QueryEditor';
import { StackAsItem } from '../../../../../components/layouts/Stack.styled';
import { isValidImageURL } from '../../../../../utils/common';
import { DataSetSchemaMemoized } from '../../../../DataSets/components/DataSetSchema';
import { useGetPublishedConnectors } from '../../../../DataSets/hooks/useGetPublishedConnectors';
import { DatabaseName, SchemasValue } from '../../../../DataSets/types';
import {
  convertResponseObjectToSchemas,
  getSuggestionsKeywordsNew,
} from '../../../../DataSets/utils';
import { PublishedConnectors } from '../../../../Rules/components/DecisionTable/types';
import { isWorkflowReadOnlyAtom } from '../../../atoms/atoms';
import { EditorContainer, PluginContainer } from './ConnectorMapping.styled';
import { queryOptions } from './constant';

type ConnectorMappingProps = {
  pluginName: string;
  category: string;
  tokens: NectedSuggestionModel[];
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  handleGetExecutionValues?: (value: Record<string, any>) => void;
  execValues?: Record<string, any>;
  connectorData?: any;
};

export function ConnectorMapping({
  pluginName = '',
  tokens,
  control,
  setValue,
  watch,
  handleGetExecutionValues,
  execValues,
  connectorData,
}: ConnectorMappingProps) {
  const { data, refetch } = useGetPublishedConnectors(true);
  const [connectorList, setConnectorList] = useState<any>();
  const [schema, setSchema] = useState<SchemasValue>({});
  const [customSuggestionObj, setCustomSuggestionObj] = useState(tokens);
  const [clearValue, setClearValue] = useState(false);
  const [defaultValue, setDefaultValue] = useState<string | null>('');

  const [publishConnectorbyId, setPublishConnectors] =
    useState<PublishedConnectors>({});

  const [, setIsQueryValidNected] = useState(true);
  const [isValidQuery] = useState(true);

  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [, setUsedConnectorMapping] = useAtom(usedConnectorMappingAtom);

  const selectedConnector = useWatch({
    name: 'integration',
    control,
  });

  const selectedAction = useWatch({
    name: 'input.action.value',
    control,
  });

  const plugin = useWatch({
    name: 'plugin',
    control,
  });

  const query = useWatch({
    name: 'input.query.value',
    control,
  });

  const editPlugin = () => {
    window.open(
      `${window.location.origin}/integrations/${
        selectedConnector?.value as string
      }?stage=staging&connector=${connectorData.plugin.name as string}`
    );
  };

  const refreshPlugins = async () => {
    await refetch();
  };

  const isReadOnly =
    _isNil(selectedAction) || selectedAction.value === queryOptions[0].value;

  useEffect(() => {
    setClearValue(true);
  }, []);

  useEffect(() => {
    if (!_isNil(selectedConnector)) {
      void getDataSetSchema();
    }
  }, [JSON.stringify(selectedConnector)]);

  useEffect(() => {
    if (!_isEmpty(publishConnectorbyId)) {
      const id = selectedConnector?.value;

      if (!_isNil(id) && !_isEmpty(id)) {
        const currConnectorConfig = publishConnectorbyId[id];

        const connectorStatus: boolean =
          ((currConnectorConfig?.staging?.isTested as boolean) ?? true) &&
          ((currConnectorConfig?.staging?.isPublish as boolean) ?? true);

        setUsedConnectorMapping((prev) => ({
          ...prev,
          [id]: {
            status: connectorStatus,
            source: [],
          },
        }));
      }
    }
  }, [JSON.stringify(selectedConnector), JSON.stringify(publishConnectorbyId)]);

  useEffect(() => {
    if (clearValue) setValue('input.query.value', '');
  }, [JSON.stringify(selectedAction)]);

  useEffect(() => {
    let newCustomSuggObj = [...tokens];

    if (!_isNil(schema) && !_isEmpty(schema)) {
      const schemaTokens = getSuggestionsKeywordsNew(
        schema,
        plugin,
        pluginName as DatabaseName
      );

      newCustomSuggObj = [...newCustomSuggObj, ...schemaTokens];
    }

    setCustomSuggestionObj(newCustomSuggObj);
  }, [tokens, schema]);

  const getDataSetSchema = async (refresh = false) => {
    try {
      const response = await axiosPrivate.post(
        '/integration/connector/execute',
        {
          id: selectedConnector.value,
          environment: 'staging',
          method: 'getSchema',
          params: {
            refresh,
          },
        }
      );

      if (response?.data.code === 'success') {
        setSchema(convertResponseObjectToSchemas(response.data.data.result));
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts.error(err.message, 'get-schema-error');
      }
    }
  };

  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data
        .filter((connector) => connector.plugin.name === pluginName)
        .map((connector) => {
          return {
            label: connector.name,
            value: connector.id,
          };
        });

      setConnectorList(publishedConnectors);
    }
  }, [data, pluginName]);

  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data ?? [];
      const publishConnectorIdAndPlugin: PublishedConnectors = {};

      if (publishedConnectors.length > 0) {
        publishedConnectors.forEach((connector) => {
          publishConnectorIdAndPlugin[connector.id] = connector;
        });
      }
      setPublishConnectors(publishConnectorIdAndPlugin);
    }
  }, [JSON.stringify(data)]);

  return (
    <Stack gutter="1.5rem">
      <PluginContainer justify="start" align="center" gap="0.8rem">
        <Image
          src={isValidImageURL(plugin?.imageUrl)}
          alt="minus"
          size="small"
          round
        />
        <Typography> {plugin?.displayName ?? ''}</Typography>
      </PluginContainer>

      <IntegrationField
        control={control}
        editPlugin={editPlugin}
        refreshPlugins={refreshPlugins}
        name="integration"
        connectorList={connectorList}
        pluginId={plugin?.id}
        disabled={isWorkflowReadOnly}
        connectorId={selectedConnector?.value}
        onSelect={(nValue, oValue) => {
          if (nValue?.value !== oValue?.value) {
            setValue('input.query.value', '');
            setDefaultValue('');
          } else {
            setDefaultValue('');
          }
        }}
      />

      {
        // eslint-disable-next-line
        !!selectedConnector?.value && (
          <>
            <div>
              <Inline gutter={8}>
                <Typography fontWeight={700}>Action</Typography>
              </Inline>
              <DropdownField
                control={control}
                name="input.action.value"
                options={queryOptions}
                disabled={isWorkflowReadOnly}
              />
            </div>

            <EditorContainer>
              <StackAsItem grow={1} as={Columns} columns={12}>
                <Column span={8}>
                  <QueryEditor
                    control={control}
                    name="input.query.value"
                    customSuggestionObj={customSuggestionObj}
                    databaseName={
                      pluginName === 'postgres'
                        ? 'pgsql'
                        : (pluginName as DatabaseName)
                    }
                    setValidity={setIsQueryValidNected}
                    methods={isReadOnly ? 'read' : 'write'}
                    disabled={isWorkflowReadOnly}
                    handleGetExecutionValues={handleGetExecutionValues}
                    execValues={execValues}
                    resetValue={defaultValue}
                    onReset={() => {
                      setDefaultValue(null);
                    }}
                  />
                </Column>

                <Column span={4}>
                  <DataSetSchemaMemoized
                    schemas={schema}
                    getDataSetSchema={getDataSetSchema}
                  />
                </Column>
              </StackAsItem>
            </EditorContainer>

            {!isValidQuery && !_isEmpty(query) && (
              <SyntaxErrorContainer padding="1rem">
                <Typography>
                  The statement written here is syntactically incorrect
                </Typography>
              </SyntaxErrorContainer>
            )}
          </>
        )
      }
    </Stack>
  );
}
