import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ExpandingTextField,
  Sheet,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { stopPropagate } from '../../../../../utils/form';
import {
  changedNodeIdsAtom,
  isWorkflowReadOnlyAtom,
  isWorkflowTestOnlyAtom,
  workflowEdgesAtom,
  workflowErrorByNodeAtom,
  workflowNodeSavingAtom,
  workflowNodesAtom,
} from '../../../atoms/atoms';
import { useTestSwitchNode } from '../../../hooks/useTestSwitchNode';
import {
  checkIfNameExists,
  formatSwitchNode,
  getBlankPath,
  getExecutedValueAndStatus,
  transformSwitchNodePayload,
} from '../../../utils/common';
import {
  nodeNameValidationBeforeSave,
  validateSwitchNode,
} from '../../../utils/validations';
import {
  SheetFooterStyled,
  WorkflowSheetFormStyled,
  WorkflowSheetTabContentStyled,
} from '../../CommonStyles/CommonStyles.styled';
import { RuleSheetCloseModal } from '../../Modals/RuleSheetCloseModal/RuleSheetCloseModal';
import { SwitchForm } from './SwitchForm/SwitchForm';
import { SwitchNodeSettings } from './SwitchNodeSettings';
import { SwitchNodeTest } from './SwitchNodeTest';

type SwitchNodeSheetProps = {
  data?: any;
  id?: string;
};

export function SwitchNodeSheet({
  data: localData,
  id = '',
}: SwitchNodeSheetProps) {
  const [, setWorkflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);

  const [workflowNodeSaving, setWorkflowNodeSaving] = useAtom(
    workflowNodeSavingAtom
  );
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);

  const { close } = useCurrentLayer();

  const { open: openRuleSheetModal } = useLayer(
    <RuleSheetCloseModal onClose={close} />
  );

  const [currentTab, setCurrentTab] = useState(0);
  const [counter, setCounter] = useState(0);

  const [isSaving, setIsSaving] = useState(false);

  const examplePath = {
    group_1: {
      nodeType: 'group',
      name: 'Path1',
      operator: '', // and / or
      children: ['condition_1'],
    },
    condition_1: {
      dataType: '',
      operator: 'any',
      leftNode: ['property_1'],
      rightNode: [],
      parent: 'group_1',
      nodeType: 'condition',
    },
    property_1: {
      dataType: '',
      parent: 'condition_1',
      nodeType: 'params',
      sourceType: '',
      attribute: '',
    },
  };

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const { handleSubmit, setError, setValue, control, watch, clearErrors } =
    useForm<any>({
      defaultValues: {
        paths: [examplePath],
        name: localData.name ?? 'switch',
        default: true,
        settings: {
          timeout: 30,
          ...(localData.settings ?? {}),
        },
      },
    });

  const handleSaveData = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, false))
  );

  const {
    updatedDataSet,
    switchData,
    testData,
    setIsTesting,
    isTesting,
    switchError,
    setCurrentStatus,
    currentStatus,
  } = useTestSwitchNode({
    localData,
    id,
  });

  useEffect(() => {
    if (counter > 1) {
      setCurrentStatus('');
    }
  }, [counter]);

  const onSubmit = async (data: any, test: boolean = false) => {
    setIsSaving(true);
    clearErrors();
    setWorkflowErrorByNode((prev) => ({
      ...prev,
      [id]: undefined,
    }));

    const isNameValid = nodeNameValidationBeforeSave(data.name, setError);

    if (!isNameValid) {
      setIsTesting(false);
      setIsSaving(false);

      return;
    }

    if (test) {
      const isValid = validateSwitchNode(data, updatedDataSet, setError);

      if (!isValid) {
        setIsSaving(false);

        return;
      }
    }

    if (!_isNil(workflowNode)) {
      const sheetData = transformSwitchNodePayload(data);
      const newWorkflowNode = JSON.parse(JSON.stringify(workflowNode));
      newWorkflowNode.data.status = currentStatus;

      if (
        data.name !== localData.name &&
        typeof localData.updateOnNameChange === 'function'
      ) {
        const doesNameExist = checkIfNameExists(
          workflowNodes,
          data.name,
          newWorkflowNode
        );

        if (doesNameExist) {
          setError('name', {
            message: 'Duplicate name provided',
          });

          return null;
        }

        localData.updateOnNameChange({
          id,
          name: localData.name,
          newName: data.name,
        });
      }

      newWorkflowNode.data.name = sheetData.name;
      newWorkflowNode.data.input = sheetData.input;
      newWorkflowNode.data.switcher = sheetData.switcher;
      newWorkflowNode.data.conditions = sheetData.conditions;
      newWorkflowNode.data.settings = sheetData.settings;
      newWorkflowNode.data.executedValue =
        currentStatus !== ''
          ? !_isNil(switchData) && !_isNil(switchData?.data)
            ? getExecutedValueAndStatus(switchData, sheetData.name)
                .executedValue
            : newWorkflowNode.data.executedValue ?? {}
          : null;

      if (counter > 1) {
        setChangedNodeIds([id]);
        setWorkflowNodeSaving(true);
      }

      localData.onWorkflowNodeChange(newWorkflowNode, 'switch', workflowEdges);
    }

    if (!test) {
      close();
    } else {
      setCounter(1);
      setIsTesting(true);
    }

    setIsSaving(false);
  };

  const append = () => {
    const paths: any[] = watch('paths');

    setValue(`paths.${paths.length}`, getBlankPath());
  };

  const remove = (index: number) => {
    const paths = watch('paths').filter((p: any, i: number) => i !== index);
    setValue('paths', paths);
  };

  useEffect(() => {
    const values = formatSwitchNode(localData);

    setValue('paths', values.paths);
    setValue('default', values.default);
    setValue('settings', values.settings);
    setValue('name', values.name);
  }, []);

  const values = watch();
  const paths = watch('paths');

  useEffect(() => {
    const submitTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      setCounter((count) => count + 1);
    }, 500);

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [JSON.stringify(paths), JSON.stringify(values)]);

  useEffect(() => {
    if (!workflowNodeSaving && isTesting) {
      void handleTestNode();
    }
  }, [workflowNodeSaving, isTesting]);

  const handleTestNode = async () => {
    await testData(values.default, () => {
      setCurrentTab(0);
      setCurrentTab(1);
      setIsTesting(false);
    });

    setIsSaving(false);
  };

  const isLoading = isTesting || workflowNodeSaving || isSaving;

  const handleSaveDataAndTest = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, true))
  );

  return (
    <Sheet size="small" onClose={counter > 1 ? openRuleSheetModal : close}>
      <WorkflowSheetFormStyled>
        <PadBox padding={0}>
          <Inline stretch="start">
            <Stack as={PadBox} gutter={48} padding={[16, 24]}>
              <Inline stretch="start">
                <Stack gutter={8}>
                  <Inline align="center" gutter="1.6rem" justify="start">
                    <Typography name="heading2">
                      <ExpandingTextField
                        control={control}
                        disabled={isWorkflowReadOnly}
                        name="name"
                      />
                    </Typography>
                  </Inline>
                </Stack>
              </Inline>
            </Stack>
          </Inline>
        </PadBox>

        <Tabs defaultOpen={currentTab} onTabChange={(i) => setCurrentTab(i)}>
          <TabList>
            <Tab>Input Params</Tab>
            <Tab>Test Result</Tab>
            <Tab>Settings</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <WorkflowSheetTabContentStyled>
                <SwitchForm
                  name="paths"
                  append={append}
                  remove={remove}
                  setValue={setValue}
                  watch={watch}
                  control={control}
                  updatedDataSet={updatedDataSet}
                />
              </WorkflowSheetTabContentStyled>
            </TabPanel>
            <TabPanel>
              <SwitchNodeTest
                outputValue={
                  !_isNil(switchData?.data?.data)
                    ? getExecutedValueAndStatus(switchData, watch('name'))
                        .executedValue
                    : switchError?.response?.data ?? localData.executedValue
                }
              />
            </TabPanel>
            <TabPanel>
              <WorkflowSheetTabContentStyled>
                <SwitchNodeSettings name="settings" control={control} />
              </WorkflowSheetTabContentStyled>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <SheetFooterStyled>
          <Button
            appearance="filled"
            disabled={isLoading || !isWorkflowTestOnly}
            onClick={handleSaveDataAndTest}
          >
            {isLoading ? <Spinner size="extraSmall" /> : <Inline>Test</Inline>}
          </Button>

          <Button
            appearance="contained"
            disabled={isLoading || isWorkflowReadOnly}
            onClick={handleSaveData}
          >
            <Inline>Save</Inline>
          </Button>
        </SheetFooterStyled>
      </WorkflowSheetFormStyled>
    </Sheet>
  );
}
