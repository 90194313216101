import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';
import { AiFillCaretDown } from 'react-icons/ai';
import { RulesPopoverField, Typography } from 'ui';

import { isRuleReadOnlyAtom } from '../../../..';
import { EntityVersionSelection } from '../../../../../../components/EntityVersionSelection/EntityVersionSelection';
import { ENTITY_ID } from '../../../../../../utils/constant';
import { useGetRuleAttributesById } from '../../../../hooks/graphql/useGetRuleAttributesById';
import { ruleResultAndList } from '../../../../utils/common';
import {
  assetsInDraftMapAtom,
  versionMappingInfoAtom,
} from '../../../atom/atom';
import { filteredRuleOptionsAtom } from '../../RuleSet';
import {
  customAttributesByIdAtom,
  resultByRuleAtom,
} from '../../RuleSetEditor/RuleSetEditor';
import { RulesLauncherStyled, TableColumnStyled } from './TableColumns.styled';

export type RulesColumnProps = Omit<UseControllerProps, 'name'> & {
  index: number;
  setValue: UseFormSetValue<any>;
};

export function RulesColumn({ control, index, setValue }: RulesColumnProps) {
  const id: string = useWatch({
    control,
    name: `ruleList.${index}.ruleId`,
  });

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [versionMappingInfo, setVersionMappingInfo] = useAtom(
    versionMappingInfoAtom
  );
  const [assetsInDraftMap] = useAtom(assetsInDraftMapAtom);

  const [filteredRuleOptions] = useAtom(filteredRuleOptionsAtom);

  const [, setCustomAttributesById] = useAtom(customAttributesByIdAtom);
  const [ruleResultById, setResultByRule] = useAtom(resultByRuleAtom);

  const currNodeVersionMapping = versionMappingInfo?.find(
    (currMapping) => currMapping.nodeId === id
  );

  const [getRuleAttributesQuery] = useGetRuleAttributesById();

  const launcher = (
    <RulesLauncherStyled padding={[4, 8]}>
      <Inline align="center" stretch="start">
        <Typography className="name">
          {!_isNil(ruleResultById[id]) ? ruleResultById[id].name : '-'}
        </Typography>
        <div>
          <AiFillCaretDown size={8} />
        </div>
      </Inline>
    </RulesLauncherStyled>
  );

  const handleUpdateRuleAttributes = async (version: string) => {
    if (!_isNil(currNodeVersionMapping)) {
      try {
        const filters: Record<string, any> = {};

        if (
          !_isNil(version) &&
          !_isEmpty(version) &&
          !['live', 'draft'].includes(version ?? '')
        ) {
          filters.eq = { version };
        }

        const { data } = await getRuleAttributesQuery({
          variables: { id, live: version !== 'draft', filters },
          fetchPolicy: 'no-cache',
        });

        if (!_isNil(data)) {
          const { ruleResultAndNameById, customAttributePerRule } =
            ruleResultAndList(data.getRule.data, []);

          setCustomAttributesById((prev) => ({
            ...prev,
            ...customAttributePerRule,
          }));

          setResultByRule((prev) => ({ ...prev, ...ruleResultAndNameById }));
        }
      } catch (err) {}
    }
  };

  const handleDataUpdate = (data: Record<string, any>) => {
    if ('selectedVersion' in data) {
      const versionInfo = data.selectedVersion;

      setVersionMappingInfo(
        (versionMappingList) =>
          versionMappingList?.map((currMapping) => {
            if (currMapping.nodeId === id) {
              return {
                ...currMapping,
                version: versionInfo.version,
              };
            }

            return currMapping;
          }) ?? []
      );

      void handleUpdateRuleAttributes(versionInfo.version);
    }
  };

  const onChange = (ruleId: string) => {
    setVersionMappingInfo((prev) =>
      prev?.map((mappingObj) => {
        if (mappingObj.entityId === id) {
          return {
            entityId: ruleId,
            type: 'rule',
            version: 'draft',
            nodeId: ruleId,
          };
        }

        return mappingObj;
      })
    );
    setValue(`ruleList.${index}.ruleId`, ruleId);
  };

  return (
    <TableColumnStyled width="50%" padding={[6, 10]}>
      <Inline align="center" justify="start" gutter="1rem">
        <RulesPopoverField
          launcher={launcher}
          options={filteredRuleOptions}
          control={control}
          name={`ruleList.${index}.ruleId`}
          disabled={isRuleReadOnly}
          onChange={(item) => {
            onChange(item);
          }}
        />

        <EntityVersionSelection
          entityInfo={{
            type: ENTITY_ID.rules,
            id,
          }}
          onLoadGetData={false}
          isReadOnly={isRuleReadOnly}
          updateDataOnParent={handleDataUpdate}
          selectedVersion={currNodeVersionMapping?.version}
          showError={assetsInDraftMap?.[id]}
          showRefreshBtn={true}
        />
      </Inline>
    </TableColumnStyled>
  );
}
