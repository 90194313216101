import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';
import { ChangeEvent, Ref, forwardRef, useEffect, useState } from 'react';
import { BiExpandAlt } from 'react-icons/bi';
import {
  ExecutedValueTooltip,
  TooltipReact,
  Typography,
  getDataTypeNected,
  useLayer,
} from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import { CalenderIconPicker } from '../../../../../components/CalenderIconPicker/CalenderIconPicker';
import { TextInputModal } from '../../../../../components/Modals/TextInputModal/TextInputModal';
import {
  DATE_TIME_FORMAT,
  convertArrayAsInput,
  convertArrayToString,
  formatNectedDate,
  getPropertyIfExists,
  isArrayAsInputValid,
  isFieldReadOnly,
} from '../../../../../utils/common';
import { LIST_KEY_REGEX } from '../../../../../utils/regex';
import {
  CorrectValues,
  getConstantNode,
  getParamNode,
  getPlaceholderByDataType,
  primitiveDataTypesList,
  validateRhsValue,
} from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { ErrorPopoverPositioned } from '../Error/ErrorPopoverPositioned';
import { sendEventToGTMType } from '../RuleBlock/RuleBlock';
import { simpleNodeErrors, simpleRuleNodesAtom } from '../index';
import type { ErrorByNodeId } from '../models';
import {
  InputBrackets,
  InputContainer,
  InputFieldStyled,
  InputStyled,
  RoundIcon,
} from './RhsParamPopover.styled';

type RhsLauncherProps = {
  nodeId: string;
  panelVisible: boolean;
  text: string | number | any[];
  parentId: string;
  dataType: string;
  selectedOperator?: string;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
  isOpen?: any;
};

export const RhsLauncher = forwardRef(
  (
    {
      text,
      nodeId,
      parentId,
      panelVisible = false,
      dataType,
      selectedOperator,
      handleSendEventToGTM,
      isOpen,
    }: RhsLauncherProps,
    ref: Ref<any>
  ) => {
    const [rules, setRules] = useAtom(simpleRuleNodesAtom);
    const [errorByRuleId, setErrorByRuleId] = useAtom(simpleNodeErrors);
    const [dataset] = useAtom(dataSetParamsAtom);
    const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

    const [value, setValue] = useState<string | number | any[]>(text);
    const [nodeType, setNodeType] = useState(rules[nodeId]?.nodeType);
    const [hasError, setHasError] = useState(false);
    const [, setIsToolTipVisible] = useState(true);
    const { openWithProps } = useLayer(<TextInputModal />);

    const [isFocused, setIsFocused] = useState(false);

    const error = !_isNil(errorByRuleId[nodeId])
      ? errorByRuleId[nodeId].message
      : null;

    useEffect(() => {
      if (!_isUndefined(rules[nodeId]) && nodeType !== rules[nodeId].nodeType) {
        setNodeType(rules[nodeId].nodeType);
        const title = rules[nodeId].attribute;

        if (rules[nodeId].nodeType === 'params' && !_isNil(title)) {
          setValue(title);
          setHasError(false);

          if (!_isNil(errorByRuleId[nodeId])) {
            setErrorByRuleId((prev) =>
              _reduce(
                prev,
                (result: ErrorByNodeId, value, key) => {
                  if (key === nodeId) {
                    return result;
                  }

                  return {
                    ...result,
                    [key]: prev[key],
                  };
                },
                {}
              )
            );
          }
        }
      }
    }, [rules[nodeId]]);

    useEffect(() => {
      setValue(text);
    }, [text]);

    const sourceType = rules[nodeId]?.sourceType;
    const attribute = rules[nodeId]?.attribute;
    const rhsDataType = rules[nodeId]?.dataType;

    const toolTipMessage =
      !_isNil(sourceType) &&
      !_isEmpty(sourceType) &&
      !_isNil(dataset[sourceType])
        ? getPropertyIfExists(
            JSON.parse(
              JSON.stringify(
                Object.keys(dataset[sourceType]?.attributes ?? {}).reduce(
                  (acc, curr) => {
                    return {
                      ...acc,
                      [curr]:
                        dataset[sourceType ?? ''].attributes[`${curr}`]
                          .executedValue,
                    };
                  },
                  {}
                )
              )
            ) ?? {},
            attribute ?? ''
          )
        : value.toString();

    const handleClick = () => {
      if (typeof handleSendEventToGTM === 'function') {
        handleSendEventToGTM({
          action: _isEmpty(text) ? 'add' : 'edit',
          element: 'rhs_value',
          actionName: dataType ?? '',
        });
      }
    };

    const transformListValue = (type: string, value: any) => {
      if (type === 'LIST_TO_STRING') {
        if (Array.isArray(value)) {
          const convertedValue = convertArrayToString(value);

          return _isEmpty(convertedValue) ? '' : `${convertedValue}`;
        } else {
          return value;
        }
      }

      if (type === 'STRING_TO_LIST') {
        if (typeof value === 'string') {
          return convertArrayAsInput(value);
        } else {
          return value;
        }
      }

      return value;
    };

    function returnDataType() {
      if (
        dataType === 'list' &&
        !['contains', 'notContains'].includes(selectedOperator ?? '')
      ) {
        return dataType;
      }

      const isValidOperator = [
        'notContainsIn',
        'containsIn',
        'in',
        'nin',
      ].includes(selectedOperator ?? '');

      if (primitiveDataTypesList.includes(dataType) && isValidOperator) {
        if (isArrayAsInputValid(value) && dataType === 'string') {
          return 'list';
        }

        if (dataType !== 'string') {
          return 'list';
        }
      }

      return dataType;
    }

    const getNodeParamsBasedOnType = (type: string, result: CorrectValues) => {
      const mappedValue =
        !_isUndefined(sourceType) && !_isNil(dataset[sourceType])
          ? getPropertyIfExists(
              JSON.parse(
                JSON.stringify(
                  Object.keys(dataset[sourceType]?.attributes ?? {}).reduce(
                    (acc, curr) => {
                      return {
                        ...acc,
                        [curr]:
                          dataset[sourceType ?? ''].attributes[`${curr}`]
                            .executedValue,
                      };
                    },
                    {}
                  )
                )
              ) ?? {},
              result.value as string
            )
          : undefined;

      return type === 'constant'
        ? {
            ...getConstantNode(
              parentId,
              returnDataType(),
              rules[nodeId].siblingIndex
            ),
            value:
              returnDataType() === 'list'
                ? transformListValue('STRING_TO_LIST', result.value)
                : result.value,
            dataType: returnDataType(),
          }
        : {
            ...getParamNode(parentId, rules[nodeId].siblingIndex),
            value: '',
            sourceType,
            attribute: value as string,
            dataType: !_isUndefined(mappedValue)
              ? getDataTypeNected(mappedValue)
              : 'unknown',
          };
    };

    const onChange = (e: ChangeEvent<any>) => {
      e.preventDefault();

      // eslint-disable-next-line
      const isList = !!e.target.value.match(LIST_KEY_REGEX)?.length;

      const nodeType = isList ? 'params' : 'constant';

      if (typeof handleSendEventToGTM === 'function') {
        handleSendEventToGTM({
          action: 'selection',
          element: 'rhs_value',
          actionName: nodeType,
        });
      }

      setNodeType(nodeType);

      const result = validateRhsValue(
        e.target.value,
        returnDataType(),
        nodeType
      );

      setValue(e.target.value);
      setHasError(!result.isCorrect);

      if (!result.isCorrect) {
        setErrorByRuleId((prev) => ({
          ...prev,
          [nodeId]: {
            code: 500,
            message:
              !_isNil(result.message) && !_isEmpty(result.message)
                ? result.message
                : 'Field is required',
          },
        }));
      } else {
        setErrorByRuleId((prev) =>
          _reduce(
            prev,
            (result: ErrorByNodeId, value, key) => {
              if (key === nodeId) {
                return result;
              }

              return {
                ...result,
                [key]: prev[key],
              };
            },
            {}
          )
        );
      }
    };

    const showExpandIcon =
      nodeType === 'constant' && returnDataType() === 'list';

    const formatValue = (input: any) => {
      if (returnDataType() === 'list') {
        return transformListValue('LIST_TO_STRING', input);
      }

      if (returnDataType() === 'date') {
        try {
          return formatNectedDate(input, 'date');
        } catch (err) {
          return value;
        }
      }

      if (returnDataType() === 'dateTime') {
        try {
          return formatNectedDate(input, 'dateTime');
        } catch (err) {
          return value;
        }
      }

      return input;
    };

    return (
      <Inline gutter="0.4rem">
        <InputContainer align="center" gutter={0} onClick={handleClick}>
          {!_isNil(error) && <ErrorPopoverPositioned error={error} />}
          {nodeType !== 'constant' && (
            <InputBrackets direction="left">
              <Typography>{'{{'}</Typography>
            </InputBrackets>
          )}
          {nodeType === 'constant' && returnDataType() === 'list' && (
            <InputBrackets direction="left">
              <Typography>{'['}</Typography>
            </InputBrackets>
          )}
          <ExecutedValueTooltip
            id={nodeId}
            attribute={attribute}
            isVisible={nodeType !== 'constant'}
            value={
              returnDataType() === 'list'
                ? transformListValue('STRING_TO_LIST', toolTipMessage)
                : toolTipMessage
            }
            dataType={rhsDataType}
          >
            {returnDataType() === 'string' ? (
              <InputStyled
                ref={ref}
                value={formatValue(value)}
                $hasError={hasError}
                $showExpandIcon={showExpandIcon}
                $isLarge={dataType === 'dateTime'}
                onChange={onChange}
                onFocus={() => {
                  setIsFocused(true);
                  setIsToolTipVisible(false);
                }}
                readOnly={isFieldReadOnly(returnDataType() ?? '')}
                onBlur={() => {
                  const result = validateRhsValue(
                    value,
                    returnDataType(),
                    nodeType
                  );
                  setRules((prev) => ({
                    ...prev,
                    [nodeId]: getNodeParamsBasedOnType(nodeType, result),
                  }));

                  setIsToolTipVisible(true);
                  setTimeout(() => {
                    setIsFocused(false);
                  }, 100);
                }}
                rows={
                  returnDataType() === 'string' && nodeType !== 'params'
                    ? isFocused
                      ? 4
                      : 2
                    : 1
                }
                placeholder={getPlaceholderByDataType(dataType)}
              />
            ) : (
              <InputFieldStyled
                value={formatValue(value)}
                ref={ref}
                $hasError={false}
                $showExpandIcon={showExpandIcon}
                placeholder={getPlaceholderByDataType(dataType)}
                disabled={isRuleReadOnly}
                onChange={(e: any) => {
                  onChange(e);
                }}
                onFocus={() => {
                  setIsFocused(true);
                  setIsToolTipVisible(false);
                }}
                readOnly={isFieldReadOnly(returnDataType() ?? '')}
                onBlur={() => {
                  const result = validateRhsValue(
                    value,
                    returnDataType(),
                    nodeType
                  );
                  setRules((prev) => ({
                    ...prev,
                    [nodeId]: getNodeParamsBasedOnType(nodeType, result),
                  }));
                  setIsFocused(false);
                  setIsToolTipVisible(true);
                }}
              />
            )}
          </ExecutedValueTooltip>

          {nodeType !== 'constant' && (
            <InputBrackets direction="right">
              <Typography>{'}}'}</Typography>
            </InputBrackets>
          )}
          {nodeType === 'constant' && returnDataType() === 'list' && (
            <InputBrackets direction="right">
              <Typography>{']'}</Typography>
            </InputBrackets>
          )}
          {value === '' && dataType === 'dateTime' && (
            <InputBrackets direction="right">
              <TooltipReact id={nodeId}>
                Enter Date in {DATE_TIME_FORMAT} format
              </TooltipReact>
            </InputBrackets>
          )}
        </InputContainer>
        {returnDataType() === 'string' && (
          <RoundIcon
            type="button"
            onClick={() => {
              openWithProps({
                onSubmit: (val: Record<string, any>) => {
                  setRules((prev) => ({
                    ...prev,
                    [nodeId]: {
                      ...prev[nodeId],
                      nodeType: 'constant',
                      value: val.value,
                      sourceType: '',
                      attribute: '',
                    },
                  }));
                },
                value: formatValue(value),
              });
            }}
            disabled={nodeType !== 'constant'}
          >
            <BiExpandAlt />
          </RoundIcon>
        )}

        {['dateTime', 'date'].includes(dataType) &&
          returnDataType() !== 'list' && (
            <RoundIcon type="button">
              <CalenderIconPicker
                value={value}
                dataType={dataType}
                disabled={isRuleReadOnly}
                onPick={(val) => {
                  setRules((prev) => ({
                    ...prev,
                    [nodeId]: {
                      ...prev[nodeId],
                      value: formatNectedDate(val, dataType) as string,
                      sourceType: undefined,
                      attribute: undefined,
                      nodeType: 'constant',
                      dataType,
                    },
                  }));
                }}
              />
            </RoundIcon>
          )}
      </Inline>
    );
  }
);

RhsLauncher.displayName = 'RhsLauncher';
