import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';

import { useGetPlugins } from '../../hooks/graphql/useGetPlugins';
import { envMap } from '../../utils/constant';
import type { FromType } from '../Modals/ImportEntityModal/ImportEntityModal';
import { RenderNestedNodes } from './RenderNestedNodes';

export type DependencyConfig = {
  entity: string;
  category: string;
  name: string;
  isNameConflict: boolean;
  isCategoryConflict: boolean;
  isEntityVersionConflict?: boolean;
  dependencies?: string[];
  action?: string;
  entityId?: string;
  version?: string;
};

type EntityDependencyViewProps = {
  rootId: string;
  data: Record<string, DependencyConfig>;
  isSummaryView?: boolean;
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
  from?: FromType;
  defaultDepenedencyData?: Record<string, any>;
};

export function EntityDependencyView({
  rootId,
  data,
  setValue,
  control,
  isSummaryView = true,
  from = 'import_sheet',
  defaultDepenedencyData,
}: EntityDependencyViewProps) {
  const [expandedNodeIds, setExpandedNodeIds] = useState<string[]>([]);
  const [getConnectorPlugins, { data: pluginData }] = useGetPlugins({
    isSource: false,
  });

  const [iconConfig, setIconConfig] = useState<Record<string, string>>({});

  useEffect(() => {
    void getConnectorPlugins();
  }, []);

  useEffect(() => {
    if (!_isNil(pluginData) && !_isNil(pluginData.getPlugin.data)) {
      const pluginIconConfig = pluginData.getPlugin.data.reduce(
        (config, currPluginObj) => {
          return {
            ...config,
            [currPluginObj.name]: currPluginObj.imageUrl,
          };
        },
        {}
      );

      setIconConfig({
        ...iconConfig,
        ...pluginIconConfig,
        rule: `${envMap.VITE_ASSETS_URL}website/icons/rules.svg`,
        dataSet: `${envMap.VITE_ASSETS_URL}website/icons/datasets.svg`,
        workflow: `${envMap.VITE_ASSETS_URL}website/icons/workflow.svg`,
        variable: `${envMap.VITE_ASSETS_URL}website/icons/remoteConfig.svg`,
      });
    }
  }, [JSON.stringify(pluginData)]);

  if (_isNil(data)) {
    return null;
  }

  return (
    <RenderNestedNodes
      rootId={rootId}
      id={rootId}
      data={data}
      expandedNodeIds={expandedNodeIds}
      setExpandedNodeIds={setExpandedNodeIds}
      setValue={setValue}
      control={control}
      isSummaryView={isSummaryView}
      from={from}
      iconConfig={iconConfig}
      defaultDepenedencyData={defaultDepenedencyData}
    />
  );
}
