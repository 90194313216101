import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { Dataset } from '../../../TreeViewer';
import type { Attributes } from '../RulePopover';

// Used to check if searchKey is present in nth level nested object (obj),
// if present then update filteredFlatRecords
function checkKeyIsPresentInNestedObject(
  obj: any,
  searchKey: string,
  parentKey: string,
  filteredFlatRecords: Record<string, any>
) {
  if (_isNil(obj)) {
    return;
  }

  if (Array.isArray(obj)) {
    obj.forEach((item, index) => {
      checkKeyIsPresentInNestedObject(
        item,
        searchKey,
        `${parentKey}[${index}]`,
        filteredFlatRecords
      );
    });
  } else if (typeof obj === 'object') {
    Object.keys(obj).forEach((key) => {
      if (key.toLowerCase().includes(searchKey.toLowerCase())) {
        filteredFlatRecords[parentKey] = obj;
        filteredFlatRecords[`${parentKey}.${key}`] = obj[key];
      }

      if (typeof obj[key] === 'object') {
        checkKeyIsPresentInNestedObject(
          obj[key],
          searchKey,
          `${parentKey}.${key}`,
          filteredFlatRecords
        );
      }
    });
  }
}

function search(
  data: Record<string, any>,
  value: any,
  path: string[],
  filteredFlatRecords: Record<string, any>
) {
  if (typeof data !== 'object') {
    return {};
  }

  if (!_isNil(data?.attributes) && !_isEmpty(data.attributes)) {
    for (const [attrKey, attrValue] of Object.entries<Attributes>(
      data.attributes
    )) {
      const sampleValue = attrValue?.executedValue;

      const key = path.concat(attrKey).join('.');

      if (attrValue.name.toLowerCase().includes(value.toLowerCase())) {
        filteredFlatRecords[key] = attrValue;
      } else if (typeof sampleValue === 'object' && !_isNil(sampleValue)) {
        if (attrValue.dataType === 'json' || attrValue.dataType === 'restAPI') {
          Object.keys(sampleValue).forEach((k) => {
            if (k.toLowerCase().includes(value.toLowerCase())) {
              filteredFlatRecords[key] = attrValue;
              filteredFlatRecords[`${key}.${k}`] = sampleValue[k];
            }

            if (typeof sampleValue[k] === 'object') {
              checkKeyIsPresentInNestedObject(
                sampleValue[k],
                value,
                `${key}.${k}`,
                filteredFlatRecords
              );
            }
          });
        } else if (attrValue.dataType === 'list') {
          sampleValue.forEach((item: any, index: number) => {
            if (
              `${key}[${index}]`.toLowerCase().includes(value.toLowerCase())
            ) {
              filteredFlatRecords[key] = attrValue;
              filteredFlatRecords[`${key}[${index}]`] = item;
            }

            if (typeof item === 'object') {
              checkKeyIsPresentInNestedObject(
                item,
                value,
                `${key}[${index}]`,
                filteredFlatRecords
              );
            }
          });
        }
      }
    }
  }

  for (const [k, v] of Object.entries(data)) {
    if (!['attributes', 'footer', 'tooltip'].includes(k)) {
      search(v, value, path.concat(k), filteredFlatRecords);
    }
  }

  return filteredFlatRecords;
}

export function searchAttributes(
  dataset: Record<string, Dataset>,
  value: string
) {
  const filteredFlatRecords: Record<string, any> = search(
    dataset,
    value,
    [],
    {}
  );

  return Object.keys(filteredFlatRecords).length > 0
    ? Array.from(
        new Set(
          Object.keys(filteredFlatRecords).reduce<string[]>(
            (acc, curr: string) => {
              return [...acc, ...curr.split('.')];
            },
            []
          )
        )
      )
    : [];
}

type KeyAndType = {
  key: string;
  type: string;
  value: any;
};

export function flattenKeysAndTypes(
  obj: Record<string, any>,
  depth = 0,
  parentKey = '',
  maxDepth = 6,
  allowList = false,
  allowJson = false
): KeyAndType[] {
  const isArray = allowList ? false : Array.isArray(obj);

  if (
    depth > maxDepth ||
    (typeof obj !== 'object' && !isArray) ||
    obj === null
  ) {
    return [];
  }

  const result: KeyAndType[] = [];
  for (const key in obj) {
    if (!_isNil(obj[key])) {
      const value = obj[key];
      const dataType = getDataTypeNected(value);
      // eslint-disable-next-line

      let currentKey = !_isEmpty(parentKey) ? parentKey + '.' + key : key;

      if (isArray) {
        const indexKey = Number.isInteger(parseInt(key)) ? `[${key}]` : key;
        // eslint-disable-next-line
        currentKey = parentKey ? parentKey + indexKey : indexKey;
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        if (allowJson && depth === 0) {
          result.push({
            key: currentKey,
            type: 'json',
            value,
          });
        }

        result.push(
          ...flattenKeysAndTypes(
            value,
            depth + 1,
            currentKey,
            maxDepth,
            allowList,
            allowJson
          )
        );
      } else {
        result.push({
          key: currentKey,
          type:
            dataType === 'object'
              ? 'list'
              : KEY_NAME_BY_DATATYPE[dataType] ?? dataType,
          value,
        });
      }
    }
  }

  return result;
}

export const KEY_NAME_BY_DATATYPE: Record<string, string> = {
  number: 'numeric',
  object: 'json',
};

export const isValidDateorDateTime = (inputString: string) => {
  // Regular expressions for different date formats
  const dateFormat1 = /^(\d{2})\/(\d{2})\/(\d{4})$/; // dd/mm/yyyy
  const dateFormat2 = /^(\d{4})\/(\d{2})\/(\d{2})$/; // yyyy/mm/dd
  const dateFormat3 = /^(\d{2})-(\d{2})-(\d{4})$/; // dd-mm-yyyy
  const dateFormat4 = /^(\d{4})-(\d{2})-(\d{2})$/; // yyyy-mm-dd

  const dateTimeFormat1 =
    /^(\d{2})[/-](\d{2})[/-](\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // dd/MM/yyyy HH:mm:ss
  const dateTimeFormat2 =
    /^(\d{4})[/-](\d{2})[/-](\d{2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy/MM/dd HH:mm:ss
  const dateTimeFormat3 = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy-MM-dd HH:mm:ss
  const dateTimeFormat4 = /^(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // dd-MM-yyyy HH:mm:ss

  const dateTimeTZFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/; // yyyy-MM-ddTHH:mm:ssZ

  const dateTimeTZMilliSecFormat =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,9})?Z$/; // yyyy-MM-ddTHH:mm:ss.aaaaaaaaaZ

  const rfc3339Format =
    /^(\d{4})-(\d{2})-(\d{2})(?:T|\s)(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,3}))?(?:(Z)|(?:([+-])(\d{2}):(\d{2})))$/; // RFC 3339 with optional milliseconds

  if (dateFormat1.test(inputString)) {
    return { isValid: true, type: 'date' };
  } else if (dateFormat2.test(inputString)) {
    return { isValid: true, type: 'date' };
  } else if (dateFormat3.test(inputString)) {
    return { isValid: true, type: 'date' };
  } else if (dateFormat4.test(inputString)) {
    return { isValid: true, type: 'date' };
  } else if (dateTimeFormat1.test(inputString)) {
    return { isValid: true, type: 'dateTime' };
  } else if (dateTimeFormat2.test(inputString)) {
    return { isValid: true, type: 'dateTime' };
  } else if (dateTimeFormat3.test(inputString)) {
    return { isValid: true, type: 'dateTime' };
  } else if (dateTimeFormat4.test(inputString)) {
    return { isValid: true, type: 'dateTime' };
  } else if (dateTimeTZFormat.test(inputString)) {
    return { isValid: true, type: 'dateTime' };
  } else if (dateTimeTZMilliSecFormat.test(inputString)) {
    return { isValid: true, type: 'dateTime' };
  } else if (rfc3339Format.test(inputString)) {
    return { isValid: true, type: 'dateTime' };
  } else {
    return { isValid: true, type: 'string' };
  }
};

export const getDataTypeNected = (value: any) => {
  const type = typeof value;
  const isDateObj = isValidDateorDateTime(value);

  if (type === 'string' && isDateObj.isValid) {
    return isDateObj.type;
  }

  if (value === null) {
    return 'generic';
  }

  switch (type) {
    case 'number':
    case 'bigint':
      return 'numeric';
    case 'object':
      return Array.isArray(value) ? 'list' : 'json';
    case 'symbol':
    case 'undefined':
    case 'function':
      return '';

    default:
      return type;
  }
};

type KeyPermutation = {
  key: string;
  value: any;
  depth: number;
  dataType: string;
};

type FlattenKeysAndTypesV2Args = {
  obj: any;
  depth?: number;
  currentKey?: string;
  result?: KeyPermutation[];
  seenKeys?: Set<string>;
};

export function flattenKeysAndTypesV2({
  obj,
  depth = 0,
  currentKey = '',
  result = [],
  seenKeys = new Set(),
}: FlattenKeysAndTypesV2Args): KeyPermutation[] {
  if (Array.isArray(obj)) {
    if (currentKey !== '' && !seenKeys.has(currentKey)) {
      result.push({ key: currentKey, value: obj, depth, dataType: 'list' });
      seenKeys.add(currentKey);
    }
    obj.forEach((item, index) => {
      const newKey = `${currentKey}[${index}]`;

      if (typeof item === 'object') {
        result.push(
          ...flattenKeysAndTypesV2({
            obj: item,
            depth: depth + 1,
            currentKey: newKey,
            result,
            seenKeys,
          })
        );
      } else {
        if (!seenKeys.has(newKey)) {
          result.push({
            key: newKey,
            value: item,
            depth: depth + 1,
            dataType: getDataTypeNected(item),
          });
          seenKeys.add(newKey);
        }
      }
    });
  } else if (typeof obj === 'object' && obj !== null) {
    if (currentKey !== '' && !seenKeys.has(currentKey)) {
      result.push({
        key: currentKey,
        value: obj,
        depth,
        dataType: getDataTypeNected(obj),
      });
      seenKeys.add(currentKey);
    }
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        // eslint-disable-next-line
        const newKey = currentKey ? `${currentKey}.${key}` : key;

        if (!seenKeys.has(newKey)) {
          result.push({
            key: newKey,
            value,
            depth: depth + 1,
            dataType: getDataTypeNected(value),
          });
          seenKeys.add(newKey);

          if (typeof value === 'object') {
            result.push(
              ...flattenKeysAndTypesV2({
                obj: value,
                depth: depth + 1,
                currentKey: newKey,
                result,
                seenKeys,
              })
            );
          }
        }
      }
    }
  } else {
    if (currentKey !== '' && !seenKeys.has(currentKey)) {
      result.push({
        key: currentKey,
        value: obj,
        depth,
        dataType: getDataTypeNected(obj),
      });
      seenKeys.add(currentKey);
    }
  }

  const visited: string[] = [];

  return result.filter((x) => {
    if (!visited.includes(x.key)) {
      visited.push(x.key);

      return true;
    }

    return false;
  });
}

export function getObjectUnion(objects: any[]) {
  const nonObjects = (Array.isArray(objects) ? objects : []).filter(
    (obj) => typeof obj !== 'object' || obj === null || Array.isArray(obj)
  );

  if (nonObjects.length === objects.length) {
    // If all elements are non-objects, return the last non-object element
    return nonObjects.slice(-1)[0];
  }

  const union: Record<string, any> = {};

  for (const obj of Array.isArray(objects) ? objects : []) {
    if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
      for (const [key, value] of Object.entries(obj)) {
        if (typeof value !== 'undefined' && value !== null && !(key in union)) {
          union[key] = value;
        } else if (
          typeof value !== 'undefined' &&
          value !== null &&
          Array.isArray(value)
        ) {
          union[key] = value;
        } else if (
          typeof value !== 'undefined' &&
          value !== null &&
          typeof union[key] === 'object' &&
          typeof value === 'object'
        ) {
          union[key] = getObjectUnion([union[key], value]);
        }
      }
    }
  }

  return union;
}
