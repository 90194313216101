import type { ObservableQuery } from '@apollo/client';
import { atom, useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Sheet, useCurrentLayer, useLayer } from 'ui';

import { usedConnectorMappingAtom } from '../../../../atom';
import { tabList } from '../../../../components/VersionControl/constant';
import { customAttributesAtom } from '../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import {
  EntityType,
  useLayerCloseOnPath,
} from '../../../../hooks/useLayerCloseOnPath';
import { PanelType } from '../../../../types';
import { dataset } from '../../fixtures/dataset';
import type { TableResponseModel } from '../../hooks/graphql/useGetTableData';
import {
  createRuleSheetAtom,
  isLiveNodeSheetClosedAtom,
  isRuleReadOnlyAtom,
} from '../../index';
import { DecisionTable } from '../DecisionTable/DecisionTable';
import { additionalDatasetAtom } from '../DecisionTable/components/DecisionTableActions';
import { RuleSet } from '../RuleSet/RuleSet';
import { SimpleRule } from '../SimpleRule';
import { ResultType } from '../SimpleRule/Results';
import { outputDatasetAtom } from '../SimpleRule/Results/DataActions';
import {
  activePanelAtom,
  approvalInfoRuleAtom,
  assetsInDraftMapAtom,
  datasetDetailsInRuleAtom,
  errorInRuleAtom,
  hasConnectorErrorInCustomAttrSheetAtom,
  isRuleLiveAtom,
  vcListTabIndexRuleAtom,
  versionInfoRuleAtom,
  versionMappingInfoAtom,
} from '../atom/atom';
import { RuleExitModal } from './RuleExitModal';

export type CreateRuleSheetProps = {
  refetch?: ObservableQuery<TableResponseModel>['refetch'];
  ruleName?: string;
  ruleId?: string;
  isClone?: boolean;
  isLive?: boolean;
  from?: string;
};

export type RuleTypes = 'simpleRule' | 'decisionTable' | 'ruleSet' | '';

export const dataSetParamsAtom = atom(dataset);

export const isRulePublishableAtom = atom(false);

export const isRulePublishedAtom = atom(false);

export const ruleEnvironmentAtom = atom<'staging' | 'production'>('staging');

export const sectionAtom = atom<ResultType>('thenDataParams');

export const enableCronTestButtonAtom = atom(true);

export const ruleSavingErrorAtom = atom('');

export const firstCustomAttributeAtom = atom('');

const rulesComponents: Record<
  string,
  (
    ruleName?: string,
    ruleId?: string,
    isClone?: boolean,
    refetch?: ObservableQuery<TableResponseModel>['refetch'],
    isLive?: boolean,
    commitId?: string
  ) => JSX.Element
> = {
  simpleRule: (
    ruleName?: string,
    ruleId?: string,
    isClone?: boolean,
    refetch?: ObservableQuery<TableResponseModel>['refetch'],
    isLive?: boolean,
    commitId?: string
  ) => (
    <SimpleRule
      ruleName={ruleName}
      ruleId={ruleId}
      isClone={isClone}
      refetch={refetch}
      isLive={isLive}
      commitId={commitId}
    />
  ),
  ruleSet: (
    ruleName?: string,
    ruleId?: string,
    isClone?: boolean,
    refetch?: ObservableQuery<TableResponseModel>['refetch'],
    isLive?: boolean,
    commitId?: string
  ) => (
    <RuleSet
      ruleName={ruleName}
      ruleId={ruleId}
      isClone={isClone}
      refetch={refetch}
      isLive={isLive}
      commitId={commitId}
    />
  ),
  decisionTable: (
    ruleName?: string,
    ruleId?: string,
    isClone?: boolean,
    refetch?: ObservableQuery<TableResponseModel>['refetch'],
    isLive?: boolean,
    commitId?: string
  ) => (
    <DecisionTable
      ruleName={ruleName}
      ruleId={ruleId}
      isClone={isClone}
      refetch={refetch}
      isLive={isLive}
      commitId={commitId}
    />
  ),
};

export const CreateRuleSheet = ({
  refetch,
  ruleName,
  ruleId,
  isClone = false,
  isLive = false,
  from,
}: CreateRuleSheetProps) => {
  const navigate = useNavigate();
  const [ruleType] = useAtom(createRuleSheetAtom);
  const [ruleSavingError, setRuleSavingError] = useAtom(ruleSavingErrorAtom);
  const [, setIsRulePublished] = useAtom(isRulePublishedAtom);
  const [, setIsRulePublishable] = useAtom(isRulePublishableAtom);
  const [, setIsLiveNodeSheetClosed] = useAtom(isLiveNodeSheetClosedAtom);
  const [, setCustomAttributes] = useAtom(customAttributesAtom);
  const [, setOutputDataset] = useAtom(outputDatasetAtom);
  const [, setAdditionalDataset] = useAtom(additionalDatasetAtom);
  const [, setActivePanel] = useAtom(activePanelAtom);
  const [, setIsRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [, setIsRuleLive] = useAtom(isRuleLiveAtom);
  const [, setApprovalInfoRule] = useAtom(approvalInfoRuleAtom);
  const [, setVersionInfoRule] = useAtom(versionInfoRuleAtom);
  const [, setVcListTabIndex] = useAtom(vcListTabIndexRuleAtom);
  const [, setDependencyUsingMap] = useAtom(versionMappingInfoAtom);
  const [, setErrorInRule] = useAtom(errorInRuleAtom);

  const [, setDatasetDetailsInRule] = useAtom(datasetDetailsInRuleAtom);
  const [, setHasConnectorError] = useAtom(
    hasConnectorErrorInCustomAttrSheetAtom
  );
  const [, setUsedConnectorMappingInRules] = useAtom(usedConnectorMappingAtom);
  const [, setAssetsInDraftMap] = useAtom(assetsInDraftMapAtom);

  const [searchParams] = useSearchParams();
  const { close: closeRuleSheet } = useCurrentLayer();

  const activePanelParams = searchParams.get('currentTab');
  const vcActiveTabParams = searchParams.get('activeVcTab');

  const isLiveParams = searchParams.get('isLive') === 'true';
  const commitIdParams = searchParams.get('commitId') ?? '';

  const closeSheet = () => {
    setRuleSavingError('');
    setIsRulePublished(false);
    setIsLiveNodeSheetClosed(true);
    const queryParams = window.sessionStorage.getItem('rulesRedirection');
    window.sessionStorage.removeItem('rulesRedirection');
    closeRuleSheet();
    const closeSheet = searchParams.get('closeSheet');

    if (from !== 'viewLive' && closeSheet !== 'true') {
      navigate(`/rules${queryParams ?? ''}`);
    } else if (from !== 'viewLive' && closeSheet === 'true') {
      handleRedirection(queryParams);
    }
  };

  const { openWithProps: openRuleExitModal, closeAllLayers } = useLayer(
    <RuleExitModal onClose={closeSheet} title="Exit Rule" />
  );

  useEffect(() => {
    if (!_isNil(activePanelParams) && !_isEmpty(activePanelParams)) {
      setActivePanel(activePanelParams as PanelType);
    }
  }, [activePanelParams]);

  useEffect(() => {
    if (!_isNil(vcActiveTabParams) && !_isEmpty(vcActiveTabParams)) {
      const index = tabList.findIndex(
        (tabObj) => tabObj.value === vcActiveTabParams
      );
      setVcListTabIndex(index !== -1 ? index : 0);
    }
  }, [vcActiveTabParams]);

  const setPublishedRuleFalse = () => {
    setIsRulePublished(false);
    setIsRulePublishable(false);
    setIsLiveNodeSheetClosed(true);
    const queryParams = window.sessionStorage.getItem('rulesRedirection');
    window.sessionStorage.removeItem('rulesRedirection');
    const closeSheet = searchParams.get('closeSheet');

    if (from !== 'viewLive' && closeSheet !== 'true') {
      if (!_isEmpty(queryParams) && !_isNil(queryParams)) {
        navigate(`/rules${queryParams}`);
      } else {
        navigate('/rules');
      }
    } else if (from !== 'viewLive' && closeSheet === 'true') {
      handleRedirection(queryParams);
    } else if (from === 'viewLive') {
      closeRuleSheet();
    }
  };

  const handleRedirection = (queryParams: string | null) => {
    if (!_isEmpty(queryParams) && !_isNil(queryParams)) {
      navigate(`/rules${queryParams}`);
    } else {
      navigate('/rules');
    }
  };

  const sheetCloseHandler = () => {
    openRuleExitModal({
      description: (
        <span>
          Are you sure you want to exit? You will lose the recent changes
          because {ruleSavingError}
        </span>
      ),
    });
  };

  useLayerCloseOnPath({
    entityName: location.pathname.split('/')[1] as unknown as EntityType,
    onRouteChange: () => {
      setCustomAttributes({});
      setOutputDataset({});
      setAdditionalDataset({});
      setActivePanel('settings');
      setIsRuleReadOnly(false);
      setIsRuleLive(false);
      setApprovalInfoRule(undefined);
      setVersionInfoRule(undefined);
      setVcListTabIndex(0);
      setDependencyUsingMap(undefined);

      setDatasetDetailsInRule(undefined);
      setUsedConnectorMappingInRules(undefined);
      setHasConnectorError({
        dataset: false,
        restAPI: false,
      });

      setErrorInRule({
        action: false,
        condition: false,
        outputData: false,
        additionalData: false,
      });

      setAssetsInDraftMap(undefined);

      closeAllLayers();
    },
  });

  return (
    <Sheet
      onClose={
        _isEmpty(ruleSavingError) ? setPublishedRuleFalse : sheetCloseHandler
      }
    >
      {!_isEmpty(ruleType) && (
        <>
          {rulesComponents[ruleType](
            ruleName,
            ruleId,
            isClone,
            refetch,
            isLive || isLiveParams,
            commitIdParams
          )}
        </>
      )}
    </Sheet>
  );
};
