import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  Button,
  ExpandingTextField,
  Sheet,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { stopPropagate } from '../../../../../utils/form';
import { timeToExpireUnits } from '../../../../DataSets/utils';
import {
  changedNodeIdsAtom,
  isWorkflowReadOnlyAtom,
  isWorkflowTestOnlyAtom,
  workflowErrorByNodeAtom,
  workflowNodeSavingAtom,
  workflowNodesAtom,
} from '../../../atoms/atoms';
import { useGetConnectorById } from '../../../hooks/graphql/useGetConnectorById';
import { useGetPluginByName } from '../../../hooks/graphql/useGetPluginByName';
import { useGenerateDataset } from '../../../hooks/useGenerateDataset';
import { useTestWorkflowDbNode } from '../../../hooks/useTestWorkflowDbNode';
import { useUpdateExecutedValue } from '../../../hooks/useUpdateExecutedValue';
import {
  checkIfNameExists,
  formatDbNodeSettings,
  getExecutedValueAndStatus,
  transformDBNode,
} from '../../../utils/common';
import {
  nodeNameValidationBeforeSave,
  validateConnectorAction,
} from '../../../utils/validations';
import {
  SheetFooterStyled,
  WorkflowSheetFormStyled,
  WorkflowSheetTabContentStyled,
} from '../../CommonStyles/CommonStyles.styled';
import { RuleSheetCloseModal } from '../../Modals/RuleSheetCloseModal/RuleSheetCloseModal';
import { ConnectorMapping } from './ConnectorMapping';
import { ConnectorSettings } from './ConnectorSettings';
import { ConnectorTest } from './ConnectorTest/ConnectorTest';
import { queryOptions } from './constant';

type ConnectorActionSheetProps = {
  data?: any;
  id?: string;
};

export function ConnectorActionSheet({
  data: localData,
  id = '',
}: ConnectorActionSheetProps) {
  const [isResultDisabled] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [counter, setCounter] = useState(0);

  const [currentStatus, setCurrentStatus] = useState<string>(
    localData.status ?? ''
  );
  const [connector, setConnector] = useState<any>(null);

  const [isSaving, setIsSaving] = useState(false);

  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [, setWorkflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);

  const [workflowNodeSaving, setWorkflowNodeSaving] = useAtom(
    workflowNodeSavingAtom
  );

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const {
    testConnectorActionData,
    connectorData,
    connectorError,
    updatedDataSet,
    parentNodes,
    isTesting,
    setIsTesting,
  } = useTestWorkflowDbNode({
    id,
    localData,
  });
  const [getConnectorById] = useGetConnectorById();
  const [getPluginByName, { data: pluginData }] = useGetPluginByName();

  const { close } = useCurrentLayer();

  const { openWithProps: openSheetCloseModal } = useLayer(
    <RuleSheetCloseModal onClose={close} title="Close connector sheet" />
  );

  const { control, handleSubmit, setValue, watch, setError, clearErrors } =
    useForm({
      defaultValues: {
        settings: !_isNil(localData.settings)
          ? formatDbNodeSettings(localData.settings)
          : {
              cacheEnabled: false,
              durationUnit: timeToExpireUnits[2],
              durationValue: 5,
              rowLimit: 1000,
              timeout: 30,
              errorContinue: false,
            },
        name: localData.name ?? '',
        environment: 'staging',
        input: {
          query: localData.input?.query ?? {},
          action: !_isNil(localData.input?.action.value)
            ? {
                value: queryOptions.find(
                  (option) => option.value === localData.input?.action.value
                ),
              }
            : {
                value: queryOptions[0],
              },
        },
        integration:
          !_isNil(localData.entityId) && localData.entityId !== ''
            ? {
                label: localData.name,
                value: localData.entityId,
              }
            : null,
        plugin: {
          imageUrl: '',
          name: '',
          displayName: '',
          category: '',
          id: '',
        },
      },
    });

  const plugin = useWatch({
    name: 'plugin',
    control,
  });

  const integration = watch('integration');

  useEffect(() => {
    void handleGetConnectorProps(integration?.value ?? '', true);
  }, [JSON.stringify(integration)]);

  const { executedValue, handleGetExecutionValues } = useUpdateExecutedValue({
    parentNodes,
    updatedDataset: updatedDataSet,
  });

  const { tokens } = useGenerateDataset({
    id,
    updatedDataset: updatedDataSet,
  });

  useEffect(() => {
    if (!_isNil(connectorData)) {
      if (!_isNil(workflowNode)) {
        const newWorkflowNode = workflowNode;
        newWorkflowNode.data.status = 'success';

        setChangedNodeIds([]);

        setTimeout(() => {
          localData.onWorkflowNodeChange(newWorkflowNode);
        }, 100);
      }
      setCurrentStatus('success');
      setCurrentTab(0);
      setCurrentTab(1);
      setIsTesting(false);

      toasts.success('Node tested successfully', 'node-test-success');
    }
  }, [connectorData]);

  useEffect(() => {
    if (!_isNil(connectorError)) {
      setCurrentStatus('error');

      if (!_isNil(workflowNode)) {
        const newWorkflowNode = workflowNode;
        newWorkflowNode.data.status = 'error';

        setChangedNodeIds([]);

        setTimeout(() => {
          localData.onWorkflowNodeChange(newWorkflowNode);
        }, 100);
      }
      setIsTesting(false);
    }
  }, [connectorError]);

  const formValues = watch();

  useEffect(() => {
    setCounter((count) => count + 1);
  }, [JSON.stringify(formValues)]);

  useEffect(() => {
    if (counter > 1) {
      setCurrentStatus('');
    }
  }, [counter]);

  const handleGetConnectorProps = async (
    id: string,
    isSetValue: boolean = false
  ) => {
    if (_isEmpty(id)) {
      return;
    }

    try {
      const data = await getConnectorById({
        variables: {
          id,
        },
      });

      const connectorData = data.data.getConnector?.data[0];

      setConnector(connectorData);

      if (isSetValue) {
        setValue('plugin.name', connectorData.plugin.name);
        setValue('plugin.imageUrl', connectorData.plugin.imageUrl);
        setValue('plugin.displayName', connectorData.plugin.displayName);
        setValue('plugin.category', connectorData.plugin.category);
        setValue('plugin.id', connectorData.plugin.id);
        setValue('integration', {
          label: connectorData.name,
          value: connectorData.id,
        });
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('error', error);
    }
  };

  const onSubmit = async (db: any, test: boolean = false) => {
    setIsSaving(true);
    clearErrors();
    setWorkflowErrorByNode((prev) => ({
      ...prev,
      [id]: undefined,
    }));

    const isValid = validateConnectorAction(db, test ? setError : undefined);

    const isNameValid = nodeNameValidationBeforeSave(db.name, setError);

    if (!isNameValid) {
      setIsTesting(false);
      setIsSaving(false);

      return;
    }

    if (test) {
      setIsTesting(true);
    }

    if (!isValid) {
      setIsTesting(false);
      setIsSaving(false);

      return null;
    }

    if (!_isNil(workflowNode) && !_isNil(localData.onWorkflowNodeChange)) {
      const newWorkflowNode = workflowNode;

      if (
        db.name !== localData.name &&
        typeof localData.updateOnNameChange === 'function'
      ) {
        const doesNameExist = checkIfNameExists(
          workflowNodes,
          db.name,
          newWorkflowNode
        );

        if (doesNameExist) {
          setError('name', {
            message: 'Duplicate name provided',
          });

          return null;
        }

        localData.updateOnNameChange({
          id,
          name: localData.name,
          newName: db.name,
        });
      }

      const { entityId, name, settings, input, action } = transformDBNode(db);

      newWorkflowNode.data.name = name;
      newWorkflowNode.data.settings = settings;
      newWorkflowNode.data.action = action;
      newWorkflowNode.data.entityId = entityId;
      newWorkflowNode.data.input = input;

      const exec = getExecutedValueAndStatus(connectorData ?? {});

      newWorkflowNode.data.executedValue =
        currentStatus !== '' ? exec.executedValue : null;

      newWorkflowNode.data.status = currentStatus;

      if (counter > 1) {
        setChangedNodeIds([id]);
        setWorkflowNodeSaving(true);
      }

      localData.onWorkflowNodeChange(newWorkflowNode);

      if (!test) {
        close();
      }
    }

    if (test) {
      setCounter(1);
    }

    return null;
  };

  const handleSaveDataAndTest = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, true))
  );

  const handleSaveData = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, false))
  );

  const selectedConnector = watch('integration');

  useEffect(() => {
    if (!_isNil(connectorData)) {
      if (!_isNil(workflowNode)) {
        const newWorkflowNode = workflowNode;
        const exec = getExecutedValueAndStatus(connectorData);
        setCurrentStatus(exec.status);

        newWorkflowNode.data.status = exec.status;
        newWorkflowNode.data.executedValue = exec.executedValue;

        setChangedNodeIds([]);

        setTimeout(() => {
          localData.onWorkflowNodeChange(newWorkflowNode);
        }, 100);
      }
    }
  }, [connectorData]);

  useEffect(() => {
    if (!workflowNodeSaving && isTesting) {
      void handleTestNode();
    }
  }, [workflowNodeSaving, isTesting]);

  useEffect(() => {
    // eslint-disable-next-line
    if (!localData?.entityId && !!localData?.blockName) {
      void getPluginByName({
        variables: {
          filters: {
            eq: {
              name: localData?.blockName,
            },
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!_isNil(pluginData)) {
      setValue('plugin.name', pluginData?.getPlugin.data?.[0].name);
      setValue('plugin.imageUrl', pluginData?.getPlugin.data?.[0].imageUrl);
      setValue(
        'plugin.displayName',
        pluginData?.getPlugin.data?.[0].displayName
      );
      setValue('plugin.category', pluginData?.getPlugin.data?.[0].category);
      setValue('plugin.id', pluginData?.getPlugin.data?.[0].id);
    }
  }, [pluginData]);

  const handleTestNode = async () => {
    await testConnectorActionData(
      formValues.input.query.value,
      formValues.input.action.value?.value ?? 0
    );

    setIsSaving(false);
  };

  const isLoading = isTesting || workflowNodeSaving || isSaving;

  return (
    <Sheet
      size="medium"
      onClose={
        counter > 2
          ? () => {
              openSheetCloseModal({
                onClose: () => {
                  // eslint-disable-next-line
                  if (!(!!localData?.entityId || !!selectedConnector?.value)) {
                    localData.deleteNode({
                      id,
                      type: localData.nodeType ?? 'dbNode',
                    });
                  }
                  close();
                },
              });
            }
          : () => {
              // eslint-disable-next-line
              if (!(!!localData?.entityId || !!selectedConnector?.value)) {
                localData.deleteNode({
                  id,
                  type: localData.nodeType ?? 'dbNode',
                });
              }
              close();
            }
      }
    >
      <WorkflowSheetFormStyled>
        <PadBox padding={0}>
          <Inline stretch="start">
            <Stack as={PadBox} gutter={48} padding={[16, 24]}>
              <Inline stretch="start">
                <Stack gutter={8}>
                  <Inline align="center" gutter="1.6rem" justify="start">
                    <Typography name="heading2">
                      <ExpandingTextField
                        control={control}
                        name="name"
                        disabled={isWorkflowReadOnly}
                      />
                    </Typography>
                  </Inline>
                </Stack>
              </Inline>
            </Stack>
          </Inline>
        </PadBox>

        <Tabs defaultOpen={currentTab} onTabChange={(i) => setCurrentTab(i)}>
          <TabList>
            <Tab>
              <Typography fontWeight={700}>Input Params</Typography>
            </Tab>

            <Tab disabled={isResultDisabled}>
              <Typography
                fontWeight={700}
                name={isResultDisabled ? 'secondarySmall' : 'paragraph'}
              >
                Test Results
              </Typography>
            </Tab>

            <Tab>
              <Typography fontWeight={700}>Settings</Typography>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <WorkflowSheetTabContentStyled>
                <PadBox padding="2rem">
                  <ConnectorMapping
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    pluginName={plugin.name}
                    category={plugin.category}
                    tokens={tokens}
                    handleGetExecutionValues={handleGetExecutionValues}
                    execValues={executedValue}
                    connectorData={connector}
                  />
                </PadBox>
              </WorkflowSheetTabContentStyled>
            </TabPanel>
            <TabPanel>
              <PadBox padding="2rem">
                <ConnectorTest
                  control={control}
                  outputValue={
                    !_isNil(connectorData?.data?.data)
                      ? getExecutedValueAndStatus(connectorData)
                      : localData
                  }
                  error={connectorError}
                />
              </PadBox>
            </TabPanel>
            <TabPanel>
              <ConnectorSettings control={control} name="settings" />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <SheetFooterStyled>
          <Button
            appearance="filled"
            disabled={isLoading || !isWorkflowTestOnly}
            onClick={handleSaveDataAndTest}
          >
            {isTesting ? <Spinner size="extraSmall" /> : <Inline>Test</Inline>}
          </Button>

          <Button
            disabled={isWorkflowReadOnly || isLoading}
            appearance="contained"
            onClick={handleSaveData}
          >
            {isLoading && !isTesting ? (
              <Spinner size="extraSmall" />
            ) : (
              <Inline>Save</Inline>
            )}
          </Button>
        </SheetFooterStyled>
      </WorkflowSheetFormStyled>
    </Sheet>
  );
}
