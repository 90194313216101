import { Inline } from '@bedrock-layout/inline';
import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import { zodResolver } from '@hookform/resolvers/zod';
import { atom, useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { ReactNode, useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import type {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import {
  Dataset,
  Sheet,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
  useCurrentLayer,
  useLayer,
} from 'ui';
import { z } from 'zod';

import {
  siteConstantsAtom,
  usedConnectorMappingAtom,
  userProfileAtom,
} from '../../../../atom';
import { getUserState } from '../../../../hooks/getUserState';
import { useGetPermissionsByEntity } from '../../../../hooks/restApi/useGetPermissionsByEntity';
import { updateWidgetState } from '../../../../pages/Home/components/sub-components/UpdateWidgetState';
import {
  dataSetFieldsByIdAtom,
  selectedDataSetAtom,
} from '../../../../pages/Rules';
import {
  dataSetParamsAtom,
  firstCustomAttributeAtom,
} from '../../../../pages/Rules/components/CreateRuleSheet/CreateRuleSheet';
import {
  assetsInDraftMapAtom,
  hasConnectorErrorInCustomAttrSheetAtom,
  versionMappingInfoAtom,
} from '../../../../pages/Rules/components/atom/atom';
import { useCIWarning } from '../../../../pages/Rules/hooks/useCIWarning';
import type { AttributeModel } from '../../../../pages/Rules/models';
import type { FieldsByID } from '../../../../pages/Rules/types';
import {
  getCustomAttributeData,
  getCustomAttributeTokensForRestInCI,
} from '../../../../pages/Rules/utils/common';
import { currentWorkspaceDetailAtom } from '../../../../pages/Workspace/atom';
import type { VersionMappingInfoType } from '../../../../types';
import {
  convertCaSampleValues,
  getTooltipText,
  isOnboardingCompleted,
  transformCustomInputs,
  updateDatasetVariable,
} from '../../../../utils/common';
import {
  addAttributesForFetchAPI,
  addCustomAttributes,
  mapDatasource,
} from '../../../../utils/constant';
import { HowToLink } from '../../../HowToLink/HowToLink';
import { roleJsonAtom } from '../../../authentication/router/AuthProvider';
import { FormWrapper } from '../FormWrapper';
import { CustomAttrExitModal } from './CustomAttrExitModal';
import { Form, FormPanel } from './CustomAttributeSheet.styled';
import { DataSourceAttributes } from './DataSourceAttribute/DataSourceAttributes';
import { InputAttributes } from './InputAttributes';
import { RestApiAttributes } from './RestInCISheet/RestApiAttributes';
import { attributesSchema } from './schema';
import {
  validateArrayAsInput,
  validateCustomAttributes,
  validateCustomInputTokens,
} from './validation';
import type { FormValues } from './validation';

export type FormHeader = {
  label: string;
  tooltipContent?: ReactNode;
};

export const customAttributeDefaultValues = {
  name: '',
  dataType: null,
  isList: false,
  isNullable: false,
  isOptional: false,
  isCaseSensitive: false,
  sourceType: '',
  attribute: '',
  selectedType: null,
  testValue: '',
  next: '',
  previous: '',
};

export type SourceProp = {
  label: string;
  imgUrl: string;
};

export type CustomAttributeTabCommonProps = {
  fields: Array<FieldArrayWithId<FormValues, 'attributes', 'id'>>;
  append: UseFieldArrayAppend<FormValues, 'attributes'>;
  remove: UseFieldArrayRemove;
  setValue: UseFormSetValue<FormValues>;
  onSubmit: (actionName: string) => void;
  tokensSet: TokensSetProps[];
  isReadOnly: boolean;
  localDataSetFieldsById: FieldsByID;
};

type CustomAttributeSheetProps = {
  isReadOnly: boolean;
};

export const FormValidationSchema = z.object({
  attributes: z.array(attributesSchema),
});

export const customAttributesAtom = atom<Record<string, AttributeModel>>({});

export type TokensSetProps = {
  token: string;
  name: string;
  executedValue: any;
};

export function CustomAttributeSheet({
  isReadOnly,
}: CustomAttributeSheetProps) {
  const [customAttributes, setCustomAttributes] = useAtom(customAttributesAtom);
  const [selectedDataSets, setSelectedDataSet] = useAtom(selectedDataSetAtom);
  const [dataSetFieldsById, setDataSetFieldById] = useAtom(
    dataSetFieldsByIdAtom
  );

  const [siteConstants] = useAtom(siteConstantsAtom);
  const [firstCustomAttribute, setFirstCustomAttribute] = useAtom(
    firstCustomAttributeAtom
  );
  const [dataSetVariables, setDatasetVariables] = useAtom(dataSetParamsAtom);
  const [selectedDataSet] = useAtom(selectedDataSetAtom);
  const [versionMappingInfo, setVersionMappingInfo] = useAtom(
    versionMappingInfoAtom
  );

  const [, setRoleJson] = useAtom(roleJsonAtom);
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);
  const [userProfile] = useAtom(userProfileAtom);

  const email = !_isEmpty(userProfile) ? userProfile?.email : '';

  const [updatedDataset, setUpdatedDataset] = useState<Record<string, Dataset>>(
    {}
  );

  const [usedConnectorMapping, setUsedConnectorMapping] = useAtom(
    usedConnectorMappingAtom
  );
  const [hasConnectorErrCustomAttrSheet, setHasConnectorErrCustomAttrSheet] =
    useAtom(hasConnectorErrorInCustomAttrSheetAtom);

  const [hasConnectorErr, setHasConnectorError] = useState<
    Record<string, boolean>
  >({
    dataset: false,
    restAPI: false,
  });
  const [assetsInDraftMap] = useAtom(assetsInDraftMapAtom);

  const [tokensSet, setTokensSet] = useState<TokensSetProps[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [showSheetExitModal, setShowSheetExitModal] = useState(false);

  const { handleSetWarning } = useCIWarning();

  const [localDataSetFieldsById, setLocalDataSetFieldsById] =
    useState<FieldsByID>(dataSetFieldsById);

  const [localDependencyUsingMap, setLocalDependencyUsingMap] = useState<
    VersionMappingInfoType[]
  >([]);

  const defaultAttributeValues = getCustomAttributeData(
    customAttributes,
    firstCustomAttribute
  );

  const { control, handleSubmit, setValue, setError, watch } =
    useForm<FormValues>({
      resolver: zodResolver(FormValidationSchema),
      defaultValues: {
        attributes: defaultAttributeValues,
        selectedDataSets,
      },
      mode: 'onChange',
    });

  const { fields, append, remove, update } = useFieldArray({
    name: 'attributes',
    control,
  });

  const attributesArray = useWatch({
    name: 'attributes',
    control,
  });

  const localSelectedDataSets = useWatch({
    name: 'selectedDataSets',
    control,
  });

  const { openWithProps: openSheetExitModal } = useLayer(
    <CustomAttrExitModal />
  );
  const { close } = useCurrentLayer();

  const { getUserPermissionsByEntity } = useGetPermissionsByEntity();

  useEffect(() => {
    if (!_isEmpty(email)) {
      void handleGetPermissionByEntity();
    }
  }, [email]);

  useEffect(() => {
    if (!_isNil(dataSetVariables)) {
      setUpdatedDataset((prev) => ({
        ...prev,
        ...dataSetVariables,
      }));
    }
  }, [dataSetVariables]);

  useEffect(() => {
    if (!_isNil(versionMappingInfo) && !_isNil(selectedDataSets)) {
      const currDependencyUsingMap = versionMappingInfo.filter(
        (mapObj) => mapObj.nodeId === `dataSet`
      );

      setLocalDependencyUsingMap(currDependencyUsingMap);
    }
  }, [versionMappingInfo, selectedDataSets]);

  useEffect(() => {
    setHasConnectorError(hasConnectorErrCustomAttrSheet);
  }, [JSON.stringify(hasConnectorErrCustomAttrSheet)]);

  useEffect(() => {
    // if Local dataset is changed then update the local staging status for dataset.
    if (!_isEmpty(localDataSetFieldsById) && !_isEmpty(localSelectedDataSets)) {
      const currConnectorConfig =
        localDataSetFieldsById[localSelectedDataSets[0]]?.connector;

      const connectorStatus: boolean =
        ((currConnectorConfig?.staging?.isTested as boolean) ?? true) &&
        ((currConnectorConfig?.staging?.isPublish as boolean) ?? true);

      setHasConnectorError((prev) => ({ ...prev, dataset: !connectorStatus }));
    } else if (_isEmpty(localSelectedDataSets)) {
      setHasConnectorError((prev) => ({ ...prev, dataset: false }));
    }
  }, [
    JSON.stringify(localDataSetFieldsById),
    JSON.stringify(localSelectedDataSets),
  ]);

  useEffect(() => {
    if (
      JSON.stringify(attributesArray) !== JSON.stringify(defaultAttributeValues)
    ) {
      setShowSheetExitModal(true);
    }
  }, [JSON.stringify(attributesArray)]);

  useEffect(() => {
    // check if there is any used connector not connected (in restAPI)
    if (
      !_isNil(usedConnectorMapping) &&
      !_isEmpty(usedConnectorMapping) &&
      !_isNil(attributesArray)
    ) {
      let hasError = false;

      attributesArray.forEach((currAttribute) => {
        if (currAttribute.dataType?.value === 'restAPI') {
          const connectorId = currAttribute.attribute ?? '';

          hasError = !usedConnectorMapping?.[connectorId]?.status;
        }
      });

      setHasConnectorError((prev) => ({
        ...prev,
        restAPI: hasError,
      }));
    }
  }, [JSON.stringify(attributesArray), JSON.stringify(usedConnectorMapping)]);

  useEffect(() => {
    if (
      JSON.stringify(localSelectedDataSets) !== JSON.stringify(selectedDataSets)
    ) {
      setShowSheetExitModal(true);
    }
  }, [JSON.stringify(localSelectedDataSets)]);

  useEffect(() => {
    if (
      JSON.stringify(localDependencyUsingMap) !==
      JSON.stringify(versionMappingInfo)
    ) {
      setShowSheetExitModal(true);
    }
  }, [JSON.stringify(localDependencyUsingMap)]);

  useEffect(() => {
    updateTokensSet(attributesArray);
  }, [JSON.stringify(attributesArray)]);

  useEffect(() => {
    setValue('selectedDataSets', selectedDataSet);
  }, [selectedDataSet]);

  const handleGetPermissionByEntity = async () => {
    const role = currentWorkspace?.role ?? '';
    const wid = currentWorkspace?.uuid ?? '';

    const user = window.btoa(email ?? '');

    await getUserPermissionsByEntity(user, role, wid, 'datasets', setRoleJson);

    await getUserPermissionsByEntity(
      user,
      role,
      wid,
      'integrations',
      setRoleJson
    );
  };

  const changeTabIndexBasedOnSourceType = (type: string) => {
    switch (type) {
      case '':
        setTabIndex(0);
        break;
      case 'dataSet':
        setTabIndex(1);
        break;
      case 'restAPI':
        setTabIndex(2);
        break;
      default:
        break;
    }
  };

  const validateCustomInput = (
    data: FormValues,
    setError: UseFormSetError<FormValues>
  ) => {
    let isCustomAttributeValid = true;
    let sourceTypeOfErrField = 'none';

    data.attributes.forEach((source, index) => {
      const options =
        !_isNil(localSelectedDataSets[0]) &&
        !_isNil(localDataSetFieldsById[localSelectedDataSets[0]])
          ? localDataSetFieldsById[localSelectedDataSets[0]].fields
          : [];

      const { isCustomAttributeValid: isCurrentSourceValid, sourceTypeOfErr } =
        validateCustomAttributes(
          source,
          index,
          options,
          data,
          setError,
          localDataSetFieldsById,
          localSelectedDataSets
        );

      if (!isCurrentSourceValid) {
        isCustomAttributeValid = false;
        sourceTypeOfErrField = sourceTypeOfErr;
      }

      const {
        haveValidTokens: isTokensValid,
        sourceTypeOfErr: sourceTypeOfTokenErr,
      } = validateCustomInputTokens(source, index, setError, updatedDataset);

      const { isArrayInputValid, sourceTypeOfErr: sourceTypeOfArrayInpErr } =
        validateArrayAsInput(source, index, setError);

      if (!isTokensValid) {
        isCustomAttributeValid = false;
        sourceTypeOfErrField = sourceTypeOfTokenErr;
      }

      if (!isArrayInputValid) {
        isCustomAttributeValid = false;
        sourceTypeOfErrField = sourceTypeOfArrayInpErr;
      }
    });

    changeTabIndexBasedOnSourceType(sourceTypeOfErrField);

    return isCustomAttributeValid;
  };

  const transformCustomInput = (data: FormValues) => {
    let customInput = {};
    data.attributes.forEach((source, index) => {
      customInput = {
        ...customInput,
        [source.name]: {
          ...source,
          name: source.name,
          isNullable: source.isNullable,
          isList: source.isList,
          isOptional: source.isOptional,
          dataType: source.dataType,
          isCaseSensitive: source.isCaseSensitive,
          attribute:
            source.sourceType === 'restAPI'
              ? source.selectedType?.value
              : source.attribute,
          sourceType: source.sourceType,
          selectedType: source.selectedType,
          executedValue: convertCaSampleValues(
            (source.selectedType?.dataType === 'dataSet'
              ? source.dataType?.value
              : source.selectedType?.dataType) ?? 'string',
            source.sampleValue
          ),
          sampleValue: convertCaSampleValues(
            (source.selectedType?.dataType === 'dataSet'
              ? source.dataType?.value
              : source.selectedType?.dataType) ?? 'string',
            source.sampleValue
          ),
          config: source.config,
          next: data.attributes[index + 1]?.name ?? '',
          previous: data.attributes[index - 1]?.name ?? '',
        },
      };
    });

    void setFirstCustomAttribute(data.attributes[0]?.name ?? '');

    return customInput;
  };

  const updateTokensSet = (data: AttributeModel[]) => {
    if (!_isNil(dataSetVariables)) {
      const tokenDataSet = updateDatasetVariable(
        transformCustomInputs({
          attributes: data,
        }),
        dataSetVariables
      );

      // Print the resulting array of objects
      setTokensSet(
        getCustomAttributeTokensForRestInCI(tokenDataSet).filter(
          (token) => token.name !== 'custom.sql'
        )
      );

      setUpdatedDataset((prev) => ({
        ...prev,
        ...tokenDataSet,
      }));
    }
  };

  const onSheetClose = () => {
    if (showSheetExitModal && !isReadOnly) {
      openSheetExitModal({
        onClose: close,
        onSaveAndClose: async (actionName: string) => {
          await handleSubmit((data) => onSubmit(actionName, data))();
        },
      });
    } else {
      close();
    }
  };

  const handleSheetAction = (actionName: string) => {
    if (actionName === 'closeSheet') {
      close();
    } else if (actionName === 'nextTab' && tabIndex < 2) {
      setTabIndex(tabIndex + 1);
    } else if (actionName === 'prevTab' && tabIndex > 0) {
      setTabIndex(tabIndex - 1);
    }
  };

  const onSubmit = (actionName: string, data: FormValues) => {
    // It means there is no change in the data of attributes
    if (!showSheetExitModal) {
      handleSheetAction(actionName);

      return;
    }
    const isCustomAttributeValid = validateCustomInput(
      structuredClone(watch()),
      setError
    );

    const customInput = transformCustomInput(structuredClone(watch()));
    const updatedDependencyMap =
      versionMappingInfo?.filter((obj) => obj.type !== 'dataSet') ?? [];

    if (isCustomAttributeValid) {
      setCustomAttributes(customInput);
      setSelectedDataSet(localSelectedDataSets);
      setDataSetFieldById(localDataSetFieldsById);

      setVersionMappingInfo([
        ...updatedDependencyMap,
        ...localDependencyUsingMap,
      ]);

      setShowSheetExitModal(false);

      if (
        !_isEmpty(localDataSetFieldsById) &&
        !_isEmpty(localSelectedDataSets)
      ) {
        // Check the connector status of Datasource used and update the config.
        const currConnectorConfig =
          localDataSetFieldsById[localSelectedDataSets[0]].connector;

        if (!_isNil(currConnectorConfig)) {
          const connectorId = currConnectorConfig.id;

          const connectorStatus: boolean =
            ((currConnectorConfig?.staging?.isTested as boolean) ?? true) &&
            ((currConnectorConfig?.staging?.isPublish as boolean) ?? true);

          const originalSource =
            usedConnectorMapping?.[connectorId]?.source ?? [];

          const source = originalSource?.includes('dataset')
            ? originalSource
            : [...originalSource, 'dataset'];

          setHasConnectorErrCustomAttrSheet((prev) => ({
            ...prev,
            dataset: !connectorStatus,
          }));

          setUsedConnectorMapping((prev) => ({
            ...prev,
            [connectorId]: {
              status: connectorStatus,
              source,
            },
          }));
        }
      } else if (_isEmpty(localSelectedDataSets)) {
        setHasConnectorErrCustomAttrSheet((prev) => ({
          ...prev,
          dataset: false,
        }));
      }

      if (_isEmpty(localDataSetFieldsById)) {
        // Clear the previous selected data set fields if there is no dataset selected.
        const updatedDataSetParams = Object.keys(dataSetVariables)
          .filter((key) => key !== 'dataSet')
          .reduce((result: Record<string, Dataset>, currentKey) => {
            result[currentKey] = dataSetVariables[currentKey];

            return result;
          }, {});

        setDatasetVariables(updatedDataSetParams);
      }

      handleSetWarning(customInput, Object.keys(localDataSetFieldsById)[0]);
      handleSheetAction(actionName);
    }
  };

  const checkIfDataSetIsAttached = (data: FormValues) => {
    if (data.attributes.length > 0) {
      const isDataSetAttached = data.attributes.find(
        (attribute) => attribute?.selectedType?.dataType === 'dataSet'
      );

      if (!_isNil(isDataSetAttached)) {
        if (!isOnboardingCompleted(mapDatasource)) {
          updateWidgetState(mapDatasource)
            .then(() => {
              void getUserState();
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        }
      }
    }
  };

  const checkIfRESTAPIIsAttached = (data: FormValues) => {
    if (data.attributes.length > 0) {
      const isDataSetAttached = data.attributes.find(
        (attribute) => attribute?.sourceType === 'restAPI'
      );

      if (!_isNil(isDataSetAttached)) {
        if (!isOnboardingCompleted(addAttributesForFetchAPI)) {
          updateWidgetState(addAttributesForFetchAPI)
            .then(() => {
              void getUserState();
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        }
      }
    }
  };

  const checkIfAttributeAdded = (data: FormValues) => {
    if (data.attributes.length > 0) {
      if (!isOnboardingCompleted(addCustomAttributes)) {
        updateWidgetState(addCustomAttributes)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    }
  };

  const showDraftAssetErr =
    !_isNil(assetsInDraftMap) && !_isEmpty(assetsInDraftMap);

  return (
    <Sheet size="large" onClose={onSheetClose}>
      <FormWrapper>
        <Inline stretch="start">
          <Stack as={PadBox} gutter={48} padding={[16, 24]}>
            <Inline align="center" gutter="1.6rem" justify="start">
              <Typography name="heading2">Input Atrributes</Typography>
            </Inline>
          </Stack>
          <PadBox
            padding={{
              right: 16,
              top: 16,
            }}
          >
            <HowToLink
              link={getTooltipText(
                siteConstants,
                'rules',
                'inputAttributesSheetHowTo',
                'howToLinks'
              )}
            />
          </PadBox>
        </Inline>

        <Stack as={Form}>
          <FormPanel padding={0}>
            <Tabs defaultOpen={tabIndex} onTabChange={setTabIndex}>
              <TabList>
                <Tab>1. Input Attributes</Tab>

                <Tab
                  showErrorIcon={hasConnectorErr.dataset || showDraftAssetErr}
                  errorTooltipText={
                    showDraftAssetErr
                      ? 'Publish Datasource'
                      : getTooltipText(
                          siteConstants,
                          'integrations',
                          'integrationNotConnected'
                        )
                  }
                  tooltipId="dataset-tab"
                >
                  2. Map with Data Source (Optional)
                </Tab>

                <Tab
                  showErrorIcon={hasConnectorErr.restAPI}
                  errorTooltipText={getTooltipText(
                    siteConstants,
                    'integrations',
                    'integrationNotConnected'
                  )}
                  tooltipId="restAPI-tab"
                >
                  3. Fetch from API (Optional)
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <InputAttributes
                    fields={fields}
                    append={append}
                    remove={remove}
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    onSubmit={async (actionName: string) => {
                      await handleSubmit((data) => {
                        checkIfAttributeAdded(data);
                        onSubmit(actionName, data);
                      })();
                    }}
                    tokensSet={tokensSet}
                    isReadOnly={isReadOnly}
                    setTabIndex={setTabIndex}
                    localDataSetFieldsById={localDataSetFieldsById}
                  />
                </TabPanel>
                <TabPanel>
                  <DataSourceAttributes
                    fields={fields}
                    append={append}
                    remove={remove}
                    update={update}
                    control={control}
                    setValue={setValue}
                    hasConnectorError={hasConnectorErr.dataset}
                    onSubmit={async (actionName: string) => {
                      await handleSubmit((data) => {
                        checkIfDataSetIsAttached(data);
                        onSubmit(actionName, data);
                      })();
                    }}
                    tokensSet={tokensSet}
                    isReadOnly={isReadOnly}
                    localSelectedDataSets={localSelectedDataSets}
                    localDataSetFieldsById={localDataSetFieldsById}
                    setLocalDataSetFieldsById={setLocalDataSetFieldsById}
                    localDependencyUsingMap={localDependencyUsingMap}
                    setLocalDependencyUsingMap={setLocalDependencyUsingMap}
                    showDraftAssetErr={showDraftAssetErr}
                  />
                </TabPanel>
                <TabPanel>
                  <RestApiAttributes
                    fields={fields}
                    append={append}
                    remove={remove}
                    control={control}
                    setValue={setValue}
                    onSubmit={async (actionName: string) => {
                      await handleSubmit((data) => {
                        checkIfRESTAPIIsAttached(data);
                        onSubmit(actionName, data);
                      })();
                    }}
                    tokensSet={tokensSet}
                    isReadOnly={isReadOnly}
                    customAttrArray={attributesArray}
                    localDataSetFieldsById={localDataSetFieldsById}
                    updatedDataset={updatedDataset}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </FormPanel>
        </Stack>
      </FormWrapper>
    </Sheet>
  );
}
