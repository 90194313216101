import { gql, useMutation } from '@apollo/client';

const updateWorkflowQuery = gql`
  mutation UpdateWorkflow(
    $id: String!
    $name: String!
    $description: String
    $nodes: [NodeInput!]
    $edges: [EdgeInput!]
    $isEnabled: Boolean
    $startNode: String
    $checksum: String!
    $trigger: TriggerInput
    $editMode: Boolean
    $staging: SettingsInput
    $production: SettingsInput
    $dependencyMap: [DependencyInput!]
  ) {
    updateWorkflow(
      input: {
        id: $id
        name: $name
        description: $description
        nodes: $nodes
        edges: $edges
        isEnabled: $isEnabled
        startNode: $startNode
        checksum: $checksum
        trigger: $trigger
        editMode: $editMode
        staging: $staging
        production: $production
        dependencyMap: $dependencyMap
      }
    ) {
      id
      name
      checksum
      status
      staticUrl
      isLive
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
      dependencyMap {
        id
        name
        type
        version
        commitTitle
        subType
        nodeId
      }
    }
  }
`;

export function useUpdateWorkflow() {
  return useMutation(updateWorkflowQuery);
}
