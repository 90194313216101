import { gql, useLazyQuery } from '@apollo/client';

const getRuleByIdQuery = gql`
  query getRule($id: String, $live: Boolean, $filters: QueryOperators) {
    getRule(id: $id, live: $live, filters: $filters) {
      data {
        id
        name
        type
        checksum
        action {
          then {
            outputData
          }
          else {
            outputData
          }
        }
        type
        customInput
      }
    }
  }
`;

export function useGetRuleById() {
  return useLazyQuery(getRuleByIdQuery);
}
